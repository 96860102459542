import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewCardComponent } from './components/cards/overview-card/overview-card.component';
import { InputUploadComponent } from './components/formcontrols/input-upload/input-upload.component';
import { LoadingDotsComponent } from './components/loading-dots.component';
import { LoadingErrorComponent } from './components/loading-error.component';
import { AwaitPromises } from './directives/await-promises';
import { ClientEntityManager } from './entity-system/client-entity-manager.service';
import { AvatarComponent } from './components/avatar/avatar.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashToDotPipe } from './pipes/dashToDot.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RouterModule } from '@angular/router';
import { ChangeLogComponent } from './components/change-log/change-log.component';
import { InputComponent } from './components/formcontrols/input/input.component';
import { SelectComponent } from './components/formcontrols/select/select.component';
import { FormhelperComponent } from './components/formhelper/formhelper.component';

import { ActiveToggleComponent } from './components/formcontrols/active-toggle/active-toggle.component';
import { ToastComponent } from './components/toast/toast.component';
import { LoadingMessageComponent } from './components/loading-message/loading-message.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { InputSearchComponent } from './components/formcontrols/input-search/input-search.component';
import { SelectFilterComponent } from './components/formcontrols/select-filter/select-filter.component';
import { AwaitIntersectionDirective } from './directives/await-intersection.directive';
import { MultiInputComponent } from './components/formcontrols/multi-input/multi-input.component';
import { TabComponent } from './components/tab-controls/tab/tab.component';
import { TabsComponent } from './components/tab-controls/tabs/tabs.component';
import { BreaddcrumbsComponent } from './components/breaddcrumbs/breaddcrumbs.component';
import {
  DataTableComponent,
  DataTableToggleComponent,
  DataTableToggleHeadingComponent
} from './tables/data-table/data-table.component';
import { DataTableCellContentComponent } from './tables/data-table/data-table-cell-content.component';
import { HeaderTitleService } from './services/header-title.service';
import { ApplicationDataGuard } from './guards/application-data-guard.service';
import { SafePipe } from './pipes/safe.pipe';

import { CheckboxGroupComponent } from './components/formcontrols/checkbox-group/checkbox-group.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import {CollapseModule, ModalModule} from 'ngx-bootstrap';
import { CoursePickerComponent } from './components/course-picker/course-picker.component';
import { TopCodePickerComponent } from './components/top-code-picker/top-code-picker.component';
import { CollegeDataGuard } from './guards/college-data-guard.service';
import { CurriculumStatusComponent } from './components/curriculum-status/curriculum-status.component';
import { HeaderComponent } from './components/header/header.component';
import { HasImportedProgramsDataGuard } from './guards/has-imported-programs-data-guard.service';
import { HasImportedCoursesDataGuard } from './guards/has-imported-courses-data-guard.service';
import { HasImportedCollegeProgramsDataGuard } from './guards/has-imported-college-programs-data-guard.service';
import { HasImportedStateProgramsDataGuard } from './guards/has-imported-state-programs-data-guard.service';
import { ImportErrorComponent } from './components/import-error/import-error.component';
import { ProgramPipelineEntityTypeDataGuard } from './guards/program-curriculum-entity-type-data-guard.service';
import { CoursePipelineEntityTypeDataGuard } from './guards/course-curriculum-entity-type-data-guard.service';
import { PendingChangesModalComponent } from './components/pending-changes-modal/pending-changes-modal.component';
import { CollegeSourceSystemDataGuard } from './guards/college-source-system-data-guard.service';
import { StateSourceSystemDataGuard } from './guards/state-source-system-data-guard.service';
import {ReadMoreComponent} from "./components/readmore.component";
import {HasImportedCollegeCoursesDataGuard} from "./guards/has-imported-college-courses-data-guard.service";
import {HasImportedStateCoursesDataGuard} from "./guards/has-imported-state-courses-data-guard.service";
import {PickedCoursesComponent} from "./components/picked-courses/picked-courses.component";
import {ProgramLearningOutcomesWidgetComponent} from "./components/program-learning-outcomes-widget/program-learning-outcomes-widget.component";
import { BulkEditUploadWidgetComponent } from './components/bulk-edit-upload-widget/bulk-edit-upload-widget.component';
import { AuthenticationGuard } from './guards/authentication.guard';
import {CipCodePickerComponent} from "./components/cip-code-picker/cip-code-picker.component";
import { BadgePickerComponent } from './components/badge-picker/badge-picker.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CollapseModule,
    ModalModule.forRoot(),
  ],
  declarations: [
    OverviewCardComponent,
    InputUploadComponent,
    AvatarComponent,
    DashToDotPipe,
    AwaitPromises,
    DragDropDirective,
    LoadingDotsComponent,
    LoadingErrorComponent,
    PaginationComponent,
    ChangeLogComponent,
    InputComponent,
    SelectComponent,
    FormhelperComponent,
    ActiveToggleComponent,
    ToastComponent,
    LoadingMessageComponent,
    DialogComponent,
    InputSearchComponent,
    SelectFilterComponent,
    AwaitIntersectionDirective,
    MultiInputComponent,
    TabsComponent,
    TabComponent,
    BreaddcrumbsComponent,
    TruncatePipe,
    DataTableCellContentComponent,
    DataTableComponent,
    DataTableToggleComponent,
    DataTableToggleHeadingComponent,
    SafePipe,
    CheckboxGroupComponent,
    CoursePickerComponent,
    PickedCoursesComponent,
    TopCodePickerComponent,
    CipCodePickerComponent,
    CurriculumStatusComponent,
    HeaderComponent,
    ImportErrorComponent,
    PendingChangesModalComponent,
    ReadMoreComponent,
    ProgramLearningOutcomesWidgetComponent,
    BulkEditUploadWidgetComponent,
    BadgePickerComponent,
  ],
  entryComponents: [
    LoadingMessageComponent,
    PendingChangesModalComponent
  ],
  exports: [
    TopCodePickerComponent,
    CipCodePickerComponent,
    CoursePickerComponent,
    PickedCoursesComponent,
    OverviewCardComponent,
    InputUploadComponent,
    AvatarComponent,
    DashToDotPipe,
    AwaitPromises,
    LoadingDotsComponent,
    LoadingErrorComponent,
    PaginationComponent,
    ChangeLogComponent,
    InputComponent,
    SelectComponent,
    FormhelperComponent,
    ActiveToggleComponent,
    ToastComponent,
    LoadingMessageComponent,
    DialogComponent,
    InputSearchComponent,
    SelectFilterComponent,
    AwaitIntersectionDirective,
    TabsComponent,
    TabComponent,
    AwaitIntersectionDirective,
    BreaddcrumbsComponent,
    DataTableCellContentComponent,
    DataTableComponent,
    DataTableToggleComponent,
    DataTableToggleHeadingComponent,
    CheckboxGroupComponent,
    MultiInputComponent,
    CurriculumStatusComponent,
    HeaderComponent,
    ImportErrorComponent,
    ReadMoreComponent,
    ProgramLearningOutcomesWidgetComponent,
    BulkEditUploadWidgetComponent,
  ],
  providers: [
    HeaderTitleService,
    ClientEntityManager,
    AuthenticationGuard,
    ApplicationDataGuard,
    CollegeDataGuard,
    HasImportedProgramsDataGuard,
    HasImportedCoursesDataGuard,
    HasImportedCollegeProgramsDataGuard,
    HasImportedStateProgramsDataGuard,
    HasImportedCollegeCoursesDataGuard,
    HasImportedStateCoursesDataGuard,
    ProgramPipelineEntityTypeDataGuard,
    CoursePipelineEntityTypeDataGuard,
    CollegeSourceSystemDataGuard,
    StateSourceSystemDataGuard,
  ]

})
export class SharedModule { }
