import { Component, Input} from '@angular/core';

export interface AvatarEntity {
  idAvatar: string;
  idName: string;
}

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent {
  @Input() avatarEntity: AvatarEntity;
  @Input() subModule: string;
}
