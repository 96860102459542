import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html'
})
export class ChangeLogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
