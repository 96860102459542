import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

export enum TabTypes {
  ALL_COURSES = 'All Courses',
  ALL_PROGRAMS = 'All Programs',
  ERRORS = 'Errors',
  NEW = 'New',
  UPDATED = 'Updated'
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html'
})
export class TabsComponent implements OnInit, AfterContentInit {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Input() selectTabByLabel: TabTypes;

  constructor() { }

  ngOnInit() {}

  ngAfterContentInit() {
    if (this.selectTabByLabel) {
      const selected = this.tabs.find(tab => tab.label === this.selectTabByLabel)
      if (selected) {
        this.selectTab(selected);
      }
    }
    else if (this.tabs) {
      this.selectFirstTab();
    }
  }

  selectFirstTab() {
    this.selectTab(this.tabs.first);
  }

  selectTab(tab: TabComponent) {
    this.deselectAllTabs();
    tab.selected = true;
  }

  deselectAllTabs() {
    this.tabs.forEach( tab => tab.selected = false );
  }

}
