import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  ApiEither,
  BulkAmendErrors,
  BulkAmendType,
  ImportCorrections, isFailure, isSuccess,
  Nothing, PipelineEntityType
} from '../../../api/datacleanuptool-api.model';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { ServiceManager } from '../../services/service-manager';
import { createToastObject, ToastType } from '../../services/toast.service';
import { BulkEditBaseComponent } from '../bulk-edit/bulk-edit-base.component';
import { LocalStorageService } from 'ngx-webstorage';
import { isNullOrUndefined } from 'util';
import { AuthenticationService } from '../../services/authentication.service';
import {ApiUrlService} from "../../services/api-url.service";

@Component({
  selector: 'app-bulk-edit-upload-widget',
  templateUrl: './bulk-edit-upload-widget.component.html'
})
export class BulkEditUploadWidgetComponent extends BaseComponent implements OnInit {

  @Input() label: string;
  @Output() importSuccessful : EventEmitter<boolean> = new EventEmitter();
  @Input() entityType: PipelineEntityType;
  @Input() field: string;

  csvAsText: string;
  uploading: boolean = false;
  private _disableImportButton: Boolean = true;
  private bulkAmendType: BulkAmendType;
  loaded = false;
  private baseUrl: string;

  constructor(
    protected readonly serviceManager: ServiceManager,
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    private apiUrlService: ApiUrlService
  ) {
    super(serviceManager);
  }

  ngOnInit(): void {
      this.applicationState.currentBulkAmendCsvAsText = undefined;
      this.bulkAmendType = bulkAmendTypeFromField(this.entityType, this.field);
      this.apiUrlService.getBaseUrl().subscribe(
        baseUrl => {
          this.baseUrl = baseUrl;
          this.loaded = true;
        }
      )
  }

  receivedFileData(csvAsText: string) {
    this.csvAsText = csvAsText;
    this.applicationState.currentBulkAmendCsvAsText = this.csvAsText;
    this._disableImportButton = false;
  }

  templateUrl(){
    if (this.bulkAmendType) {
      const token = this.localStorageService.retrieve('token');
      if(isNullOrUndefined(token)){
        // send to login page for authentication first
        this.authenticationService.logOutAndRedirect();
      }
      let templateDownloadUrl = this.curriculumYear.templateDownloadUrls[this.bulkAmendType];

      // server is returning an http url, incorrectly. fix it here;
      // TODO: server should not return the base API url portion
      let split = templateDownloadUrl.split("/templates");
      templateDownloadUrl = split[1];

      return this.baseUrl + "/templates" + templateDownloadUrl +"&t=" + token;
    }
  }

  bulkEditFromCsv(importCorrectionsByRow?: Map<number, ImportCorrections>) {
    this.uploading = true;
    this.curriculumYear.bulkEditFromCsv(this.entityType, this.bulkAmendType, this.csvAsText)
      .pipe(takeUntil(this.onNgDestroy))
      .subscribe(
        response => this.importHandler(response),
        error => this.uploading = false
      );
  }

  disableImportButton() {
    return this._disableImportButton;
  }

  private importHandler(response: ApiEither<BulkAmendErrors, Nothing>) {
    this.uploading = false;
    if (isSuccess(response)) {
      this.serviceManager.toastService.add(createToastObject(ToastType.TOAST_IS_SUCCESS, "batch update succeeded"));
      this.applicationState.currentBulkAmendCsvAsText = undefined;
      this.importSuccessful.next(true);
    }
    else if (isFailure(response)) {
      this.applicationState.pendingCsvAsText = this.csvAsText;
      this.applicationState.currentBulkAmendErrors = response.value;
      this.importSuccessful.next(false);
    }
  }
}

export function bulkAmendTypeFromField(entityType: PipelineEntityType, field: string): BulkAmendType {
  let bulkAmendType: BulkAmendType;
  if (entityType === PipelineEntityType.PROGRAM) {
    if (!BulkEditBaseComponent.PROGRAM_BULK_EDIT_TEMPLATE_MAPPING[field]) {
      throw new Error('invalid type: ' + field);
    }
    bulkAmendType = BulkEditBaseComponent.PROGRAM_BULK_EDIT_TEMPLATE_MAPPING[field];
  } else if (entityType === PipelineEntityType.COURSE) {
    if (!BulkEditBaseComponent.COURSE_BULK_EDIT_TEMPLATE_MAPPING[field]) {
      throw new Error('invalid type: ' + field);
    }
    bulkAmendType = BulkEditBaseComponent.COURSE_BULK_EDIT_TEMPLATE_MAPPING[field];
  }
  return bulkAmendType;
}
