/**
 * Holds a promise as well as the resolve and reject functions associated with the promise. Makes creating and managing
 * promises easier than using the native Promise constructor.
 */
export interface OwnedPromise<T> {
  promise: Promise<T>;

  resolve: (value?: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
}

/**
 * Creates a promise and returns it as an [OwnedPromise] with the resolve and reject functions along side the actual
 * promise object.
 */
export function createPromise<T>(): OwnedPromise<T> {
  let resolve: (value?: T | PromiseLike<T>) => void;
  let reject: (reason?: any) => void;

  const promise = new Promise<T>((resolveParam, rejectParam) => {
    resolve = resolveParam;
    reject = rejectParam;
  });

  return {
    promise,
    resolve,
    reject
  };
}

/**
 * Creates a promise that will be resolved on the next animation frame.
 */
export function animationFramePromise(): Promise<void> {
  return new Promise<void>(
    resolve => requestAnimationFrame(() => resolve())
  );
}

/**
 * Creates a promise that will be resolved after the given number of milliseconds.
 */
export function timeoutPromise(timeoutMs?: number): Promise<void> {
  return new Promise<void>(
    resolve => setTimeout(() => resolve(), timeoutMs)
  );
}
