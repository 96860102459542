import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export const enum ToastType {
  TOAST_IS_ERROR = 'toast-is-error',
  TOAST_IS_SUCCESS = 'toast-is-success'
}

export interface ToastMessage  {
  toastType: ToastType;
  message: string;
  urToDisplay?: string;
}

export function createToastObject (toastType: ToastType, message: string, urlToDisplay?: string) {
  return {
    toastType: toastType,
    message: message,
    urToDisplay: urlToDisplay
  };
}

@Injectable()
export class ToastService {

  constructor(){
    console.log("Creating a toast service", this);
  }
  private _toasts: ToastMessage[] = [];

  private _toastSource: ReplaySubject<ToastMessage[]> = new ReplaySubject<ToastMessage[]>();
  toastSource$ = this._toastSource.asObservable();

  add( toast: ToastMessage ) {
  this._toasts.push(toast);
      this.announceToast();
  }
  remove(toastToRemove: ToastMessage) {
    this._toasts = this._toasts.filter(toast => toast !== toastToRemove);
    this.announceToast();
  }

  private announceToast() {
    this._toastSource.next(this._toasts.reverse());
  }

}

