import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-program-learning-outcomes-widget',
  templateUrl: './program-learning-outcomes-widget.component.html',
  styleUrls: ['./program-learning-outcomes-widget.component.scss']
})
export class ProgramLearningOutcomesWidgetComponent implements OnInit {

  @Input() learningOutcomesArray: string[] = [];
  @Input() index: number;
  @Output() outcomesOut: EventEmitter<[]> = new EventEmitter();

  learningOutcomesFormGroup = this.formBuilder.group({
    learningOutcomesControlsArray: this.formBuilder.array([])
  });

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.learningOutcomesArray.forEach(value => {
      this.addOutcome(value);
    })
  }

  get outcomesFormArray() {
    return this.learningOutcomesFormGroup.get('learningOutcomesControlsArray') as FormArray;
  }

  get outcomes() {
    return this.learningOutcomesFormGroup.get('learningOutcomesControlsArray').value;
  }

  markAsChanged() {
    this.outcomesOut.emit(this.outcomes);
  }

  addOutcome(value) {
    this.outcomesFormArray.push(this.formBuilder.control(value));
  }

  removeOutcome(index) {
    this.outcomesFormArray.removeAt(index);
  }

  moveUp(index) {
    if (index > 0) {
      const control = this.outcomesFormArray.at(index);
      this.outcomesFormArray.removeAt(index);
      this.outcomesFormArray.insert(index - 1, control);
    }
  }

  moveDown(index) {
    const control = this.outcomesFormArray.at(index);
    this.outcomesFormArray.removeAt(index);
    this.outcomesFormArray.insert(index + 1, control);
  }
}
