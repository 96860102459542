import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-message',
  template: `
    <div class="l-loadingmessage">
      <div class="loadingmessage {{className}} ">
        <div class="loadingmessage-x-indicator"></div>
        <h3 class="loadingmessage-x-headline u-text-bodylarge">Loading</h3>
        <p class="loadingmessage-x-copy u-text-body">Please wait a moment</p>
      </div>
    </div>
  `
})
export class LoadingMessageComponent implements OnInit {

  @Input() subModule?: string;

  // TODO: Yona added this because it was referenced in the .html file, but not defined here, and was causing compilation errors
  @Input() className?: string;

  constructor() { }

  ngOnInit() {
  }

}
