import { Component, Input, OnInit } from '@angular/core';
import { BaseInput } from '../base-input';

@Component({
  selector: 'app-active-toggle',
  templateUrl: './active-toggle.component.html'
})
export class ActiveToggleComponent extends BaseInput implements OnInit {

  @Input() placeholder: string;

  @Input() type: string;

  @Input() set value(value: boolean) { this._value = value; }

  // When passed in - A label that is only displayed at less than the laptop media query
  @Input() labelAtMobile?: string;

  get value(): boolean { return this._value; }
    private _value?: boolean;

  ngOnInit() {
    super.ngOnInit();
    this.control.setValue(this.value);
  }

}
