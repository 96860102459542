import { Component, Input} from '@angular/core';

export type DialogSubmodules = 'dialog-anchorright' | 'dialog-anchorbottom | pui-dialog-long';
export type DialogStates = 'dialog-is-open';

@Component({
  selector: 'app-dialog',
  template: `
     <div class="dialog {{subModule}} {{state}} l-dialog l-inertcontainer">
      <div class="dialog-x-content">
        <ng-content></ng-content>
      </div>
    </div>
  `
})
export class DialogComponent {

  @Input() subModule: DialogSubmodules;

  @Input() state: DialogStates;

}

