import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseInput } from '../base-input';

/** compoundName allows you to specify what to display as the option label in the select control.
 *  separator: optional separator between element order items
 */

export interface SelectFormControlOption {
  name: string;
  value: string | number | null;
  compoundName?: {
    prefix: 'name' | 'value',
    suffix: 'name' | 'value',
    separator?: string;
  };
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html'
})
export class SelectComponent extends BaseInput implements OnInit {
  @Input() options ;

  @Input() defaultOptionText?: string;

  @Input() optionsConfig?: SelectFormControlOption;

  @Input() subModule?: string;

  @Input() value?: string = '';

  @Input() invalidValueText?: string;

  @Output() selectionEmitter: EventEmitter<any> = new EventEmitter<any>();

  optionalText: string = '';

  select: FormControl;

  ngOnInit() {
    super.ngOnInit();
    this.optionsConfig = this.optionsConfig || { name: 'name', value: 'value' };

    /**
     * If the invalidValueText property exists and the value that was passed in
     * is not found in options, display the invalidValueText in the place of the
     * defaultOptionText
     *
     * ************************************************************************************
     * logic used to create option name (while in compoundName condition):
     *    valid value = generated compound name
     *    no value + defaultOptionText = defaultOptionText
     *    no value + no defaultOptionText = ''
     *
     */
    if (this.value && this.invalidValueText) {

      this.optionalText = this.isValueValid()
                            ? (this.optionsConfig && this.optionsConfig.compoundName)
                                ? this.generateCompoundName()
                                : this.value
                            : `${this.invalidValueText} ${this.value}`;

      this.control.setValue(null);
    }

    else {
      this.optionalText = this.value
                          ? (this.optionsConfig && this.optionsConfig.compoundName)
                              ? this.generateCompoundName()
                              : this.value
                          : (this.defaultOptionText ? this.defaultOptionText : '');


      this.control.setValue(this.value
                            ? this.value
                            : (this.defaultOptionText ? null : ''));
    }

  }


  generateCompoundName(): string {
    const cn = this.optionsConfig.compoundName;
    const val = this.options.filter( v => v.value === this.value)[0];

    return (cn.prefix === val)
      ? `${val.value} ${cn.separator} ${val.name}`
      : `${val.name} ${cn.separator} ${val.value}`;
  }

  isValueValid() {
   return this.options.some( r => r[this.optionsConfig.value] === this.value);
  }

  emitSelection() {
    this.selectionEmitter.emit(this.control.value);
  }

}
