import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollegesComponent } from './colleges/colleges.component';
import { LoadingDotsComponent } from './shared/components/loading-dots.component';
import { LoadingErrorComponent } from './shared/components/loading-error.component';
import { ApiUrlService } from './shared/services/api-url.service';
import { ApplicationStateService } from './shared/services/application-state.service';
import { BreakpointService } from './shared/services/breakpoint.service';
import { EnvironmentConfigService } from './shared/services/environment-config.service';
import { LoggingService } from './shared/services/logging-service';
import { MessageService } from './shared/services/message.service';
import { SharedModule } from './shared/shared.module';
import { InfoComponent } from './info/info.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { MessagesComponent } from './messages/messages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ServerErrorComponent } from './server-error/server-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { ApiResponseInterceptor } from './shared/api-response-interceptor';
import { ProgramRecordsComponent } from './authenticated/pro-mode/program-records/program-records.component';
import { CourseRecordsComponent } from './authenticated/pro-mode/course-records/course-records.component';
import { MasterRecordNoticeService } from './shared/services/master-record-notice.service';
import { ExportSearchResultsService } from './shared/services/export-search-results.service';
import { ServiceManager } from './shared/services/service-manager';
import { CourseTableComponent } from './authenticated/pro-mode/course-records/course-table/course-table.component';
import { ProgramTableComponent } from './authenticated/pro-mode/program-records/program-table/program-table.component';
import { ExcelErrorReportService } from './excel-error-report.service';
import { CollegeDataComponent } from './authenticated/college-data/college-data.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { LoginModule } from './anonymous/login/login.module';
import { JwtAuthenticationInterceptor } from './shared/services/jwt-authentication-interceptor';
import { ToastService } from './shared/services/toast.service';
import { LocalStorageService } from 'ngx-webstorage';

@NgModule({
  declarations: [
    AdminDashboardComponent,
    AppComponent,
    MessagesComponent,
    InfoComponent,
    NotFoundComponent,
    ServerErrorComponent,
    LoadingBarComponent,
    CollegesComponent,
    ProgramRecordsComponent,
    CourseRecordsComponent,
    CourseTableComponent,
    ProgramTableComponent,
    CollegeDataComponent,
  ],

  entryComponents: [
    LoadingDotsComponent,
    LoadingErrorComponent,
  ],

  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CollapseModule.forRoot(),
    LoginModule
  ],

  providers: [
    ServiceManager,
    MessageService,
    ApiUrlService,
    ApplicationStateService,
    ToastService,
    LocalStorageService,
    EnvironmentConfigService,
    LoggingService,
    BreakpointService,
    Title,
    ExcelErrorReportService,
    MasterRecordNoticeService,
    ExportSearchResultsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true,
      //deps: [ ToastService ]
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: JwtAuthenticationInterceptor,
      multi: true,
//      deps: [ LocalStorageService ]
    },
  ],
  exports: [
    //CreateCourseLocalDocumentComponent
  ],

  bootstrap: [ AppComponent ]
})

export class AppModule {}
