import {Injectable, isDevMode} from '@angular/core';

@Injectable()
export class LoggingService {
  log(...args: any[]) {
    if (isDevMode()) {
      console.log.apply(console, args);
    }
  }
}
