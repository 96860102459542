import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InfoComponent } from './info/info.component';
import { LoginComponent } from './anonymous/login/login/login.component';
import { AuthenticationGuard } from './shared/guards/authentication.guard';

// new routes params
export const curriculumYearParam = 'curriculumYear';

// legacy routes params
export const collegeIdParam = 'collegeId';
// export const curriculumParam = 'curriculum';
// export const programParam = 'program';
// export const courseParam = 'course';
// export const documentTypeParam = 'sourceDocumentType';
// export const curriculumEntityTypeParam = 'curriculumEntityType';
// export const sourceDocumentId = 'sourceDocumentId';

// export class QueryParametersNameValue<QValue> {
//   name: QueryParamTypes;
//   value: QValue;
//
//   constructor(name: QueryParamTypes, value: QValue) {
//     this.name = name;
//     this.value = value;
//   }
// }
/**
 *   Prototype routes
 */
const routes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: `info`, component: InfoComponent },
  {
    path: '',
    canActivate: [AuthenticationGuard],
    loadChildren: './authenticated/authenticated.module#AuthenticatedModule'
  },

  { path: `login`, component: LoginComponent },

  // {
  //   path: `admin`,
  //   component: AdminDashboardComponent
  // },
  // {
  //   path: `colleges`,
  //   component: CollegesComponent,
  //   // canActivate: [ ApplicationDataGuard ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years`,
  //   component: ChooseYearComponent,
  //   canActivate: [ CollegeDataGuard ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/create/:${curriculumYearParam}`,
  //   component:CreateCurriculumYearComponent,
  //   canActivate: [ CollegeDataGuard ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}`,
  //   component: CurriculumDashboardComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'version-history',
  //       outlet : 'popup',
  //       component: VersionHistoryComponent
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/master-records/COURSE`,
  //   component: CourseRecordsComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children : [
  //     {
  //       path: 'detail/:id',
  //       outlet : 'popup',
  //       component: CourseDetailComponent,
  //       children: [
  //         {
  //           path: 'golden',
  //           outlet : 'popup2',
  //           component: CourseGoldenRecordEditComponent,
  //           children: [
  //             {
  //               path: 'course-picker',
  //               outlet : 'coursePickerModal',
  //               component: CoursePickerComponent
  //             }
  //           ]
  //         },
  //         {
  //           path: 'source/:id',
  //           outlet : 'popup2',
  //           component: EditCourseSourceDocumentComponent,
  //         }
  //       ],
  //     },
  //     {
  //       path: 'add',
  //       outlet : 'popup',
  //       component: CourseAddComponent
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/master-records/PROGRAM`,
  //   component: ProgramRecordsComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children : [
  //     {
  //       path: 'detail/:id',
  //       outlet : 'popup',
  //       component: ProgramDetailComponent,
  //       children: [
  //         {
  //           path: 'golden',
  //           outlet : 'popup2',
  //           component: ProgramGoldenRecordEditComponent,
  //           children: [
  //             {
  //               path: 'top-code-picker',
  //               outlet : 'topCodePickerModal',
  //               component: TopCodePickerComponent
  //             }
  //           ]
  //         },
  //         {
  //           path: 'source/:id',
  //           outlet : 'popup2',
  //           component: EditProgramSourceDocumentComponent,
  //         }
  //       ],
  //     },
  //     {
  //       path: 'add',
  //       outlet : 'popup',
  //       component: ProgramAddComponent
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/course-lists`,
  //   component: CourseListsDashboardComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'add',
  //       component: CourseListsAddComponent,
  //       outlet : 'popup',
  //       children: [
  //         {
  //           path: 'course-picker',
  //           outlet : 'coursePickerModal',
  //           component: CoursePickerComponent
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/course-lists/search`,
  //   component: CourseListsSearchComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'edit/:id',
  //       outlet : 'popup',
  //       component: CourseListsEditComponent,
  //       children: [
  //         {
  //           path: 'course-picker',
  //           outlet : 'coursePickerModal',
  //           component: CoursePickerComponent
  //         }
  //       ],
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/amend/course`,
  //   component: BulkAmendComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/amend/course/errors`,
  //   component: BulkAmendErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/amend/program`,
  //   component: BulkAmendComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/amend/program/errors`,
  //   component: BulkAmendErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/import/:${documentTypeParam}/errors`,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   component: ImportErrorComponent
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/premerge/:${documentTypeParam}/upload`,
  //   component: PreMergeUploadComponent,
  //   canActivate: [ ApplicationDataGuard ]
  // },
  // {
  //   path: 'colleges/:collegeId/curriculum-years/:curriculum/general-education-patterns',
  //   component: GeneralEducationPatternsDashboardComponent,
  //   canActivate: [ ApplicationDataGuard ]
  // ,
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years`,
  //   component: ChooseYearComponent,
  //   canActivate: [ CollegeDataGuard ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/create/:${curriculumYearParam}`,
  //   component:CreateCurriculumYearComponent,
  //   canActivate: [ CollegeDataGuard ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}`,
  //   component: CurriculumDashboardComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'version-history',
  //       outlet : 'popup',
  //       component: VersionHistoryComponent
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses`,
  //   component: CourseRecordsComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children : [
  //     {
  //       path: 'detail/:id',
  //       outlet : 'popup',
  //       component: CourseDetailComponent,
  //       children: [
  //         {
  //           path: 'golden',
  //           outlet : 'popup2',
  //           component: CourseGoldenRecordEditComponent,
  //           children: [
  //             {
  //               path: 'course-picker',
  //               outlet : 'coursePickerModal',
  //               component: CoursePickerComponent
  //             }
  //           ]
  //         },
  //         {
  //           path: 'source/:id',
  //           outlet : 'popup2',
  //           component: EditCourseSourceDocumentComponent,
  //         }
  //       ],
  //     },
  //     {
  //       path: 'add',
  //       outlet : 'popup',
  //       component: CourseAddComponent
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs`,
  //   component: ProgramRecordsComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children : [
  //     {
  //       path: 'detail/:id',
  //       outlet : 'popup',
  //       component: ProgramDetailComponent,
  //       children: [
  //         {
  //           path: 'golden',
  //           outlet : 'popup2',
  //           component: ProgramGoldenRecordEditComponent,
  //           children: [
  //             {
  //               path: 'top-code-picker',
  //               outlet : 'topCodePickerModal',
  //               component: TopCodePickerComponent
  //             }
  //           ]
  //         },
  //         {
  //           path: 'source/:id',
  //           outlet : 'popup2',
  //           component: EditProgramSourceDocumentComponent,
  //         }
  //       ],
  //     },
  //     {
  //       path: 'add',
  //       outlet : 'popup',
  //       component: ProgramAddComponent
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/course-lists`,
  //   component: CourseListsDashboardComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'add',
  //       component: CourseListsAddComponent,
  //       outlet : 'popup',
  //       children: [
  //         {
  //           path: 'course-picker',
  //           outlet : 'coursePickerModal',
  //           component: CoursePickerComponent
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/course-lists/search`,
  //   component: CourseListsSearchComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'edit/:id',
  //       outlet : 'popup',
  //       component: CourseListsEditComponent,
  //       children: [
  //         {
  //           path: 'course-picker',
  //           outlet : 'coursePickerModal',
  //           component: CoursePickerComponent
  //         }
  //       ],
  //     }
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses/descriptions/amend`,
  //   component: CourseDescriptionsBulkEditModalComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses/descriptions/amend/errors`,
  //   component: CourseDescriptionsBulkEditErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses/units/amend`,
  //   component: CourseUnitsBulkEditModalComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses/units/amend/errors`,
  //   component: CourseUnitsBulkEditErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses/requisites/amend`,
  //   component: CourseRequisitesBulkEditModalComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/courses/requisites/amend/errors`,
  //   component: CourseRequisitesBulkEditErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.COURSE
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/descriptions/amend`,
  //   component: ProgramDescriptionsBulkEditModalComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/descriptions/amend/errors`,
  //   component: ProgramDescriptionsBulkEditErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/youtube/amend`,
  //   component: ProgramYoutubeBulkEditModalComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/youtube/amend/errors`,
  //   component: ProgramYoutubeBulkEditErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/learning-outcomes/amend`,
  //   component: LearningOutcomesBulkEditModalComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/learning-outcomes/amend/errors`,
  //   component: LearningOutcomesBulkEditErrorsComponent,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   data: {
  //     entityType: PipelineEntityType.PROGRAM
  //   }
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/import/:${documentTypeParam}/errors`,
  //   canActivate: [ ImportErrorGuard, ApplicationDataGuard ],
  //   component: ImportErrorComponent
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/premerge/:${documentTypeParam}/upload`,
  //   component: PreMergeUploadComponent,
  //   canActivate: [ ApplicationDataGuard ]
  // },
  // {
  //   path: 'colleges/:collegeId/curriculum-years/:curriculum/general-education-patterns',
  //   component: GeneralEducationPatternsDashboardComponent,
  //   canActivate: [ ApplicationDataGuard ]
  // },
  // {
  //   path: 'colleges/:collegeId/curriculum-years/:curriculum/general-education-patterns/edit/:id',
  //   component: GeneralEducationPatternsEditComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'course-picker',
  //       component: CoursePickerComponent,
  //       outlet: 'coursePickerModal',
  //     },
  //   ]
  // },
  // {
  //   path: 'colleges/:collegeId/curriculum-years/:curriculum/general-education-patterns/add/:id',
  //   component: GeneralEducationPatternsEditComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'course-picker',
  //       component: CoursePickerComponent,
  //       outlet: 'coursePickerModal',
  //     },
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/align`,
  //   component: ProgramsAlignComponent,
  //   canActivate: [ ApplicationDataGuard ]
  // },
  // {
  //   path: `edit-program-master-record`,
  //   component: ProgramGoldenRecordEditComponent,
  //   outlet: 'modal'
  // },
  // {
  //   path: `edit-program-source-record/:${sourceDocumentId}`,
  //   component: EditProgramSourceDocumentComponent,
  //   outlet: 'modal'
  // },
  // {
  //   path: `edit-course-master-record`,
  //   component: CourseGoldenRecordEditComponent,
  //   outlet: 'modal'
  // },
  // {
  //   path: `edit-course-source-record/:${sourceDocumentId}`,
  //   component: EditCourseSourceDocumentComponent,
  //   outlet: 'modal'
  // },

  //     {
  //       path: 'course-picker',
  //       component: CoursePickerComponent,
  //       outlet: 'coursePickerModal',
  //     },
  //   ]
  // },
  // {
  //   path: 'colleges/:collegeId/curriculum-years/:curriculum/general-education-patterns/add/:id',
  //   component: GeneralEducationPatternsEditComponent,
  //   canActivate: [ ApplicationDataGuard ],
  //   children: [
  //     {
  //       path: 'course-picker',
  //       component: CoursePickerComponent,
  //       outlet: 'coursePickerModal',
  //     },
  //   ]
  // },
  // {
  //   path: `colleges/:${collegeIdParam}/curriculum-years/:${curriculumParam}/programs/align`,
  //   component: ProgramsAlignComponent,
  //   canActivate: [ ApplicationDataGuard ]
  // },
  // {
  //   path: `edit-program-master-record`,
  //   component: ProgramGoldenRecordEditComponent,
  //   outlet: 'modal'
  // },
  // {
  //   path: `edit-program-source-record/:${sourceDocumentId}`,
  //   component: EditProgramSourceDocumentComponent,
  //   outlet: 'modal'
  // },
  // {
  //   path: `edit-course-master-record`,
  //   component: CourseGoldenRecordEditComponent,
  //   outlet: 'modal'
  // },
  // {
  //   path: `edit-course-source-record/:${sourceDocumentId}`,
  //   component: EditCourseSourceDocumentComponent,
  //   outlet: 'modal'
  // },

  // { path: '404', component: NotFoundComponent},
  // { path: '500', component: ServerErrorComponent},
  // { path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes  , { enableTracing: false, scrollPositionRestoration: 'enabled'}) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule {

  constructor() {
    console.log("created app routing module");
  }

}
