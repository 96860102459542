import { Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

export function createInputId(base: string) { return base + 'id'; }

export type InputControlStatus = 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

export class BaseInput implements OnInit {

  @Input() control: FormControl;

  @Input() name: string = '';

  @Input() label?: string = '';

  @Input() hint?: string = '';

  @Input() isDisable?: boolean = false;

  @Input() errorMessage?: string;

  @Input() placeholderText?: string = '';

  @Input() utilityClass?: string;

  @Input() subModule?: string = '';

  @Input() xSelector?: string = '';

  id: string;

  ngOnInit() {
    this.id = createInputId(this.name);
  }

}
