
import { Observable ,  Subject, Subscription } from 'rxjs';
import {first} from "rxjs/internal/operators/first";

/**
 * Represents an object that holds some underlying, mutable data, that may or may not be loaded, and may be changed.
 */
export interface IMutableDataHolder {
  /**
   * Indicates that this object has been loaded.
   */
  readonly isLoaded: boolean;

  /**
   * Indicates that this object is currently loading (transitioning from unloaded to loaded).
   */
  readonly isLoading: boolean;

  /**
   * A promise that is resolved when the data is loaded.
   */
  readonly loadedPromise: Promise<this>;

  /**
   * An observable that will post this object when changes occur to it.
   */
  readonly changed$: Observable<this>;

  /**
   * An observable that will post this object when it is unloaded (invalidated).
   */
  readonly unloaded$: Observable<this>;

  /**
   * Ensures that the data for this object is either loaded or loading, or fetches the data.
   */
  ensureFetched(): this;
}

/**
 * Basic implementation for [IMutableDataHolder]. Handles managing the underlying data object and the various states
 * that a mutable data wrapper can be in.
 */
export abstract class BaseMutableDataWrapper<TData> implements IMutableDataHolder {
  /**
   * Holds values of cached properties that are based on the current underlying data object. Cleared whenever that
   * object changes.
   */
  private readonly _dataPropertyCache = new Map();

  /**
   * The underlying data that this object wraps.
   */
  private _data: TData | undefined;

  /**
   * Internal flag indicating that this wrapper has data.
   */
  private _isLoaded = false;

  /**
   * Internal flag indicating that the data for this wrapper has been requested, but has not yet arrived.
   */
  private _isLoading = false;

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Protected Interface

  /**
   * Gets the wrapped data, and also ensure that the data has been requested. By doing this, an unloaded wrapper will
   * be fetched as soon as anything (like an angular view) tries to access this data, essentially removing the need to
   * ever explicitly request that the data be fetched.
   */
  protected get data(): TData | undefined {
    this.ensureFetched();

    return this._data;
  }

  /**
   * Get the wrapped data, and throws an exception if it is not yet loaded.
   */
  protected get requiredData(): TData {
    if (! this.data)
      throw new Error('Data is required, but not loaded, into ' + this);

    return this.data;
  }

  protected changedSubject = new Subject<this>();
  protected unloadedSubject = new Subject<this>();

  /**
   * Performs mutation on the data, if it is loaded, throws an error otherwise. Computed properties are cleared,
   * change subject is notified. Should be used for internal data model mutations, like from setters. Does _not_ update
   * the model change detection, so if the model was changed, [isChanged] will return true.
   */
  protected mutateData(
    mutator: (data: TData) => void
  ) {
    mutator(this.requiredData);

    this.onDataChanged();
    this.changedSubject.next(this);
    this._dataPropertyCache.clear();
  }

  /**
   * Called when the underlying data is changed, but before the changed subject is notified.
   */
  protected onDataChanged() {}

  /**
   * Called when our data is requested, but we are not yet loaded or loading.
   */
  protected abstract fetchData();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Public Interface

  get isLoaded() { return this._isLoaded; }
  get isLoading() { return this._isLoading; }

  get changed$() { return this.changedSubject.asObservable(); }

  get loaded$() { return this.changedSubject.asObservable(); }

  get unloaded$() { return this.unloadedSubject.asObservable(); }

  get loadedPromise() {
    this.ensureFetched();
    return this.changed$.pipe(first())
      .toPromise();
  }

  /**
   * Unloads the data from this object.
   */
  unload() {
    this._dataPropertyCache.clear();
    this._isLoaded = false;
    this._isLoading = false;
    this.unloadedSubject.next(this);
  }

  /**
   * Updates the underlying data of this object, from an external source. Fires change events and marks
   * the object as loaded.
   */
  applyExternalData(
    newData: TData
  ) {
    this._data = newData;
    this._isLoaded = true;
    this._isLoading = false;
    this._dataPropertyCache.clear();

    this.onDataChanged();
    // Ensure loadedPromise is ready
    this.loadedPromise;
    this.changedSubject.next(this);
  }

  /**
   * Ensures that the data for this object is either loaded or loading, or fetches the data.
   */
  ensureFetched() {
    if (this._isLoaded || this._isLoading) {
      return this;
    }

    this._isLoading = true;
    this.fetchData();
    return this;
  }

  /**
   * Projects this data wrapper using the given function to a new data wrapper that will be kept up-to-date with
   * this one using the supplied function.
   */
  project<T>(mapFn: (input: this) => T | PromiseLike<T>): MutableDataProjecion<[ this ], T> {
    return new MutableDataProjecion<[ this ], T>(
      [ this ],
      ([ value ]) => mapFn(value)
    );
  }

  /**
   * Projects this data wrapper using the given function to a new data array wrapper that will be kept up-to-date with
   * this one using the supplied function.
   */
  projectToArray<T>(mapFn: (input: this) => T[] | PromiseLike<T[]>): MutableDataArrayProjection<[ this ], T> {
    return new MutableDataArrayProjection<[ this ], T>(
      [ this ],
      ([ value ]) => mapFn(value)
    );
  }

}

/**
 * Represents a projection or "map" of one or more [IMutableDataHolder]s to some other value. Allows creating persistent
 * derivations of mutable data that are kept up-to-date with the underlying data source.
 */
export class MutableDataProjecion<
  TInputs extends Array<IMutableDataHolder>,
  TProjected
> extends BaseMutableDataWrapper<TProjected> {
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly inputs: TInputs,
    private readonly inputFunc: (inputs: TInputs) => TProjected | PromiseLike<TProjected>
  ) {
    super();

    // Make sure we're marked as loading
    this.ensureFetched();

    // When all inputs are loaded, we'll load
    // TODO: this code goes OOM with some test data. why ?
    Promise.all(inputs.map(i => i.loadedPromise))
      .then(() => this.update());

    // Also update us or unload whenever an input changes
    for (const input of inputs) {
      this.subscriptions.push(input.changed$.subscribe(() => this.update()));
      this.subscriptions.push(input.unloaded$.subscribe(() => this.unload()));
    }
  }

  private destroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private currentUpdatePromise?: Promise<TProjected>;
  update() {
    for (const input of this.inputs) {
      // We'll get called again when this input is loaded later.
      if (! input.isLoaded) return;
    }

    const updatePromise = Promise.resolve(this.inputFunc(this.inputs));
    this.currentUpdatePromise = updatePromise;
    this.currentUpdatePromise.then(
      data => {
        if (this.currentUpdatePromise === updatePromise) {
          // Apply the now-loaded data only if the promise is current. If a new promise has come in since, wait
          // for that one instead.
          this.applyExternalData(data);
        }
      }
    );
  }

  /**
   * Projects the data within this projection using the given mapping function, handling the unwrapping and undefined cases.
   */
  flatProject<T>(mapFn: (input: TProjected) => T | PromiseLike<T>): MutableDataProjecion<[ BaseMutableDataWrapper<TProjected> ], T> {
    let lastData: TProjected | undefined;
    let lastSubscription: Subscription | undefined;

    const projection = this.project(() => {
      if (lastData != this.projectedData) {
        lastData = this.projectedData;

        if (lastSubscription) {
          lastSubscription.unsubscribe();
          lastSubscription = undefined;
        }

        if (this.projectedData instanceof BaseMutableDataWrapper) {
          lastSubscription = this.projectedData.changed$.subscribe(() => projection.update());
        }
      }

      return this.projectedData && mapFn(this.projectedData);
    });

    return projection;
  }

  /**
   * Projects the data within this projection using the given mapping function, handling the unwrapping and undefined cases.
   */
  flatProjectToArray<T>(mapFn: (input: TProjected) => T[] | PromiseLike<T[]>): MutableDataArrayProjection<[ BaseMutableDataWrapper<TProjected> ], T> {
    let lastData: TProjected | undefined;
    let lastSubscription: Subscription | undefined;

    const projection = this.projectToArray(() => {
      if (lastData != this.projectedData) {
        lastData = this.projectedData;

        if (lastSubscription) {
          lastSubscription.unsubscribe();
          lastSubscription = undefined;
        }

        if (this.projectedData instanceof BaseMutableDataWrapper) {
          lastSubscription = this.projectedData.changed$.subscribe(() => projection.update());
        }
      }

      return this.projectedData && mapFn(this.projectedData);
    });

    return projection;
  }

  get projectedData() { return this.data; }

  protected fetchData() {
    // Simply delegate to inputs
    for (const input of this.inputs) {
      input.ensureFetched();
    }
  }
}

/**
 * Represents a projection or "map" of one or more [IMutableDataHolder]s to an iterable value.  Allows creating
 * persistent derivations of mutable data that are kept up-to-date with the underlying data source.
 */
export class MutableDataArrayProjection<
  TInputs extends Array<IMutableDataHolder>,
  TProjected
> extends MutableDataProjecion<TInputs, Array<TProjected>> implements Iterable<TProjected> {
  private static emptyArray = [];

  constructor(
    inputs: TInputs,
    inputFunc: (inputs: TInputs) => Array<TProjected> | PromiseLike<Array<TProjected>>
  ) {
    super(inputs, inputFunc);
  }

  toArray() { return this.data || MutableDataArrayProjection.emptyArray; }

  length() { return this.data && this.data.length || 0; }

  [Symbol.iterator]() {
    return (this.data || MutableDataArrayProjection.emptyArray)[Symbol.iterator]();
  }
}
