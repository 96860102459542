import { ServiceManager } from '../services/service-manager';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from './base.component';

/**
 * Base class for routable components (views) in the application. Uses the [ServiceManager] to provide easy access to various
 * managers, and has some shortcut delegates for common operations.
 */
export abstract class BaseRoutableComponent extends BaseComponent {
  constructor(
    protected readonly serviceManager: ServiceManager,
    protected activatedRoute: ActivatedRoute,
  ) {
    super(serviceManager);
  }

  get entityManager() {
    return this.serviceManager.entityManager;
  }

  get logger() {
    return this.serviceManager.logger;
  }

  get college() {
    return this.applicationState.college;
  }

  get curriculumYear() {
    return this.applicationState.curriculumYear;
  }

  get route() {
    return this.activatedRoute;
  }

  get params() {
    return this.route.snapshot.params;
  }

  get queryParams() {
    return this.route.snapshot.queryParams;
  }

  get routeData() {
    return this.route.snapshot.data;
  }

  get applicationState () {
    return this.serviceManager.applicationState;
  }

  get headerTitle() {
    return this.serviceManager.headerTitle;
  }
}
