import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Observable} from "rxjs";
import {PaginationObject} from "../pagination/pagination.component";
import {
  FindTopCodesResponse,
  newPickedTopCode,
  PickedTopCode,
  ITopCode
} from '../../../api/datacleanuptool-api.model';
import { takeUntil } from 'rxjs/operators';
import { BaseRoutableComponent } from '../base-routable.component';
import { ServiceManager } from '../../services/service-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'app-top-code-picker',
  templateUrl: './top-code-picker.component.html'
})
export class TopCodePickerComponent extends BaseRoutableComponent implements OnInit{

  modalRef: BsModalRef;
  @ViewChild('template') template;

  readonly itemsPerPage = 10;
  searchResults$: Observable<FindTopCodesResponse>;

  private _searchTerm: string;
  private _page: number = 1;
  private _pickedTopCode: PickedTopCode;

  constructor(
    protected readonly serviceManager: ServiceManager,
    protected activatedRoute: ActivatedRoute,
    private modalServiceA: BsModalService,
    private router: Router,
  ) {
    super(serviceManager, activatedRoute);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalServiceA.show(template, {ignoreBackdropClick: true, backdrop: false, keyboard: false, class: 'modal-lg'});
  }

  closeModal() : void {
    this.modalRef.hide();

    if(this._pickedTopCode){
      this.applicationState.topCodePicked(
        {
          // TODO: Test when blank initially
          title : this._pickedTopCode.topCodeTitle,
          topCode : this._pickedTopCode.topCode,
          standardOccupations : this._pickedTopCode.standardOccupations
        });
    }
    else {
      this.applicationState.topCodePicked(undefined);
    }

    this.router.navigate(
      [
        './',
        // NOTE: No relative-path navigation is required because we are accessing
        // the parent's "activatedRoute" instance. As such, this will be executed
        // as if we were doing this in the parent view component.
        {
          outlets: {
            topCodePickerModal: null
          }
        }
      ],
      {
        relativeTo: this.route.parent // <--- PARENT activated route.
      }
    );
  }

  ngOnInit(): void  {
    this.applicationState.initiallyPickedTopCode$
      .pipe(takeUntil(this.onNgDestroy))
      .subscribe(
        pickedTopCode => {
          if(pickedTopCode) {
            this.pickTopCode(pickedTopCode);
          }
          this._searchTerm = undefined;
          this.updatePaginatedResults();
        }
      );

    setTimeout(() => {
      this.openModal(this.template);
    });

  }

  searchChanged(searchParams: string) {
    this._searchTerm = searchParams;
    this.resetToPageOne();
    this.updatePaginatedResults();
  }

  private resetToPageOne() {
    this._page = 1;
  }

  get page(){
    return this._page;
  }

  setPage(page: number) {
    // console.log("set page.." + page);
    this._page = page;
    this.updatePaginatedResults();
  }

  onPaginationChange(paginationObject: PaginationObject) {
    this.setPage(paginationObject.page);
  }

  private updatePaginatedResults() {
    console.log("filter and search: items per page:" + this.itemsPerPage + ",search term: " + this._searchTerm + ", page " + this._page);
    this.searchResults$ = this.curriculumYear.topCodes$(
      this.itemsPerPage,
      this._searchTerm,
      this._page);
  }

  isSelected(topCode: ITopCode) {
    return this._pickedTopCode && this._pickedTopCode.topCode === topCode.topCode;
  }

  pickTopCode(pickableTopCode: ITopCode) {
    this._pickedTopCode = newPickedTopCode({
      topCode: pickableTopCode.topCode,
      standardOccupations :pickableTopCode.standardOccupations,
      topCodeTitle : pickableTopCode.title,
      missing: false
    });
  }


}
