import { OnInit } from '@angular/core';
import {
  CurriculumEntityId,
  CurriculumEntityType,
  newCurriculumEntitySearchCriteria,
  NoticeTypeFilter,
  PaginatedFilteredCourseSearchResults,
  PaginatedFilteredProgramSearchResults,
  PipelineEntityType,
  Publishability,
} from '../../api/datacleanuptool-api.model';
import { CurriculumYear } from '../../shared/entity-system/entities/curriculum-year';
import { BaseRoutableComponent } from '../../shared/components/base-routable.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MasterRecordNoticeService, NoticeMetaData } from '../../shared/services/master-record-notice.service';
import { ServiceManager } from '../../shared/services/service-manager';
import { Observable } from 'rxjs';
import { ApplicationStateService } from '../../shared/services/application-state.service';
import { ExportSearchResultsService } from '../../shared/services/export-search-results.service';
import { tap } from 'rxjs/operators';

export abstract class BaseRecordsComponent<TSearchResults extends PaginatedFilteredCourseSearchResults | PaginatedFilteredProgramSearchResults> extends BaseRoutableComponent implements OnInit {

  Publishability = Publishability;

  private _searchTerm: string;
  private _checkedNoticeFilters: Set<string> = new Set();
  private _anyOfPublishabilities: Set<Publishability> = new Set();
  private _showActive = true;
  private _showInactive = false;
  private _page: number = 1;

  paginatedFilteredSearchResults$: Observable<TSearchResults> ;// = this._paginatedFilteredSearchResultsSubject.asObservable();

  public searchResultsLoaded: boolean = false;
  public showFilter = true;
  readonly itemsPerPage = 20;
  private _creatingLocalRecord: boolean = false;

  protected constructor(
    serviceManager: ServiceManager,
    activatedRoute: ActivatedRoute,
    public router: Router,
    protected masterRecordNoticeService: MasterRecordNoticeService,
    protected recordFilterService: ExportSearchResultsService,
    protected applicationStateService: ApplicationStateService,
    private recordType: PipelineEntityType,
  ) {
    super(serviceManager, activatedRoute);
  }

  ngOnInit() {
    this.loadResults();
  }

  protected loadResults() {
    this.paginatedFilteredSearchResults$ = undefined;

    const anyOfNoticeTypes: NoticeTypeFilter[] = Array.from(this._checkedNoticeFilters).map(
      noticeType => {
        return { noticeType: noticeType, fields: []};
      }
    );

    const criteria = newCurriculumEntitySearchCriteria({
      matchingSlugs: [],
      noneOfNonFieldNoticeTypes: [],
      noneOfPublishabilities: [],
      treatInactiveAsActiveForPublishabilityFilter: true,
      searchTerm : this.searchTerm,
      showActive : this._showActive,
      showInactive : this._showInactive,
      anyOfPublishabilities: Array.from(this._anyOfPublishabilities),
      anyOfNoticeTypes: anyOfNoticeTypes
    });

    this.paginatedFilteredSearchResults$ =
      this.findEntities$(criteria)
        .pipe(
          // TODO : handle error toast / go to page 1 if empty results and not page 1
          tap(() => this.searchResultsLoaded = true)
        ) as Observable<TSearchResults>;
  }

  protected abstract findEntities$(filterState);

// TODO: MasterRecord: migrate to CurriculumYear and remove this.
//   get curriculum(): CurriculumInfoClientEntity {return this.curriculumYear && this.curriculumYear.legacyCurriculum && this.curriculumYear.legacyCurriculum.target; }
//
//   get bulkAmendCourseRoute() {
//     return this.curriculum && this.curriculum.entityBulkAmendRoute(PipelineEntityType.COURSE);
//   }
//
//   get bulkAmendProgramRoute() {
//     return this.curriculum && this.curriculum.entityBulkAmendRoute(PipelineEntityType.PROGRAM);
//   }

  onRecordSelected(detailId: CurriculumEntityId) {
    this.router.navigate([{ outlets: { popup: ['detail', detailId.id] } }],
      { relativeTo: this.route});
  }

  /** Pagination **/

  get page(){
    return this._page;
  }

  onPageChange($event: number) {
    // a page change was requested from a child component
    this._page = $event;
    this.loadResults();
  }

  /*               Search              */

  search(searchTerm: string) {
    if(searchTerm !== this._searchTerm){
      this._searchTerm = searchTerm;
      this.resetToFirstPage();
      this.loadResults();
    }
  }

  get searchTerm() {
    if (this.hasSearchTerm()) {
      return this._searchTerm;
    }
    else {
      return '';
    }
  }

  hasSearchTerm() : boolean {
    return this._searchTerm !== undefined && this._searchTerm !== "";
  }

  /*              Filters                */

  errorTypes(): NoticeMetaData[] {
    return this.masterRecordNoticeService.nonFieldErrorTypes();
  }

  warningTypes(): NoticeMetaData[] {
    return this.masterRecordNoticeService.nonFieldWarningTypes();
  }

  get filters() : string[] {
    return Array.from(this._checkedNoticeFilters.values()).map(
      filterType => this.masterRecordNoticeService.getNoticeMetaDataForType(filterType).label
    );
  }

  toggleNoticeFilter(curNoticeType: string) {
    if(this._checkedNoticeFilters.has(curNoticeType)){
      this._checkedNoticeFilters.delete(curNoticeType);
    }
    else {
      this._checkedNoticeFilters.add(curNoticeType);
    }
    this.resetToFirstPage();
    this.loadResults();
  }

  isNoticeFilterChecked(curNoticeType: string) {
    return this._checkedNoticeFilters.has(curNoticeType);
  }

  toggleNoticeSeverityFilter(noticeSeverity: Publishability) {
    if(this._anyOfPublishabilities.has(noticeSeverity)) {
      this._anyOfPublishabilities.delete(noticeSeverity);
    }
    else {
      this._anyOfPublishabilities.add(noticeSeverity);
    }
    this.resetToFirstPage();
    this.loadResults();
  }

  isNoticeSeverityChecked(noticeSeverity: Publishability) {
    return this._anyOfPublishabilities.has(noticeSeverity);
  }

  get showActive() {
    return this._showActive;
  }

  get showInactive() {
    return this._showInactive;
  }

  toggleShowInactive() {
    this._showInactive = !this._showInactive;
    this.resetToFirstPage();
    this.loadResults();
  }

  toggleShowActive(){
    this._showActive = !this._showActive;
    this.resetToFirstPage();
    this.loadResults();
  }

  private resetToFirstPage(){
    this._page = 1;
  }

  toggleShowFilter() {
    if (this.showFilter) {
      document.getElementById('filters').style.display = 'none';
      this.showFilter = false;
    }
    else {
      document.getElementById('filters').style.display = 'block';
      this.showFilter = true;
    }
  }

  get curriculumYear(): CurriculumYear {
    return this.applicationState.curriculumYear;
  }

  exportReport() {
    this.recordFilterService.exportReport(
      this.recordType === PipelineEntityType.COURSE ? CurriculumEntityType.COURSE : CurriculumEntityType.PROGRAM,
      this.searchTerm,
      this.showActive,
      this.showInactive,
      this._anyOfPublishabilities,
      this._checkedNoticeFilters,
    );
  }

  addLocalCourseOrProgram() {
    this.router.navigate([{ outlets: { popup: ['add'] } }],
      { relativeTo: this.route});

  }

  creatingLocalRecord() {
    return this._creatingLocalRecord;
  }

  noticeCount(noticeTypeCounts: { [noticeType: string]: number }, noticeType: string) {
    if(noticeTypeCounts[noticeType]){
      return noticeTypeCounts[noticeType];
    }
    else {
      return 0;
    }
  }

}
