import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

import {LocalStorageService} from 'ngx-webstorage';
import { AuthenticatedUser } from '../../api/datacleanuptool-api.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  currentUser$: BehaviorSubject<AuthenticatedUser> = new BehaviorSubject(null);

  constructor(private localStorageService: LocalStorageService) { }

  setCurrentUser(user: AuthenticatedUser): void {
    this.localStorageService.store('currentUser', {firstName: user.firstName, lastName: user.lastName, email: user.email});
    this.currentUser$.next(user);
  }

  getCurrentUser(): Observable<AuthenticatedUser> {
    const currentUser = this.localStorageService.retrieve('currentUser');

    if (currentUser) {
      this.currentUser$.next(currentUser);
    }

    return this.currentUser$.asObservable();
  }

}
