import { Injectable } from '@angular/core';


import { EnvironmentConfig } from '../model/env-specific';
import { LoggingService } from './logging-service';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class EnvironmentConfigService {

  constructor(
    private http: HttpClient,
    private loggingService: LoggingService
  ) {}

  get environmentConfig() {
    return this.http.get<EnvironmentConfig>('./assets/env-specific.json').toPromise();
  }

}
