import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-input-upload',
  templateUrl: './input-upload.component.html'
})
export class InputUploadComponent implements OnInit {

  @Input() isDisabled: boolean = false;
  @Input() label: string;
  @Output() emittedFile: EventEmitter<any> = new EventEmitter();
  fileName: string;


  constructor() { }

  public changeListener($event) {

    const reader = new FileReader();

    if ($event.target && $event.target.files && $event.target.files[0]) {
      // file was chosen
      this.fileName = $event.target.files[0].name;
      reader.readAsText($event.target.files[0]);
    }
    else {
      this.fileName = null;
    }

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        throw new Error("array buffer CSV not supported")
      } else {
        this.emitCsvAsText(reader.result);
      }
      reader.onerror = err => console.log( 'input-upload.component.changeListener :: There was an error reading the csv ',err)
    }
  }

  emitCsvAsText (textFile: string) {
    this.emittedFile.emit(textFile);
  }

  ngOnInit() {
  }

}
