import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseInput } from '../base-input';
import { FormArray, FormControl } from '@angular/forms';

export interface MultiInputCollator {
  location: number;
  multiInputItem: FormControl;
}

@Component({
  selector: 'app-multi-input',
  templateUrl: './multi-input.component.html'
})

export class MultiInputComponent extends BaseInput implements OnInit {

  @Input() formArray: FormArray;

  @Input() buttonLabel?: string;

  @Output() addClick: EventEmitter<any> = new EventEmitter<any>();

  @Output() removeClick: EventEmitter<MultiInputCollator> = new EventEmitter<MultiInputCollator>();

  @Output() upClick: EventEmitter<MultiInputCollator> = new EventEmitter<MultiInputCollator>();

  @Output() downClick: EventEmitter<MultiInputCollator> = new EventEmitter<MultiInputCollator>();

  constructor() {
    super();
    super.ngOnInit();
  }

}
