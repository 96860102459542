import {
  HumanReadableNotice,
  HumanReadableNoticeSeverity,
  MasterRecordNoticeService
} from "../../shared/services/master-record-notice.service";
import {
  CurriculumEntityId,
  Notice, PaginatedFilteredCourseSearchResults, PaginatedFilteredProgramSearchResults, CurriculumEntityType,
} from '../../api/datacleanuptool-api.model';
import {EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ExportSearchResultsService} from "../../shared/services/export-search-results.service";
import {createPaginationObject, PaginationObject} from "../../shared/components/pagination/pagination.component";
import {ApplicationStateService} from "../../shared/services/application-state.service";

export abstract class BaseCurriculumEntityTableComponent<TSearchResults extends PaginatedFilteredProgramSearchResults | PaginatedFilteredCourseSearchResults> implements OnInit{
  readonly WARNING = HumanReadableNoticeSeverity.WARNING;
  readonly CRITICAL = HumanReadableNoticeSeverity.CRITICAL;

  @Input() itemsPerPage: number;
  @Input() page: number;
  @Input() searchResults: TSearchResults;

  @Output() selectedRecord = new EventEmitter<CurriculumEntityId>();
  @Output() pageChange = new EventEmitter<number>();

  paginationObject: PaginationObject;
  private _totalPages: number;

  protected constructor(
    protected applicationStateService: ApplicationStateService,
    protected recordFilterService: ExportSearchResultsService,
    protected masterRecordNoticeService: MasterRecordNoticeService,
  ) {
  }

  ngOnInit() {
    this.initPagination();
  }

  updatePaginationObject(paginationOject: PaginationObject) {
    this.pageChange.next(paginationOject.page);
  }

  selectMasterRecord(detailId: CurriculumEntityId) {
    this.selectedRecord.emit(detailId);
  }

  getHumanReadableNoticeForEntityType(curriculumEntityType: CurriculumEntityType, notice: Notice, field: string) : HumanReadableNotice{
    return this.masterRecordNoticeService.getHumanReadableNotice(curriculumEntityType, notice, field);
  }

  getHumanReadableNoticesForEntityType(curriculumEntityType: CurriculumEntityType, nonFieldNotices: Notice[], severity:HumanReadableNoticeSeverity) : HumanReadableNotice[] {
    return nonFieldNotices.map(notice => this.getHumanReadableNoticeForEntityType(curriculumEntityType, notice, undefined))
      .filter(nonFieldNotice => nonFieldNotice.severity === severity);
  }

  private initPagination() {
    const page = Math.ceil(this.searchResults.totalSearchResultCount / this.itemsPerPage) ;
    this._totalPages =  isNaN(page) ? 1 : page;
    this.paginationObject = createPaginationObject(this.page);
  }

  get totalPages() {
    return this._totalPages;
  }

}
