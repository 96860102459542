import {Component, Input, OnChanges} from '@angular/core';

/**
 * Adapted from https://stackoverflow.com/questions/37819312/angular-2-read-more-directive
 */
@Component({
  selector: 'app-read-more',
  template: `
    <span [innerHTML]="currentText">
    </span>
    <button class="btn btn-link p-0 align-top ml-1" *ngIf="textExceedsLength" (click)="toggleView()">{{isCollapsed ? 'More' : 'Less'}}</button>
  `
})
export class ReadMoreComponent implements OnChanges {
  @Input() text: string;
  @Input() maxLength: number = 100;
  currentText: string;
  textExceedsLength: boolean = true;

  isCollapsed: boolean = true;

  toggleView() {
    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }

  determineView() {
    if (!this.text || this.text.length <= this.maxLength) {
      this.isCollapsed = false;
      this.textExceedsLength = false;
    } else {
      this.textExceedsLength = true;
    }

    if (this.isCollapsed) {
      this.currentText = this.text.substring(0, this.maxLength) + "...";
    } else {
      this.currentText = this.text;
    }
  }

  ngOnChanges() {
    this.determineView();
  }
}
