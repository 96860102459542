import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import 'assets/js/harvey.js';
import { Breakpoint, BreakpointService } from './shared/services/breakpoint.service';
import { Subscription } from 'rxjs';

declare var Harvey: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  activeBreakpoint: Breakpoint;

  isNavDrawerOpen: boolean;

  private breakpointSubscription: Subscription;

  constructor(
    private breakpointService: BreakpointService,
  ) {}

  ngOnInit() {
    this.breakpointSubscription = this.breakpointService.activeBreakpoint$.subscribe(
      activeBreakpoint => {
        this.activeBreakpoint = activeBreakpoint;
        this.updateInertOnBreakpointChange(activeBreakpoint);
      }
    );
  }

  // modalOutletRouteEvent(event) {
  //   this.isModalOpen = !this.isModalOpen;
  //   this.navigationStateService.toggleBodyIsDialogBackground();
  // }

  private updateInertOnBreakpointChange(breakpoint: Breakpoint) {
    /**
     * left navigation has been removed for the time being - commenting out the navigation functionality
     */
    // switch (breakpoint) {
    //   case Breakpoint.MOBILE: {
    //     this.logger.log('AppComponent.Breakpoint :: MOBIL');
    //     this.navigationService.isNavDrawerOpen = false;
    //     this.navigationService.defaultInertForLessThenHD();
    //     break;
    //   }
    //   case Breakpoint.TABLET: {
    //     this.logger.log('AppComponent.Breakpoint :: TABLET');
    //     this.navigationService.isNavDrawerOpen = false;
    //     this.navigationService.defaultInertForLessThenHD();
    //     break;
    //   }
    //   case Breakpoint.LAPTOP: {
    //     this.logger.log('AppComponent.Breakpoint :: LAPTOP');
    //     this.navigationService.isNavDrawerOpen = false;
    //     this.navigationService.defaultInertForLessThenHD();
    //     break;
    //   }
    //   case Breakpoint.HD: {
    //     this.logger.log('AppComponent.Breakpoint :: HD');
    //     this.navigationService.defaultInertForHD();
    //     break;
    //   }
    //   default: {
    //     break;
    //   }
    // }
  }

  ngAfterViewInit() {
    Harvey.attach('screen and (max-width:767px)', {
      on: () => {
        this.breakpointService.announceActiveBreakpointChange(Breakpoint.MOBILE);
      }
    });

    Harvey.attach('screen and (min-width:768px) and (max-width:1023px)', {
      on: () => {
        this.breakpointService.announceActiveBreakpointChange(Breakpoint.TABLET);
      }
    });

    Harvey.attach('screen and (min-width:1024px) and (max-width:1439px)', {
      on: () => {
        this.breakpointService.announceActiveBreakpointChange(Breakpoint.LAPTOP);
      }
    });

    Harvey.attach('screen and (min-width:1440px)', {
      on: () => {
        this.breakpointService.announceActiveBreakpointChange(Breakpoint.HD);
      }
    });
  }

  ngOnDestroy() {
    if (this.breakpointSubscription) { this.breakpointSubscription.unsubscribe(); }
  }

}
