import { Component, Input, OnInit } from '@angular/core';
import { BaseInput } from '../base-input';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
})
export class InputComponent extends BaseInput implements OnInit {

  @Input() type: string;

  @Input() multiline?: boolean = false;

  @Input() rows?: number;

  @Input() subModule?: string = '';

  @Input() xSelector?: string = '';

  @Input()
    set value(value: string) { this._value = (value === undefined) ? '' : value; }
      private _value?: string = '';

  get value(): string { return this._value; }

  constructor () {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.control.setValue(this.value);
  }

}

