import { Component, OnInit } from '@angular/core';
import {
  PipelineEntityType, PaginatedFilteredCourseSearchResults, CurriculumEntitySearchCriteria
} from '../../../api/datacleanuptool-api.model';
import { BaseRecordsComponent } from '../base-records.component';
import {ActivatedRoute, Router} from '@angular/router';
import { MasterRecordNoticeService } from '../../../shared/services/master-record-notice.service';
import { ExportSearchResultsService } from '../../../shared/services/export-search-results.service';
import { ServiceManager } from '../../../shared/services/service-manager';
import {ApplicationStateService} from "../../../shared/services/application-state.service";
import {Observable} from "rxjs";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-course-records',
  templateUrl: './course-records.component.html'
})
export class CourseRecordsComponent extends BaseRecordsComponent<PaginatedFilteredCourseSearchResults> implements OnInit {

  constructor(
    serviceManager: ServiceManager,
    activatedRoute: ActivatedRoute,
    router: Router,
    protected noticeTypeService: MasterRecordNoticeService,
    protected recordFilterService: ExportSearchResultsService,
    protected applicationStateService: ApplicationStateService,
  ) {
    super(
      serviceManager,
      activatedRoute,
      router,
      noticeTypeService,
      recordFilterService,
      applicationStateService,
      PipelineEntityType.COURSE,
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.headerTitle.setTitle(['Course Records']);
    this.applicationState.onCourseUpdated$
      .pipe(takeUntil(this.onNgDestroy))
      .subscribe( updatedEntityId =>{
          super.loadResults();
        }
      );
    this.applicationState.onCourseAdded$
      .pipe(takeUntil(this.onNgDestroy))
      .subscribe( () =>{
          this.loadResults();
        }
      );
  }

  protected findEntities$(criteria: CurriculumEntitySearchCriteria) : Observable<PaginatedFilteredCourseSearchResults>{
    return this.curriculumYear.courses$(criteria, this.page, this.itemsPerPage);
  }
}
