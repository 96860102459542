import {AfterViewInit, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import { newPickableCourse, newPickedCourse, PickedCourse } from '../../../api/datacleanuptool-api.model';
import {ServiceManager} from "../../services/service-manager";
import { ActivatedRoute, Router } from '@angular/router';
import {UUID} from "angular2-uuid";
import { BaseRoutableComponent } from '../base-routable.component';
import { Subscription } from 'rxjs';
import { sortBy } from '../../util/array-utils';
import {BsModalRef, BsModalService} from "ngx-bootstrap";

@Component({
  selector: 'app-picked-courses',
  templateUrl: './picked-courses.component.html'
})
export class PickedCoursesComponent extends BaseRoutableComponent implements AfterViewInit{

  @Output() pickedCoursesChanged: EventEmitter<PickedCourse[]> = new EventEmitter<PickedCourse[]>();

  @Input() pickedCourses: PickedCourse[];
  id : UUID;
  collapsed: boolean = true;
  private listenForPickedCoursesSub: Subscription;
  @Input() saving: boolean;
  modalRef: BsModalRef;

  constructor(
    protected readonly serviceManager: ServiceManager,
    protected activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
  ) {
    super(serviceManager, activatedRoute);
    this.id = UUID.UUID();
  }

  ngAfterViewInit(): void {
    this.pickedCourses = sortBy(this.pickedCourses, course => course.label);
  }

  pickCourses() {
    this.listenForPickedCourses();
      this.applicationState.initiallyPickedCourses = this.pickedCourses

        .filter(pickedCourse => !pickedCourse.missing)
        // if there is no pickable course for this slug, its an 'orphan' slug (one without an actual course).
        // this can happen if:
        // 1. the course was removed (by a re-import of source data) *after* it was added to this course list
        // or
        // 2. if this course list was uploaded with incorrect course slugs (future).
        // in this case, the raw value is all that we have

        .map(pickedCourse =>
        newPickableCourse(
          {
            active: !pickedCourse.inActive,
            label : pickedCourse.label,
            publishErrors : pickedCourse.publishErrors,
            slug: pickedCourse.slug,
            title : pickedCourse.title
          }
        )
        );

    // route to the course picker modal
    this.router.navigate([{ outlets: { coursePickerModal: ['course-picker'] } }],
      { relativeTo: this.route, skipLocationChange: true});
  }

  private listenForPickedCourses() {
    if(this.listenForPickedCoursesSub && !this.listenForPickedCoursesSub.closed){
      this.listenForPickedCoursesSub.unsubscribe();
    }
    this.listenForPickedCoursesSub = this.applicationState.onCoursesPicked$
      .subscribe(
        pickableCourses => {
          if(pickableCourses){
            // add the missing ones back in again.  we did not send these to the picker
            this.pickedCourses =

              sortBy(this.pickedCourses.filter(course => course.missing)
              .concat(
              pickableCourses.map(pickableCourse =>
                newPickedCourse(
                  {
                    inActive: !pickableCourse.active,
                    label : pickableCourse.label,
                    missing: false,
                    publishErrors : pickableCourse.publishErrors,
                    slug: pickableCourse.slug,
                    title : pickableCourse.title
                  })
              )), course => course.label
            );

            this.applicationState.initiallyPickedCourses = undefined;
            this.pickedCoursesChanged.emit(this.pickedCourses);
            if(this.listenForPickedCoursesSub && !this.listenForPickedCoursesSub.closed){
              this.listenForPickedCoursesSub.unsubscribe();
            }
          }
        }
      );
  }

  removeCourse(course: PickedCourse) {
    this.pickedCourses = this.pickedCourses.filter(pickedCourse => pickedCourse !== course);
    this.pickedCoursesChanged.emit(this.pickedCourses);
  }

  removeAllCourses(){
    this.pickedCourses =[];
    this.pickedCoursesChanged.emit(this.pickedCourses);
    this.closeConfirmRemoveAllModal();
  }

  toggleCollapsed() {
    this.collapsed = !this.collapsed;
  }

  missingCount(pickedCourses: PickedCourse[]) {
    return pickedCourses.filter(pickedCourse => pickedCourse.missing).length;
  }

  inactiveCount(pickedCourses: PickedCourse[]) {
    return pickedCourses.filter(pickedCourse => pickedCourse.inActive && !pickedCourse.missing).length;
  }

  unpublishableCount(pickedCourses: PickedCourse[]) {
    return pickedCourses.filter(pickedCourse => ! pickedCourse.inActive
      && pickedCourse.publishErrors && !pickedCourse.missing
    ).length;
  }

  publishableCount(pickedCourses: PickedCourse[]) {
    return pickedCourses.filter(pickedCourse => ! pickedCourse.inActive
    && !pickedCourse.publishErrors && !pickedCourse.missing
    ).length;
  }

  openConfirmRemoveAllModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {ignoreBackdropClick: true, backdrop: false});
  }

  closeConfirmRemoveAllModal() {
    this.modalRef.hide();
  }
}
