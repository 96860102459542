import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {HttpErrorResponse} from '@angular/common/http';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {createToastObject, ToastService, ToastType} from '../../../shared/services/toast.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  submitting = false;

  constructor(
    private toastService: ToastService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthenticationService) {}

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {

    this.form = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
      'password': ['', Validators.required],
    });
  }

  submitForm() {
    this.submitting = true;
    if (!this.form.valid) {
      Object.keys(this.form.controls).forEach(key => {

        const controlErrors: ValidationErrors = this.form.get(key).errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
          });
        }
      });
      throw Error("form is not valid");
    }
    this.authService.logIn(this.form.value.email, this.form.value.password).subscribe(() => {
        this.submitting = false;
        if (this.authService.isLoggedIn) {
          const redirectUrl = this.authService.redirectUrl || '/colleges';
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR, 'User name or password is incorrect'));
        }
      },
      (error: HttpErrorResponse) => {
        this.submitting = false;
        if (error && error.status === 401) {
          this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR, 'User name or password is incorrect'));
        } else {
          console.log(error);
        }
      });
  }
}
