import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ResetPasswordService} from "../reset-password/reset-password.service";
import {HttpErrorResponse} from "@angular/common/http";
import { createToastObject, ToastService, ToastType } from '../../../shared/services/toast.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    private toastService: ToastService,
    private resetPasswordService: ResetPasswordService
  ) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
    });
  }

  submitForm() {
    this.submitting = true;
    this.resetPasswordService.requestForgotPasswordEmail(this.form.controls.email.value)
      .subscribe((response: boolean) => {
          this.submitting = false;
          this.toastService.add(createToastObject(ToastType.TOAST_IS_SUCCESS, `Email sent to ${this.form.controls.email.value}`));
      },
      (error: HttpErrorResponse) => {
        this.submitting = false;
        this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR, `Email failed to send to ${this.form.controls.email.value}`));
      });

  }
}
