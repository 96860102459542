import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-formhelper',
  templateUrl: './formhelper.component.html'
})
export class FormhelperComponent {
  @Input() instructions?: string;

  @Input() multiline?: boolean = false;

  @Input() subModule?: string = '';

  @Input() xSelector?: string = '';

  @Input() cssOverride?: string = '';

  @Input() utilityClass?: string = '';
}
