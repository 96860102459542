import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectFormControlOption } from '../select/select.component';
import { BaseInput } from '../base-input';

export enum IsActiveSelectFilterOptions {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive'
}

export enum HasErrorSelectFilterOptions {
  ALL = 'All_errors',
  CLEAN = 'clean',
  HAS_ERRORS = 'has_errors',
  HAS_WARNNGS = 'has_warnings'
}

@Component({
  selector: 'app-select-filter',
  template: `
    <div class="forminput forminput-with-icon {{subModule}} {{layoutClass}} {{xSelector}} {{utilityClass}}">
      <div class="forminput-x-containerlabelhint u-visuallyhidden">
        <label class="forminput-x-label u-text-small u-text-bold">{{label}}</label>
      </div>
      <div class="forminput-x-containerinput">
        <select
                class="forminput-x-input"
                (change)="filterChangeEmitter.emit($event.target.value)"
        >
          <option *ngIf="!defaultValue" disabled="" hidden="" selected="" value="">Filter Option</option>
          <option *ngFor="let option of selectOptions"
                  [selected] = "option.value == defaultValue"
                  [value]="option.value">{{option.name}}</option>
        </select>
        <svg class="forminput-x-icon">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon--arrows-up-down"></use>
        </svg>
      </div>
    </div>
  `
})
export class SelectFilterComponent extends BaseInput implements OnInit {

  @Input() selectOptions: SelectFormControlOption

  @Input() layoutClass?: string = '';

  @Input() defaultValue?: string;

  @Output() filterChangeEmitter: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit() {
    super.ngOnInit();
  }

}
