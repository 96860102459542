import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationStateService } from '../services/application-state.service';

@Injectable()
export class HasImportedStateCoursesDataGuard implements CanActivate {

  constructor(
    private appState: ApplicationStateService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean {
    if (
      this.appState.curriculumYear &&
      this.appState.curriculumYear.hasImportedCourses() &&
      this.appState.curriculumYear.hasImportedStateCourses()
    ) {
      return true;
    }
    else {
      return this.router.createUrlTree(this.appState.curriculumYear.stateCoursesImportRoute());
    }
  }
}
