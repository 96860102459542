import { Injectable } from '@angular/core';

import {Observable} from 'rxjs';
import { ApiUrlService } from '../../../shared/services/api-url.service';
import {
  newForgotPasswordRequest,
  ResetPasswordRequest
} from '../../../api/datacleanuptool-api.model';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private apiService: ApiUrlService) { }

  resetPasswordFromToken(resetPasswordFromTokenRequest: ResetPasswordRequest ) {
    return this.apiService.postRest<boolean>(`password-resets`, resetPasswordFromTokenRequest);
  }

  requestForgotPasswordEmail(email: string) : Observable<boolean> {
    return this.apiService.postRest<boolean>(`forgot-password-emails`,
      newForgotPasswordRequest({email: email}));
  }
}
