import {Component, OnInit} from '@angular/core';
import {BaseRoutableComponent} from '../../shared/components/base-routable.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ServiceManager} from '../../shared/services/service-manager';
import {collegeIdParam, curriculumYearParam} from '../../app-routing.module';

@Component({
  selector: 'app-college-data',
  templateUrl: './college-data.component.html'
})
export class CollegeDataComponent extends  BaseRoutableComponent implements OnInit {

  constructor (
    activatedRoute: ActivatedRoute,
    private router: Router,
    serviceManager: ServiceManager,
  ) {
    super(
      serviceManager,
      activatedRoute,
    );
  }

  curriculumYearRoute = [];

  ngOnInit() {
    // TODO: build theses routes better
    this.curriculumYearRoute = [ '/colleges',
                                 this.params[collegeIdParam],
                                 'curriculum-years',
                                 this.params[curriculumYearParam]
                             ];
  }

  selectedDataSource (dataSource: string) {}

}
