import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'td[data-table-cell-content]',
  template: `

    <div>
      <div>
        <span [class.sr-only]="!cellLabel">
          {{cellLabel ? cellLabel : 'Title'}}
        </span>
          <ng-content></ng-content>
      </div>
    </div>
  `
})
export class DataTableCellContentComponent {
   cell = {
    label: 'entity.label',
    dataSource: 'notice.sourceSystem'
  }

  @HostBinding('class.table-x-cell')
  @Input() isTableXCell: boolean = true;

  @Input() isFirstCell: boolean = false;

  @Input() cellLabel: string= '';

}

