import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CheckboxGroupItem } from './checkbox-group-item';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html'
})
export class CheckboxGroupComponent implements OnDestroy {

  @Output() checkboxGroupSelected = new EventEmitter();

  @Input() boxesCheckedByDefault: string[] = [];

  @Input()
    set checkboxOptions(value: CheckboxGroupItem[]) {
      this._checkboxOptions = value;
      this.checkDefaultBoxes();
    }
    private _checkboxOptions = Array<CheckboxGroupItem>();

  get checkboxOptions(): CheckboxGroupItem[] {
    return this._checkboxOptions;
  }

  ngOnDestroy() {
    this.resetCheckboxGroup();
  }

  checkDefaultBoxes() {
    this._checkboxOptions.map( x =>
      x.checked = this.boxesCheckedByDefault.includes(x.value)
    );
  }

  onToggle() {
    const checkedOptions = this._checkboxOptions.filter(x => x.checked);
    this.emitSelectedCheckboxes(checkedOptions.map(x => x.value));
  }

  resetCheckboxGroup() {
    this._checkboxOptions.map( x => x.checked = false)
    this.emitSelectedCheckboxes([]);
  }

  emitSelectedCheckboxes(checked) {
    this.checkboxGroupSelected.emit(checked);
  }

}
