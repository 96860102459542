/* tslint:disable:component-selector */

import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'table[data-table]',
  template: `
    <tr>
      <th *ngFor="let column of columns">{{column}}</th>
    </tr>

    <ng-content></ng-content>
  `
})
export class DataTableComponent {

  @HostBinding('class.table')
  @Input() isTable: boolean = true;

  @Input() columns: string[];
}


//////////// Data table CheckboxGroupSelected ////////////
@Component({
  selector: 'data-table-toggle',
  template: `    
    <div class="l-flex l-flex-alignbaseline" *ngIf="length">
      <label class="l-flex l-flex-alignbaseline"> 
        <input type="checkbox" (change)="show=input.checked" #input
               style="-webkit-appearance: checkbox !important;"
        />
        <p class="u-text-bold u-margin-left1x" style="cursor: pointer">
          {{title}} ({{length}})
        </p>
      </label>
      <ng-content></ng-content>
    </div>
  `
})
export class DataTableToggleComponent implements DataTableToggle {

  @Input() title: string = '';

  @Input() length: string = '';

  @Input()
  get show(): boolean { return this._show; }

  set show(value: boolean) {
    this._show = value;
    this.showChange.emit(this._show);
  }

  @Output() showChange = new EventEmitter<boolean>();

  private _show: boolean = false;

}


//////////// Data table heading ////////////
@Component({
  selector: 'data-table-toggle-heading',
  template: `      
    <h3 class="u-text-bold u-margin-top2x"
        *ngIf="toggle.show">
      {{toggle.title}}
      <ng-content></ng-content>
    </h3>
  `
})
export class DataTableToggleHeadingComponent {
  @Input() toggle: DataTableToggleComponent;

}

export interface DataTableToggle {
  length: string; // Data table item count
  title: string;
  show: boolean;
}






