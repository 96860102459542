import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApplicationStateService } from '../services/application-state.service';
import { ClientEntityManager } from '../entity-system/client-entity-manager.service';
import { collegeIdParam, curriculumYearParam } from '../../app-routing.module';
import { flatMap, map } from 'rxjs/operators';

@Injectable()
export class ApplicationDataGuard implements CanActivate {

  constructor(
    private entityManager: ClientEntityManager,
    private appState: ApplicationStateService,
    private router: Router
  ) {}

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    console.log('guard');
    const params = next.params;
    const collegeSlug = params[collegeIdParam];
    const curriculumYear = params[curriculumYearParam];
    if (collegeSlug && curriculumYear) {
      return this.selectCollegeAndCurriculumYear(collegeSlug, curriculumYear);
    }
    else if (collegeSlug) {
      return this.router.parseUrl('colleges/' + collegeSlug + '/curriculum-years');
    }
    else {
      return false;
    }
  }

  private selectCollegeAndCurriculumYear(collegeSlug, curriculumYear): Observable<boolean | UrlTree> {
    return this.appState.selectCollege$(collegeSlug)
      .pipe(flatMap(collegeSelected => {
          if (collegeSelected) {
            return this.selectCurriculumYear(curriculumYear, collegeSlug);
          } else {
            return of(this.router.parseUrl('colleges'));
          }
        })
      );
  }

  private selectCurriculumYear(curriculumYear: number, collegeSlug: string): Observable<boolean | UrlTree> {
    return this.appState.selectCurriculumYear$(curriculumYear).pipe(
      map(yearSelected => {
        if (yearSelected) {
          return true;
        } else {
          return this.router.parseUrl('colleges/' + collegeSlug + '/curriculum-years');
        }
      })
    );
  }
}
