import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationStateService } from '../services/application-state.service';
import { ClientEntityManager } from '../entity-system/client-entity-manager.service';
import { SourceSystem } from '../../api/datacleanuptool-api.model';

@Injectable()
export class CollegeSourceSystemDataGuard  implements CanActivate {

  constructor(
    private entityManager: ClientEntityManager,
    private appState: ApplicationStateService,
  ) {}

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    console.log('CollegeSourceSystemDataGuard :: COLLEGE');
    this.appState.currentSourceSystem = SourceSystem.COLLEGE;
    return true;
  }
}
