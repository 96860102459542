import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationStateService } from '../services/application-state.service';

@Injectable()
export class HasImportedCollegeCoursesDataGuard implements CanActivate {

  constructor(
    private appState: ApplicationStateService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean {
    console.log("has imported college courses data guard invoked");
    if (
      this.appState.curriculumYear &&
      this.appState.curriculumYear.hasImportedCourses() &&
      this.appState.curriculumYear.hasImportedCollegeCourses()
    ) {
      return true;
    }
    else {
      console.log("college missing either curriculum year, imported courses or imported college courses");
      return true;
    }
  }
}
