import { Component} from '@angular/core';

import {
  AwardType,
  Notice,
  PaginatedFilteredProgramSearchResults, CurriculumEntityType,
} from '../../../../api/datacleanuptool-api.model';
import {BaseCurriculumEntityTableComponent} from "../../base-curriculum-entity-table.component";
import {ApplicationStateService} from "../../../../shared/services/application-state.service";
import {ExportSearchResultsService} from "../../../../shared/services/export-search-results.service";
import {
  HumanReadableNoticeSeverity,
  MasterRecordNoticeService
} from "../../../../shared/services/master-record-notice.service";
import {humanReadableAwardType} from "../../../../shared/model/award-types";

@Component({
  selector: 'app-program-table',
  templateUrl: './program-table.component.html'
})

export class ProgramTableComponent extends BaseCurriculumEntityTableComponent<PaginatedFilteredProgramSearchResults>{

  constructor(
    protected applicationStateService: ApplicationStateService,
    protected recordFilterService: ExportSearchResultsService,
    protected masterRecordNoticeService: MasterRecordNoticeService,
  ) {
    super(applicationStateService, recordFilterService, masterRecordNoticeService);
  }

  getHumanReadableNotices(nonFieldNotices: Notice[], noticeSeverity: HumanReadableNoticeSeverity) {
    return super.getHumanReadableNoticesForEntityType(CurriculumEntityType.PROGRAM, nonFieldNotices, noticeSeverity);
  }

  humanReadableAwardType(awardType: AwardType) {
    return humanReadableAwardType(awardType);
  }
}
