import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {ResetPasswordService} from "./reset-password.service";
import {HttpErrorResponse} from "@angular/common/http";
import { createToastObject, ToastService, ToastType } from '../../../shared/services/toast.service';
import { newResetPasswordRequest, ResetPasswordRequest } from '../../../api/datacleanuptool-api.model';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  private resetPasswordToken: string;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private resetPasswordService: ResetPasswordService,
    private toastService: ToastService,
    private router: Router) { }

  ngOnInit() {
    this.resetPasswordToken = this.route.snapshot.queryParams['resetToken'];
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      'password': ['', [Validators.required, Validators.minLength(8)]],
      'confirmPassword': ['', Validators.required],
    }, { validator: passwordsMatchValidator });
  }

  submitForm() {
    this.submitting = true;
    const resetPasswordRequest : ResetPasswordRequest = newResetPasswordRequest({
      resetToken: this.resetPasswordToken,
      password: this.form.controls.password.value
    });

    this.resetPasswordService.resetPasswordFromToken(resetPasswordRequest)
      .subscribe(() => {
          this.submitting = false;
          this.toastService.add(createToastObject(ToastType.TOAST_IS_SUCCESS, `Password Reset`));
          this.router.navigate(['/login']);
      },
      (error: HttpErrorResponse) => {
        this.submitting = false;
        console.log(error);
        this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR, 'Password Reset failed.'));
      });
  }
}

export const passwordsMatchValidator: ValidatorFn =
  (control: FormGroup): ValidationErrors | null =>
    (control.get('password').value !== control.get('confirmPassword').value) ?
      { 'passwordsDoNotMatch': true } : null;

