import {Injectable} from '@angular/core';

@Injectable()
export class MessageService {
  private _messages: string[] = [];

  add(message: string) {
    this._messages.push(message);
  }

  clear() {
    this._messages = [];
  }

  get messages(): string[] {
    return this._messages;
  }
}
