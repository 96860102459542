import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastMessage, ToastService, ToastType } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html'
})
export class ToastComponent implements OnInit, OnDestroy {

  constructor (
    private toastService: ToastService
  ) { }

  toasts: ToastMessage[];

  displayToast: boolean = false;

  private _toastSubscription: Subscription;

  ngOnInit() {
    this._toastSubscription = this.toastService.toastSource$
      .subscribe( toasts => {
        this.toasts = toasts;
        this.displayToast = true;
        this.toasts.forEach(toast => {
          if (toast.toastType === ToastType.TOAST_IS_SUCCESS) {
            setTimeout(() => this.close(toast), 10000);
          }
        });
      });
  }

  close(toast: ToastMessage) {
    this.toastService.remove(toast);
  }

  ngOnDestroy() {
    if (this._toastSubscription) {this._toastSubscription.unsubscribe(); }
  }

}
