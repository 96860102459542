import {Injectable} from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as ExcelProper from 'exceljs';
import {Workbook, Worksheet} from 'exceljs';
import {saveAs} from 'file-saver';
import {
  AwardType,
  CollegeReportCourseSummary,
  CollegeReportEntitySummary,
  CollegeReportProgramSummary, CourseSchema,
  CurriculumEntityType,
  ProgramClassification, ProgramSchema,
  ReportField,
} from './api/datacleanuptool-api.model';
import {ApplicationStateService} from './shared/services/application-state.service';
import {sortBy} from './shared/util/array-utils';
import {
  HumanReadableNotice,
  HumanReadableNoticeSeverity,
  MasterRecordNoticeService,
} from './shared/services/master-record-notice.service';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {awardTypeLabels} from "./api/extensions";

@Injectable({
  providedIn: 'root'
})

export class ExcelErrorReportService {

  private static green = 'B8FFB7';
  private static red = 'EA9999';
  private static orange = 'FFD966';
  private static grey = 'EFEFEF';

  constructor(
    private applicationStateService: ApplicationStateService,
    private recordNoticeService: MasterRecordNoticeService
  ) {
  }

  generateExcel$(): Observable<boolean> {

    return this.applicationStateService.curriculumYear.collegeCurriculumReport$().pipe(
      map(
        report => {
          //console.log(report);

          const workbook: ExcelProper.Workbook = new Excel.Workbook();

          this.createProgramReport(workbook, report.programReports);
          this.createCourseReport(workbook, report.courseReports);

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            saveAs(blob, this.applicationStateService.college.name + '_' + this.applicationStateService.curriculumYear.curriculumYear + '_curriculum_report.xlsx');
          });
          return true;
        }
      ));
  }

  private createCourseReport(workbook: Workbook, courseReports: CollegeReportCourseSummary[]) {
    const courseWorksheet = workbook.addWorksheet('Courses', {
      views: [
        {state: 'frozen', ySplit: 2}
      ]
    });
    const courseReportTitle = this.applicationStateService.college.name + ' Course Cleaning Report';
    const titleRow = courseWorksheet.addRow([courseReportTitle]);
    // Set font, size and style in title row.
    titleRow.font = {name: 'Calibri', family: 4, size: 12, bold: true};
    courseWorksheet.mergeCells('A1:I1');

    const subTitleRow = courseWorksheet.addRow(['Course', 'Title', 'Cleaning']);
    subTitleRow.font = {name: 'Calibri', family: 4, size: 12, bold: true};

    courseWorksheet.mergeCells(courseWorksheet.rowCount, 3, courseWorksheet.rowCount, 9); // top,left,bottom,right
    subTitleRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: ExcelErrorReportService.grey},
      };
      cell.border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
    });

    sortBy(courseReports, courseReport => courseReport.subjectAndNumber);

    courseReports.forEach(courseReport => {
        this.writeCourseDetailToWorkSheet(courseReport, courseWorksheet);
      }
    );

    this.setColumnWidths(courseWorksheet);
  }

  private createProgramReport(workbook: Workbook, programReports: CollegeReportProgramSummary[]) {
    const programWorksheet = workbook.addWorksheet('Programs', {
      views: [
        {state: 'frozen', ySplit: 2}
      ]
    });

    const programReportTitle = this.applicationStateService.college.name + ' Program Cleaning Report';
    const titleRow = programWorksheet.addRow([programReportTitle]);
    // Set font, size and style in title row.
    titleRow.font = {name: 'Calibri', family: 4, size: 12, bold: true};
    programWorksheet.mergeCells('A1:I1');

    const subTitleRow = programWorksheet.addRow(['Program Title', 'Award', 'Cleaning']);
    subTitleRow.font = {name: 'Calibri', family: 4, size: 12, bold: true};

    programWorksheet.mergeCells(programWorksheet.rowCount, 3, programWorksheet.rowCount, 9); // top,left,bottom,right
    subTitleRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: ExcelErrorReportService.grey},
      };
      cell.border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
    });

    sortBy(programReports, programReport => programReport.programTitle);

    programReports.forEach(programReport => {
        this.writeProgramDetailToWorkSheet(programReport, programWorksheet);
      }
    );

    this.setColumnWidths(programWorksheet);
  }

  private setColumnWidths(entityReportSheet) {
    entityReportSheet.getColumn('A').width = 25;

    entityReportSheet.getColumn('A').alignment = {wrapText: true};
    entityReportSheet.getColumn('B').width = 15;
    entityReportSheet.getColumn('B').alignment = {wrapText: true};

    entityReportSheet.getColumn('C').width = 12;

    entityReportSheet.getColumn('D').width = 20;
    entityReportSheet.getColumn('D').alignment = {wrapText: true};

    entityReportSheet.getColumn('E').width = 40;

    entityReportSheet.getColumn('F').width = 30;
    entityReportSheet.getColumn('F').alignment = {wrapText: true};

    entityReportSheet.getColumn('G').width = 24;
    entityReportSheet.getColumn('G').alignment = {wrapText: true};

    entityReportSheet.getColumn('H').width = 30;
    entityReportSheet.getColumn('H').alignment = {wrapText: true};

    entityReportSheet.getColumn('I').width = 30;
    entityReportSheet.getColumn('I').alignment = {wrapText: true};
  }

  private writeCourseDetailToWorkSheet(courseReport: CollegeReportCourseSummary, worksheet: Worksheet) {
    let cleaningSummaryCellColor;
    let cleaningSummary;

    const rowData = [];

    if (courseReport.notices.hasErrors) {
      cleaningSummary = 'Not usable by Program Pathways Mapper due to Critical Errors';
      cleaningSummaryCellColor = ExcelErrorReportService.red;
    } else if (courseReport.notices.hasWarnings) {
      cleaningSummary = 'Usable by Program Pathways Mapper, but with warnings.';
      cleaningSummaryCellColor = ExcelErrorReportService.orange;
      rowData[0] = [];
    } else {
      cleaningSummary = 'Usable by Program Pathways Mapper';
      cleaningSummaryCellColor = ExcelErrorReportService.green;
    }

    //const awardTypeLabel = awardTypeLabels[courseReport.programAwardType].label;
    const courseSummaryRow = worksheet.addRow([courseReport.subjectAndNumber, courseReport.courseTitle, cleaningSummary]);
    courseSummaryRow.height = 30;
    courseSummaryRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: cleaningSummaryCellColor},
        };
        cell.border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
      }
    );
    worksheet.mergeCells(worksheet.rowCount, 3, worksheet.rowCount, 9); // top,left,bottom,right

    if (courseReport.notices.hasErrors || courseReport.notices.hasWarnings) {
      this.writeCurriculumEntityRollupNotices(worksheet, courseReport, CurriculumEntityType.COURSE);
    }
    this.writeCurriculumEntityFields(worksheet, courseReport, CurriculumEntityType.COURSE);
  }

  private writeProgramDetailToWorkSheet(programReport: CollegeReportProgramSummary, worksheet: Worksheet) {
    //console.log(programDetail);

    let cleaningSummaryCellColor;
    let cleaningSummary;

    const rowData = [];

    if (programReport.notices.hasErrors) {
      cleaningSummary = 'Not usable by Program Pathways Mapper due to Critical Errors';
      cleaningSummaryCellColor = ExcelErrorReportService.red;
    } else if (programReport.notices.hasWarnings) {
      cleaningSummary = 'Usable by Program Pathways Mapper, but with warnings.';
      cleaningSummaryCellColor = ExcelErrorReportService.orange;
      rowData[0] = [];
    } else {
      cleaningSummary = 'Usable by Program Pathways Mapper';
      cleaningSummaryCellColor = ExcelErrorReportService.green;
    }

    const awardTypeLabel = programReport.programAwardType ? awardTypeLabels[programReport.programAwardType].label : 'Missing Award Type';
    const programSummaryRow = worksheet.addRow([programReport.programTitle, awardTypeLabel, cleaningSummary]);
    programSummaryRow.height = 30;
    programSummaryRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: cleaningSummaryCellColor},
        };
        cell.border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
      }
    );
    worksheet.mergeCells(worksheet.rowCount, 3, worksheet.rowCount, 9); // top,left,bottom,right

    if (programReport.notices.hasErrors || programReport.notices.hasWarnings) {
      this.writeCurriculumEntityRollupNotices(worksheet, programReport, CurriculumEntityType.PROGRAM);
    }

    this.writeCurriculumEntityFields(worksheet, programReport, CurriculumEntityType.PROGRAM);

  }


  private writeCurriculumEntityFields(worksheet: Worksheet, entityReport: CollegeReportEntitySummary, curriculumEntityType: CurriculumEntityType) {
    const fieldsBreakRow = worksheet.addRow(['', '', 'Cleaned Data']);
    fieldsBreakRow.font = {name: 'Calibri', family: 4, bold: true};

    worksheet.mergeCells(worksheet.rowCount, 3, worksheet.rowCount, 9); // top,left,bottom,right

    fieldsBreakRow.getCell('C').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsBreakRow.getCell('C').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsBreakRow.getCell('D').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsBreakRow.getCell('D').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsBreakRow.getCell('E').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsBreakRow.getCell('E').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsBreakRow.getCell('F').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsBreakRow.getCell('F').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    const fieldsHeaderRow = worksheet.addRow(['', '', '', 'Field', 'Value', 'Sources', 'Problems', 'Action Taken in DCT', 'Next Steps']);

    fieldsHeaderRow.font = {name: 'Calibri', family: 4, bold: true};

    fieldsHeaderRow.getCell('D').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsHeaderRow.getCell('D').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsHeaderRow.getCell('E').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsHeaderRow.getCell('E').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsHeaderRow.getCell('E').alignment = {wrapText: false};

    fieldsHeaderRow.getCell('F').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsHeaderRow.getCell('F').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsHeaderRow.getCell('G').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsHeaderRow.getCell('G').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsHeaderRow.getCell('H').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsHeaderRow.getCell('H').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };
    fieldsHeaderRow.getCell('I').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    fieldsHeaderRow.getCell('I').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    // TODO: would be nice if server or client entity returned this as a map not an indexed array?
    // TODO: index is a number (compiler) issue when trying to directly use fieldName as string? but it works as a strictly typed string map key ?
    const fields: Map<string, ReportField<any>> = new Map<string, ReportField<any>>();
    for (const fieldName in entityReport.fields) {
      if (entityReport.fields.hasOwnProperty(fieldName)) {
        fields.set(fieldName, entityReport.fields[fieldName]);
      }
    }

    if(this.applicationStateService.curriculumYear.programClassification === ProgramClassification.CIP){
      fields.delete(ProgramSchema.topCode)
    }
    if(this.applicationStateService.curriculumYear.programClassification === ProgramClassification.TOP){
      fields.delete(ProgramSchema.cipCode)
    }
    fields.delete(CourseSchema.topCode)

    // TODO: optimize by sorting only once (we have the set of fields in the master record notice service)
    const sortedFieldNames = sortBy([...fields.keys()], fieldName => this.recordNoticeService.getFieldSortOrder(fieldName));

    sortedFieldNames.forEach(fieldName => {
      const humanReadableFieldNotices: HumanReadableNotice[] = [];
      // are there any notices for this field
      if (entityReport.notices.fieldNotices[fieldName] !== undefined) {
        // for each field notice
        entityReport.notices.fieldNotices[fieldName].forEach(
          notice => {
            humanReadableFieldNotices.push(this.recordNoticeService.getHumanReadableNotice(curriculumEntityType, notice, fieldName));
          }
        );
      }
      const field: ReportField<any> = entityReport.fields[fieldName];
      const fieldRow = worksheet.addRow(['', '', '', this.recordNoticeService.getFieldNameDisplay(fieldName), (field.value || field.value === 0) ? field.value : '', field.sources.map(source => this.recordNoticeService.sourceSystemLabel(curriculumEntityType, source)).join(', '), humanReadableFieldNotices.map(problem => problem.message).join('; '), humanReadableFieldNotices.map(problem => problem.ourAction).join('; '), humanReadableFieldNotices.map(problem => problem.collegeAction).join('; ')]);
//      console.log(['', '', '', this.recordNoticeService.getFieldNameDisplay(fieldName), field.value ? field.value : '', '', humanReadableFieldNotices.map(problem=> problem.message).join(";"), humanReadableFieldNotices.map(problem => problem.ourAction).join(";"), humanReadableFieldNotices.map(problem => problem.collegeAction).join(";")]);

      let fillColor;
      const fieldSeverity = this.maxSeverity(humanReadableFieldNotices);

      if (fieldSeverity === HumanReadableNoticeSeverity.CRITICAL) {
        fillColor = ExcelErrorReportService.red;
      } else if (fieldSeverity === HumanReadableNoticeSeverity.WARNING) {
        fillColor = ExcelErrorReportService.orange;
      } else {
        fillColor = undefined;
      }
      if (fillColor) {

        fieldRow.getCell('D').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: fillColor},
        };
        fieldRow.getCell('E').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: fillColor},
        };

        fieldRow.getCell('F').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: fillColor},
        };
        fieldRow.getCell('G').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: fillColor},
        };

        fieldRow.getCell('H').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: fillColor},
        };
        fieldRow.getCell('I').fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {argb: fillColor},
        };
      }

      fieldRow.getCell('F').border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };

      fieldRow.getCell('D').border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };

      fieldRow.getCell('E').border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
      fieldRow.getCell('G').border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
      fieldRow.getCell('H').border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
      fieldRow.getCell('I').border = {
        top: {style: 'medium'},
        left: {style: 'medium'},
        bottom: {style: 'medium'},
        right: {style: 'medium'}
      };
      // worksheet.mergeCells(worksheet.rowCount,7,worksheet.rowCount,9); // top,left,bottom,right

    });
  }

  private writeCurriculumEntityRollupNotices(worksheet: Worksheet, entityReport: CollegeReportEntitySummary, curriculumEntityType: CurriculumEntityType) {
    const errorsBreakRow = worksheet.addRow(['', '', 'Errors and Warnings']);
    errorsBreakRow.font = {name: 'Calibri', family: 4, bold: true};

    worksheet.mergeCells(worksheet.rowCount, 3, worksheet.rowCount, 9); // top,left,bottom,right

    errorsBreakRow.getCell('C').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsBreakRow.getCell('C').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    errorsBreakRow.getCell('D').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsBreakRow.getCell('D').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    errorsBreakRow.getCell('E').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsBreakRow.getCell('E').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    errorsBreakRow.getCell('F').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsBreakRow.getCell('F').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    const errorsHeaderRow = worksheet.addRow(['', '', '', 'Severity', 'Problem', 'Action Taken in DCT', 'Next Steps']);

    errorsHeaderRow.font = {name: 'Calibri', family: 4, bold: true};

    errorsHeaderRow.getCell('D').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsHeaderRow.getCell('D').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    errorsHeaderRow.getCell('E').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsHeaderRow.getCell('E').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    errorsHeaderRow.getCell('F').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsHeaderRow.getCell('F').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    errorsHeaderRow.getCell('G').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: ExcelErrorReportService.grey},
    };
    errorsHeaderRow.getCell('G').border = {
      top: {style: 'medium'},
      left: {style: 'medium'},
      bottom: {style: 'medium'},
      right: {style: 'medium'}
    };

    worksheet.mergeCells(worksheet.rowCount, 7, worksheet.rowCount, 9); // top,left,bottom,right

    entityReport.notices.nonFieldNotices.forEach(notice => {

        /*
        Optionally provide the missing data in the Programs Template (see details below)
         */
        const humanReadableNotice: HumanReadableNotice = this.recordNoticeService.getHumanReadableNotice(curriculumEntityType, notice, undefined);

        const errorRow = worksheet.addRow(['', '', '', humanReadableNotice.severity, humanReadableNotice.message, humanReadableNotice.ourAction, humanReadableNotice.collegeAction]);

        let fillColor;
        if (humanReadableNotice.severity === HumanReadableNoticeSeverity.CRITICAL) {
          fillColor = ExcelErrorReportService.red;
        } else if (humanReadableNotice.severity === HumanReadableNoticeSeverity.WARNING) {
          fillColor = ExcelErrorReportService.orange;
        } else {
          fillColor = undefined;
        }
        errorRow.getCell('D').border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
        errorRow.getCell('E').border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
        errorRow.getCell('F').border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
        errorRow.getCell('G').border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
        errorRow.getCell('H').border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
        errorRow.getCell('I').border = {
          top: {style: 'medium'},
          left: {style: 'medium'},
          bottom: {style: 'medium'},
          right: {style: 'medium'}
        };
        if (fillColor) {
          errorRow.getCell('D').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: fillColor},
          };

          errorRow.getCell('E').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: fillColor},
          };

          errorRow.getCell('E').alignment = {wrapText: true};
          errorRow.getCell('F').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: fillColor},
          };

          errorRow.getCell('F').alignment = {wrapText: true};
          errorRow.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: fillColor},
          };
          errorRow.getCell('G').alignment = {wrapText: true};

          errorRow.getCell('H').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: fillColor},
          };

          errorRow.getCell('I').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: fillColor},
          };

        }
        worksheet.mergeCells(worksheet.rowCount, 7, worksheet.rowCount, 9); // top,left,bottom,right
      }
    );
  }

  private maxSeverity(problems: HumanReadableNotice[]): HumanReadableNoticeSeverity {
    if (problems.filter(problem => problem.severity === HumanReadableNoticeSeverity.CRITICAL).length > 0) {
      return HumanReadableNoticeSeverity.CRITICAL;
    } else if (problems.filter(problem => problem.severity === HumanReadableNoticeSeverity.WARNING).length > 0) {
      return HumanReadableNoticeSeverity.WARNING;
    } else {
      return HumanReadableNoticeSeverity.INFO;
    }
  }

}




export enum ExtendedAwardType {
  MISSING_AWARD_TYPE = 'MISSING_AWARD_TYPE'
}

export const awardTypeVerboseLabels: {[a in ExtendedAwardType | AwardType ]: { label: string}} = {
  CERTIFICATE: {label: 'Certificate'},
  PROFICIENCY_AWARD : {label: 'Proficiency Award'},
  CERTIFICATE_OF_ACCOMPLISHMENT : {label: 'Certificate of Accomplishment'},
  CERTIFICATE_OF_SPECIALIZATION : {label: 'Certificate of Specialization'},
  CERTIFICATE_OF_COMPLETION : {label: 'Certificate of Completion (Noncredit)'},
  CERTIFICATE_OF_COMPETENCY : {label: 'Certificate of Competency'},
  CERTIFICATE_OF_PERFORMANCE : {label: 'Certificate of Performance'},
  JOB_SKILLS_CERTIFICATE : {label: 'Job Skills Certificate'},
  CERTIFICATE_OF_ACHIEVEMENT : {label: 'Certificate of Achievement'},
  HIGH_SCHOOL_DIPLOMA : {label: 'High School Diploma'},
  ASSOCIATE_IN_ARTS : {label: 'Associate in Arts'},
  ASSOCIATE_IN_SCIENCE : {label: 'Associate in Science'},
  ASSOCIATE_OF_ARTS : {label: 'Associate of Arts'},
  ASSOCIATE_OF_SCIENCE : {label: 'Associate of Science'},
  ASSOCIATE_IN_ARTS_FOR_TRANSFER  : {label: 'Associates in Arts for Transfer'},
  ASSOCIATE_OF_ARTS_FOR_TRANSFER  : {label: 'Associates of Arts for Transfer'},
  ASSOCIATE_IN_SCIENCE_FOR_TRANSFER : {label: 'Associates in Science for Transfer'},
  ASSOCIATE_OF_SCIENCE_FOR_TRANSFER : {label: 'Associates of Science for Transfer'},
  ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Biology DTA'},
  ASSOCIATE_IN_MATH_EDUCATION_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Math Education DTA'},
  ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Business DTA'},
  ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Music DTA'},
  ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Pre-Nursing DTA'},
  ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Nursing DTA'},
  ASSOCIATE_IN_BIOENGINEERING_AND_CHEMICAL_PRE_ENGINEERING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Bioengineering and Chemical Pre-Engineering DTA'},
  ASSOCIATE_IN_COMPUTER_AND_ELECTRICAL_ENGINEERING_DIRECT_TRANSFER_AGREEMENT : {label: 'Associate in Computer and Electrical Engineering DTA'},
  UNIVERSITY_OF_CALIFORNIA_TRANSFER_PATHWAY: {label: 'University of California Transfer Pathway'},
  NON_CREDIT : {label: 'Non Credit'},
  EMPLOYABLE_SKILLS_CERTIFICATE : {label: 'Employable Skills Certificate'},
  CERTIFICATE_OF_COMPLETION_CREDIT: {label : 'Certificate of Completion (Credit)'},
  OTHER_CREDIT_AWARD : {label: 'Other Credit Award'},
  MISSING_AWARD_TYPE : {label: 'Missing Award Type'},

  ASSOCIATE_IN_ARTS_DTA : {label: 'Associate in Arts DTA'},
  ASSOCIATE_IN_BIOLOGY_DTA_MRP : {label: 'Associate in Biology DTA'},
  ASSOCIATE_IN_BUSINESS_DTA_MRP : {label: 'Associate in Business DTA/MRP'},
  ASSOCIATE_IN_COMPUTER_SCIENCE_DTA_MRP : {label: 'Associate in Computer Science DTA/MRP'},
  ASSOCIATE_IN_PRE_NURSING_DTA_MRP : {label: 'Associate in Pre-Nursing DTA/MRP'},
  ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_1 : {label: 'Associate of Science - Transfer Track 1'},
  ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2 : {label: 'Associate of Science - Transfer Track 2'},
  ASSOCIATE_OF_SCIENCE_TRANSFER_TRACK_2_MRP : {label: 'Associate of Science - Transfer Track 2/MRP'},

  ASSOCIATE_OF_ARTS_DIRECT_TRANSFER_AGREEMENT: {label:'Associate of Arts Direct Transfer Agreement'},
  ASSOCIATE_IN_BIOLOGY_DIRECT_TRANSFER_AGREEMENT_MRP: {label:'Associate in Biology Direct Transfer Agreement/MRP'},
  ASSOCIATE_IN_BUSINESS_DIRECT_TRANSFER_AGREEMENT_MRP: {label:'Associate in Business Direct Transfer Agreement/MRP'},
  ASSOCIATE_IN_MUSIC_DIRECT_TRANSFER_AGREEMENT_MRP: {label:'Associate in Music Direct Transfer Agreement/MRP'},
  ASSOCIATE_OF_APPLIED_SCIENCE_FOR_TRANSFER: {label:'Associate of Applied Science for Transfer'},
  ASSOCIATE_IN_COMPUTER_SCIENCE_DIRECT_TRANSFER_AGREEMENT: {label: 'Associate in Computer Science Direct Transfer Agreement'},
  ASSOCIATE_IN_PRE_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP: {label:'Associate in Pre-Nursing Direct Transfer Agreement/MRP'},
  ASSOCIATE_IN_NURSING_DIRECT_TRANSFER_AGREEMENT_MRP: {label:'Associate in Nursing Direct Transfer Agreement/MRP'},
  // Added for CSUB
  BACHELOR_OF_ARTS: {label: "Bachelor of Arts"},
  BACHELOR_OF_SCIENCE: {label: "Bachelor of Science"},
  DOCTOR_OF_EDUCATION: {label: "Doctor of Education"},
  MASTER_OF_ARTS: {label: "Master of Arts"},
  MASTER_OF_BUSINESS_ADMINISTRATION: {label: "Master of Business Administration"},
  MASTER_OF_PUBLIC_ADMINISTRATION: {label: "Master of Public Administration"},
  MASTER_OF_SCIENCE: {label: "Master of Science"},
  MASTER_OF_SOCIAL_WORK: {label: "Master of Social Work"},

  CERTIFICATE_OF_PROFICIENCY : {label: 'Certificate of Proficiency'},
  SKILLS_CERTIFICATE: {label: 'Skills Certificate'},
  CERTIFICATE_OF_SKILLS: {label: 'Certificate of Skills'},
  CAREER_CERTIFICATE: {label: 'Career Certificate'},
  STATE_SHORT_EARLY_CHILDHOOD_EDUCATION_CERTIFICATE : {label: 'State Short Early Childhood Education Certificate'},
  ASSOCIATE_IN_APPLIED_ARTS : {label: 'Associate in Applied Arts'},
  ASSOCIATE_IN_FINE_ARTS : {label: 'Associate in Fine Arts'},
  ASSOCIATE_IN_APPLIED_SCIENCE : {label: 'Associate in Applied Science'},
  ASSOCIATE_IN_APPLIED_TECHNOLOGY : {label: 'Associate in Applied Technology'},
  ASSOCIATE_IN_PRENURSING : {label: 'Associate in Pre-Nursing'},
  BACHELOR_OF_APPLIED_SCIENCE : {label: 'Bachelor of Applied Science'},

  BACHELOR_OF_APPLIED_ARTS: {label: 'Bachelor of Applied Arts'},
  BACHELOR_OF_SCIENCE_IN_NURSING: {label: 'Bachelor of Science in Nursing'},
  ASSOCIATE_IN_BUSINESS: {label: 'Associate in Business'},
  ASSOCIATE_IN_MATH_EDUCATION: {label: 'Associate in Math Education'},
  ASSOCIATE_IN_MUSIC: {label: 'Associate in Music'},
  ASSOCIATE_IN_ARTS_AND_SCIENCES: {label: 'Associate in Arts and Sciences'},
  ASSOCIATE_IN_ARTS_AND_SCIENCES_FOR_TRANSFER: {label: 'Associate in Arts and Sciences for Transfer'},
  ASSOCIATE_IN_TECHNICAL_ARTS: {label: 'Associate in Technical Arts'},
  ASSOCIATE_OF_FINE_ARTS: {label: 'Associate of Fine Arts'},
  ASSOCIATE_IN_SCIENCE_DEGREE_FOR_UC_TRANSFER: {label: 'Associate in Science Degree for UC Transfer'},
  ASSOCIATE_IN_ARTS_DEGREE_FOR_UC_TRANSFER: {label: 'Associate in Arts Degree for UC Transfer'},
  ASSOCIATE_IN_ARTS_DIRECT_TRANSFER_AGREEMENT: {label: 'Associate in Arts Direct Transfer Agreement'},
  SKILLS_RECOGNITION: {label: 'Skills Recognition'},
  ASSOCIATE_IN_APPLIED_SCIENCE_TRANSFER: {label: 'Associate in Applied Science Transfer'},
  ASSOCIATE_IN_APPLIED_SCIENCE_FOR_TRANSFER: {label: 'Associate in Applied Science For Transfer'},
  SHORT_TERM_CERTIFICATE: {label: 'Short Term Certificate'},
  CERTIFICATE_OF_ARTS_AND_SCIENCES: {label: 'Certificate of Arts and Sciences'},
  BACHELOR_OF_APPLIED_BEHAVIORAL_SCIENCE: {label: 'Bachelor of Applied Behavioral Science'},
  ASSOCIATE_OF_APPLIED_SCIENCE: {label: 'Associate of Applied Science'},

  BACHELOR_OF_FINE_ARTS: {label: 'Bachelor of Fine Arts'},
  BACHELOR_OF_MUSIC: {label: 'Bachelor of Music'},
  CERTIFICATE_OF_CAREER_PREPARATION: {label: 'Certificate of Career Preparation'}
};
