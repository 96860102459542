import {Injectable} from '@angular/core';
import {HumanReadableNotice, MasterRecordNoticeService} from "./master-record-notice.service";
import {
  isCourseForSearchResult,
  isProgramForSearchResult,
  Notice,
  INotices,
  NoticeTypeFilter,
  CurriculumEntityType,
  newCurriculumEntitySearchCriteria,
  Publishability,
} from '../../api/datacleanuptool-api.model';
import {AngularCsv} from "angular7-csv";
import {Observable} from "rxjs";
import {ApplicationStateService} from "./application-state.service";
import {map} from "rxjs/operators";
import {ClientEntityManager} from "../entity-system/client-entity-manager.service";

@Injectable()
export class ExportSearchResultsService {

  constructor(
    private entityManager: ClientEntityManager,
    private masterRecordNoticeService: MasterRecordNoticeService,
    private applicationStateService: ApplicationStateService
  ) {
  }

  private static cleaningStatusLabel(notices: INotices) {
    if(notices.hasErrors){
      return 'Errors';
    }
    if(notices.hasWarnings){
      return 'Warnings';
    }
    return 'Clean';
  }

  private courseCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    noDownload: false,
    headers: ["label", "title", "control_number", "currently_active", "cleaning_status", "errors", "warnings", "id"]
  };

  private programCsvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    useBom: true,
    noDownload: false,
    headers: ["label", "title", "award", "control_number", "currently_active", "cleaning_status", "errors", "warnings", "id"]
  };

  private getHumanReadableNotice(curriculumEntityType: CurriculumEntityType, notice: Notice, field: string) : HumanReadableNotice {
    return this.masterRecordNoticeService.getHumanReadableNotice(curriculumEntityType, notice, field);
  }

  exportReport(curriculumEntityType: CurriculumEntityType,
               searchTerm: string | undefined,
               showActive: boolean,
               showInactive: boolean,
               anyOfPublishabilities: Set<Publishability>,
               checkedNoticeFilters: Set<string>
  ) {

    this.prepareReport(curriculumEntityType, searchTerm, showActive, showInactive, anyOfPublishabilities, checkedNoticeFilters)
      .subscribe(recordsForCsv => this.writeCsvFileAndDownload(recordsForCsv, curriculumEntityType));
  }

  // call the server to get the full, un-paginated search results/ filtered by notices/ etc and then create a CSV report out of it
  prepareReport(curriculumEntityType: CurriculumEntityType,
                searchTerm: string | undefined,
                showActive: boolean,
                showInactive: boolean,
                anyOfPublishabilities: Set<Publishability>,
                checkedNoticeFilters: Set<string>
  ): Observable<any[]>{


    const anyOfNoticeTypes : NoticeTypeFilter[] = Array.from(checkedNoticeFilters).map(
      noticeType => {
        return { noticeType: noticeType, fields: []};
      }
    );

    const criteria = newCurriculumEntitySearchCriteria({
      matchingSlugs: [],
      noneOfNonFieldNoticeTypes: [],
      noneOfPublishabilities: [],
      treatInactiveAsActiveForPublishabilityFilter: true,
      searchTerm : searchTerm,
      showActive : showActive,
      showInactive : showInactive,
      anyOfPublishabilities: Array.from(anyOfPublishabilities),
      anyOfNoticeTypes: anyOfNoticeTypes
    });

    if(curriculumEntityType === CurriculumEntityType.COURSE){
      return this.applicationStateService.curriculumYear.courses$(criteria, 1, 0).pipe(
        map(searchResults =>
          searchResults.courses
            .map(course => this.createCsvRow(curriculumEntityType, course)
            )
        )
      );
    }
    else if (curriculumEntityType === CurriculumEntityType.PROGRAM){
      return this.applicationStateService.curriculumYear.programs$(criteria, 1, 0).pipe(
        map(searchResults =>
          searchResults.programs
            .map(masterRecordSummary => this.createCsvRow(curriculumEntityType, masterRecordSummary)
            )
        )
      );

    }
    else {
      throw new Error(`unsupported curriculumEntityType: ${curriculumEntityType}`);
    }
  }

  private writeCsvFileAndDownload(recordsForCsv: any[], curriculumEntityType: CurriculumEntityType) {
    let csvOptions;
    if(curriculumEntityType === CurriculumEntityType.PROGRAM){
      csvOptions = this.programCsvOptions;
    }
    else {
      csvOptions = this.courseCsvOptions;
    }

    let filename = this.applicationStateService.college.name + "-" + this.applicationStateService.curriculumYear.curriculumYear + "-" + this.applicationStateService.curriculumYear.currentRevision;

    const date: Date = new Date();
    const dateForFileName = date.toISOString().replace(/-/g,"-");

    let fileContents: string;
    if(curriculumEntityType === CurriculumEntityType.PROGRAM){
      fileContents  = 'programs';
    }
    else if (curriculumEntityType === CurriculumEntityType.COURSE){
      fileContents = 'courses';
    }
    else {
      throw new Error(`unsupported curriculum entity type: ${curriculumEntityType}`);
    }

    filename = filename + "-" + fileContents;
    filename = filename + "-" + dateForFileName;

    return new AngularCsv(recordsForCsv, filename, csvOptions);
  }

  private createCsvRow(curriculumEntityType:CurriculumEntityType, searchResult) {

    const errorsAsString: string = searchResult.notices.nonFieldNotices
      .filter(notice => this.masterRecordNoticeService.isErrorNotice(notice))
      .map(error => this.getHumanReadableNotice(curriculumEntityType, error, null).message).join(',');

    const warningsAsString: string = searchResult.notices.nonFieldNotices
      .filter(notice => this.masterRecordNoticeService.isWarningNotice(notice))
      .map(error => this.getHumanReadableNotice(curriculumEntityType, error, null).message).join(',');

    if(isCourseForSearchResult(searchResult)){
      return {
        "label": searchResult.subjectAndNumber,
        "title": searchResult.title,
        "control_number": searchResult.controlNumber,
        "currently_active": searchResult.active ? 'Y' : 'N',
        "cleaning_status": ExportSearchResultsService.cleaningStatusLabel(searchResult.notices),
        "errors": errorsAsString,
        "warnings": warningsAsString,
        //"infos": infosAsString,
        "id": searchResult.id.id
      };
    }
    else if(isProgramForSearchResult(searchResult)){
      return {
        "label": searchResult.title + " " + searchResult.awardType,
        "title": searchResult.title,
        "award": searchResult.awardType,
        "control_number": searchResult.controlNumber,
        "currently_active": searchResult.active ? 'Y' : 'N',
        "cleaning_status": ExportSearchResultsService.cleaningStatusLabel(searchResult.notices),
        "errors": errorsAsString,
        "warnings": warningsAsString,
        "id": searchResult.id.id
      };
    }
  }

}
