import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationStateService } from '../services/application-state.service';
import { ClientEntityManager } from '../entity-system/client-entity-manager.service';
import { collegeIdParam } from '../../app-routing.module';
import { map } from 'rxjs/operators';

@Injectable()
export class CollegeDataGuard implements CanActivate {

  constructor(
    private entityManager: ClientEntityManager,
    private appState: ApplicationStateService,
    private router: Router
  ) {}

  canActivate (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    const params = next.params;
    const collegeSlug = params[collegeIdParam];

    if (collegeSlug) {
      return this.appState.selectCollege$(collegeSlug).pipe(
        map(collegeSelected => {
          if (collegeSelected) {
            return true;
          }
          else {
            return this.router.parseUrl('colleges');
          }
        })
      );
    }
    else {
      return false;
    }
  }

}
