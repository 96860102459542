import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { createToastObject, ToastService, ToastType } from './services/toast.service';
import { AuthenticationService } from './services/authentication.service';
import { traceIdFromError } from './util/error-util';


/*
1. in case of any response from server with an Authorization header with a Bearer token, update our locally stored token to that (refreshed) value.
2. in case of any 401 (authentication failed) error response on a REST API call, log the user out and send them to the login page
3. in case of any 500 error, toast the trace ID
4. in case of network connection failure, toast to that effect
*/

@Injectable()
export class ApiResponseInterceptor implements HttpInterceptor {

  constructor(
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap( (response :HttpEvent<any>) => {
        if (response instanceof HttpResponse ) {
          const httpResponse:HttpResponse<any> = response as HttpResponse<any>;
          if(httpResponse.headers.has("Authorization")
            && httpResponse.headers.get("Authorization").startsWith("Bearer ")){
            const authHeader = httpResponse.headers.get("Authorization");
            const newToken = authHeader.split("Bearer ")[1];
            this.authenticationService.updateToken(newToken);
          }
        }
      }),
      catchError(err => {
        let errMsg: string;
        if (traceIdFromError(err)) {
          errMsg = 'Trace ID: ' + traceIdFromError(err);
        }
        else {
          errMsg = [
            err.name,
            err.message
          ]
            .join(': ');
        }
        if (err.status === 401) {
          // authentication failed
          this.authenticationService.redirectToLogin();
          return observableThrowError(err);
        }
        else if (err.status === 500) {
          this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR,'This application could not retrieve a response from the server.  Please contact Concentric Sky Support and reference ' + errMsg));
          return observableThrowError(err);
        }
        else if (err.status === 0){
          this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR,'Unexpected network error.  Please check your internet connection and try again'));
          return observableThrowError(err);
        }
        else {
          this.toastService.add(createToastObject(ToastType.TOAST_IS_ERROR,'Unexpected error.  Please contact Concentric Sky Support and reference status code ' + err.status + " and " + errMsg));
          return observableThrowError(err);
        }
      })
    );
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     tap( (response: HttpEvent<any>) => {
  //       if (response instanceof HttpResponse ) { /** grab auth information here **/ }
  //     }),
  //     catchError(err => {
  //       let errMsg: string;
  //
  //       if (traceIdFromError(err)) {
  //         errMsg = 'Trace ID: ' + traceIdFromError(err);
  //       }
  //       else {
  //         errMsg = [
  //           err.name,
  //           err.message
  //         ]
  //           .filter( e => e)
  //           .join(': ');
  //       }
  //
  //       this.toastService.add (
  //         createToastObject( ToastType.TOAST_IS_ERROR, errMsg)
  //       )
  //
  //       return observableThrowError(err);
  //     })
  //   );
  //}
}
