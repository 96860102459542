import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

export const headerTitleSeparator = '<span class = "mx-2 text-light">&#187;</span>'

@Injectable()
export class HeaderTitleService {

  private headerTitleSource = new ReplaySubject<string>();

  headerTitle$ = this.headerTitleSource.asObservable();

  constructor() { }

  clearTitle() {
    this.anouceheaderTitle('');
  }

  setTitle(title: string[]) {
    this.anouceheaderTitle(
      ['', ...title]
      .join(headerTitleSeparator)
    );
  }

  private anouceheaderTitle(title: string) {
    requestAnimationFrame( () => this.headerTitleSource.next(title));
  }

}
