import {Component, OnInit} from '@angular/core';
import {appVersion} from '../app.version';
import { ApiUrlService } from '../shared/services/api-url.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ServerVersion{
  version: string;
}

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  uiVersion: string;
  serverVersion$: Observable<ServerVersion>;

  constructor(private apiUrlService: ApiUrlService){

  }

  ngOnInit() {
    this.uiVersion = appVersion;
    this.serverVersion$ = this.apiUrlService.getRestData("info")
      .pipe( map(it => it as ServerVersion));
  }

}
