import {
  AddCourseListApiResponse,
  ApiEither,
  ApiObject,
  BulkAmendErrors,
  BulkAmendResult,
  BulkAmendType,
  CourseApiObject,
  CourseListAddRequest,
  CourseListApiObject,
  CourseListId,
  CourseSchema, CreateHighSchoolApiObject, CreateHighSchoolResponse,
  CurriculumEntityDetailMissing,
  CurriculumEntitySearchCriteria,
  CurriculumEntityType,
  CurriculumYearApiObject,
  CurriculumYearHistoryResponse, EditHighSchoolApiObject, EditHighSchoolResponse,
  FindCipCodesResponse,
  FindCourseListsAction, FindImportedBadgesAction, FindImportedBadgesResponse,
  FindPickableCoursesResponse,
  FindTopCodesResponse,
  GenEdPatternApiObject,
  ImportCorrections,
  isCociNotReachableError,
  isCOCISource,
  isCourseApiObject,
  isCSUSupplementalSource,
  isELumenSource,
  isFailure,
  isGenericCollegeSource,
  isNoSourceConfiguredError,
  isNotParsableError,
  isParseValidationError,
  isPeopleSoftSource,
  isProgramApiObject,
  isPublishConflict,
  isRemoteServerPublishError,
  isSourceNotFetchableError,
  isSuccess,
  isSuccessfulPublish,
  isUCGenericCollegeSource,
  newAggregateAlignmentCounts,
  newAggregateCleanCounts,
  newAggregateNoticeCounts,
  newAggregateSourceCounts,
  newArchiveCurriculumYearAction,
  newBulkAmendCorrectionsAction,
  newCourseListAddRequest,
  newCourseListByIdAction,
  newCourseListId, newCreateHighSchoolAction,
  newCurriculumEntityDetailAction,
  newDeactivateSourceSystemAction,
  newDeleteCourseListAction,
  newEditCurriculumNotesAction, newEditHighSchoolAction,
  newFetchAndImportSourceFileAction,
  newFindCipCodesAction,
  newFindCourseListsAction,
  newFindCoursesAction,
  newFindGenEdPatternsAction, newFindImportedBadgesAction,
  newFindPickableCoursesAction,
  newFindProgramsAction,
  newFindTopCodesAction,
  newGenerateCollegeReportAction,
  newGetCurriculumYearHistoryAction,
  newGetGenEdPatternAction,
  newImportBadgeFileAction,
  newImportSourceFileAction, newMarkPublishedAction,
  newPublishCurriculumAction,
  newPublishPreviewAction,
  newRecordMutationAction, newSearchHighSchoolsByCollegeAction,
  newSourceRecordDetailAction,
  newUpdateCourseListAction,
  newUpdateGenEdAction,
  Nothing,
  PaginatedFilteredCourseSearchResults,
  PaginatedFilteredProgramSearchResults,
  ParseErrorApiObject,
  PipelineEntityType,
  ProgramApiObject,
  ProgramClassification,
  ProgramSchema,
  Publishability,
  PublishCurriculumApiResponse,
  PublishPreview,
  RecordMutationApiAction,
  RichCourseRecord,
  RichProgramRecord,
  RowWithNumber,
  SetValue,
  SourceCountSummary,
  SourceRecordDetailApiObject,
  SourcesProvider,
  SourceSystem,
  UpdateCourseListAction
} from '../../../api/datacleanuptool-api.model';
import {ClientEntityManager} from '../client-entity-manager.service';
import {Observable, of} from 'rxjs';
import {CollegeCurriculumReportResult} from './curriculum-info.client-entity';
import {flatMap, map, tap} from 'rxjs/operators';
import {ApplicationStateService} from '../../services/application-state.service';
import {createToastObject, ToastService, ToastType} from '../../services/toast.service';
import {College} from "./college";

export type SourceCountSummaryBySourceSystem =  {[P in SourceSystem]?: SourceCountSummary };

export class CurriculumYear {
  private directImportType: Promise<String>;

  constructor(
    private _apiObject: CurriculumYearApiObject,
    readonly college: College,
    private _entityManager: ClientEntityManager,
    private _applicationState: ApplicationStateService,
    private _toastService: ToastService,
  ) {

    this._dashboardRoute  = [
      '/colleges',
      this.collegeSlug,
      'curriculum-years',
      this.curriculumYear + ''
    ];

    this._programsDashboardRoute =  [...this._dashboardRoute, 'programs'];
    this._coursesDashboardRoute =  [...this._dashboardRoute, 'courses'];
    this._generalEducationPatternsRoute =  [...this._dashboardRoute, 'general-education-patterns'];
    this._courseListsRoute =  [...this._dashboardRoute, 'course-lists'];
    this._highSchoolsRoute = [...this._dashboardRoute, 'high-schools']
    this._publishRoute =  [...this._dashboardRoute, 'publish'];
    this._badgesRoute =  [...this._dashboardRoute, 'badges'];

    //use a promise here, to handle sub in constructor

  }

  collegeDataCleaningReport$: Observable<CollegeCurriculumReportResult>;
  private _courseListsRoute: string[];
  private _highSchoolsRoute: string[];
  private _generalEducationPatternsRoute: string[];
  private _coursesDashboardRoute: string[];
  private _programsDashboardRoute: string[];
  private _publishRoute: string[];
  private _dashboardRoute: string[];
  private _badgesRoute: string[];

  getCurriculumYearId(){
    return this._apiObject.id;
  }

  publishabilityCount(type: CurriculumEntityType, publishability: Publishability) {
    return this._apiObject.publishabilityCounts[type] &&
      this._apiObject.publishabilityCounts[type][publishability] &&
      this._apiObject.publishabilityCounts[type][publishability] ||  0;
  }

  get collegeSource(): SourcesProvider | null {
    return this.college.sources.COLLEGE;
  }

  get stateSource(): SourcesProvider | null {
    return this.college.sources.STATE;
  }

  get collegeSlug() {
    return this._apiObject.collegeSlug;
  }

  get currentRevision() {
    return this._apiObject.currentRevision;
  }

  get currentRevisionNotes() {
    return this._apiObject.currentRevisionNotes;
  }

  get uuid() {
    return this._apiObject.id.uuid;
  }

  get curriculumYear(): number {
    return this._apiObject.curriculumYear;
  }

  get programClassification(): ProgramClassification {
    return this._apiObject.programClassification;
  }

  get systemsImported(): { [P in PipelineEntityType]?: SourceSystem[] } {
    return this._apiObject.systemsImported;
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Routes
  get dashboardRoute(): string[] {
    return this._dashboardRoute;
  }

  entityBulkAmendErrorRoute(type: PipelineEntityType, field: string): string[] {
    switch (type) {
      case PipelineEntityType.PROGRAM:
        return this.bulkAmendProgramsErrorRoute(field);

      case PipelineEntityType.COURSE:
        return this.bulkAmendCoursesErrorRoute(field);
    }
    return [''];
  }

  entityBulkAmendSuccessRoute(type: PipelineEntityType, field: string): string[] {
    switch (type) {
      case PipelineEntityType.PROGRAM:
        //[...this.bulkAmendProgramsRoute(field), 'errors']
        return [...this.programsDashboardRoute, field, 'edit'];

      case PipelineEntityType.COURSE:
        return [...this.coursesDashboardRoute, field, 'edit'];
    }
    return [''];
  }

  // COURSE GROUPS aka course lists
  get courseListsRoute(): string[] {
    return this._courseListsRoute;
  }

  get highSchoolsRoute(): string[] {
    return this._highSchoolsRoute;
  }

  // GEN ED AREAS
  get generalEducationPatternsRoute(): string[] {
    return this._generalEducationPatternsRoute;
  }

  // Badges
  get badgesRoute(): string[] {
    return this._badgesRoute;
  }

  get publishRoute() : string[] {
    return this._publishRoute;
  }

  get programsDashboardRoute(): string[] {
    return this._programsDashboardRoute;
  }

  get coursesDashboardRoute(): string[] {
    return this._coursesDashboardRoute;
  }

  get templateDownloadUrls() {
    return this._apiObject.templateDownloadUrls;
  }

  bulkAmendProgramsRoute( field: string) {
    return [...this.dashboardRoute, 'programs', field, 'amend'];
  }

  bulkAmendProgramsErrorRoute( field: string) : string[] {
    return [...this.bulkAmendProgramsRoute(field), 'errors'];
  }

  bulkAmendCoursesRoute(field: string) {
    return [...this.dashboardRoute, 'courses', field, 'amend'];
  }

  bulkAmendCoursesErrorRoute(field: string) {
    return [...this.bulkAmendCoursesRoute(field), 'errors'];
  }

  hasImportedPrograms(): boolean {
    return !!(this._apiObject.systemsImported[PipelineEntityType.PROGRAM]
      && this._apiObject.systemsImported[PipelineEntityType.PROGRAM].length);
  }

  hasImportedCourses(): boolean {
    return !!(this._apiObject.systemsImported[PipelineEntityType.COURSE]
      && this._apiObject.systemsImported[PipelineEntityType.COURSE].length);
  }

  hasImportedStatePrograms(): boolean {
    return this.systemsImported.PROGRAM && this.systemsImported.PROGRAM.includes(SourceSystem.STATE);
  }

  hasImportedStateCourses(): boolean {
    return this.systemsImported.COURSE && this.systemsImported.COURSE.includes(SourceSystem.STATE);
  }

  hasImportedCollegePrograms(): boolean {
    return this.systemsImported.PROGRAM && this.systemsImported.PROGRAM.includes(SourceSystem.COLLEGE);
  }

  hasImportedCollegeCourses(): boolean {
    return this.systemsImported.COURSE && this.systemsImported.COURSE.includes(SourceSystem.COLLEGE);
  }

  // Has at least 1 publishable program and 1 publishable course.
  hasPublishableProgramAndCourse(): boolean {
    return (this.publishabilityCount(CurriculumEntityType.PROGRAM, Publishability.CLEAN) > 0 ||
      this.publishabilityCount(CurriculumEntityType.PROGRAM, Publishability.WARNINGS) > 0) &&
      (this.publishabilityCount(CurriculumEntityType.COURSE, Publishability.CLEAN) > 0 ||
        this.publishabilityCount(CurriculumEntityType.COURSE, Publishability.WARNINGS) > 0);
  }

  publish$(): Observable<PublishCurriculumApiResponse> {
    let response: PublishCurriculumApiResponse;

    return this._entityManager.performApiAction$(newPublishCurriculumAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear
    })).pipe(
      flatMap(it => {
        response = it;
        if (isSuccessfulPublish(response)) {
          this._toastService.add(createToastObject( ToastType.TOAST_IS_SUCCESS,"Curriculum Year Published"));
        }
        // TODO: toast here instead of console error
        else if (isPublishConflict(response)) {
          this._toastService.add(createToastObject( ToastType.TOAST_IS_ERROR,"Publishing Conflict"));
        }
        else if (isRemoteServerPublishError(response)) {
          this._toastService.add(createToastObject( ToastType.TOAST_IS_ERROR,"Server Error"));
        }

        // flush the curriculum year from cache
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return response;
        }
      ));
  }

  bulkEditFromCsv(entityType: PipelineEntityType, bulkAmendType: BulkAmendType, fileContents: string,
                  importCorrectionsByRow?:Map<number, ImportCorrections>){
    return this.mutateRecords$<ApiEither<BulkAmendErrors, BulkAmendResult>>(entityType,
      newBulkAmendCorrectionsAction({
        amendType: bulkAmendType,
        fileContents: fileContents,
        importCorrectionsByRow: this.fromMap(importCorrectionsByRow),
      }));
  }

  fromMap(importCorrections: Map<number, ImportCorrections>): { [index: string]: ImportCorrections } {
    if(importCorrections){
      const asObject = {};
      importCorrections.forEach((value, key) => {
        asObject[key] = value;
      });
      return asObject;
    }
    else{
      return undefined;
    }
  }

  validate$(): Observable<PublishPreview> {
    return this._entityManager.performApiAction$(newPublishPreviewAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear
    })).pipe(tap (result => console.log(result)));
  }

  collegeCurriculumReport$(): Observable<CollegeCurriculumReportResult> {
    return this._entityManager.performApiAction$(newGenerateCollegeReportAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear
    })).pipe(
      map(apiResult => new CollegeCurriculumReportResult(apiResult))
    );
  }

  async mutateRecords<T extends ApiObject>(entityType: PipelineEntityType, action: RecordMutationApiAction<T>): Promise<T> {
    const result = await this.mutateRecords$(entityType, action).toPromise();
    return result as T;
  }

  mutateRecords$<T extends ApiObject>(entityType: PipelineEntityType, action: RecordMutationApiAction<T>): Observable<T> {
    let mutationResult: T;

    return this._entityManager.performApiAction$(newRecordMutationAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      pipelineEntityType: entityType,
      action: action
    })).pipe(
      flatMap(it => {
        mutationResult = it as T;
        // flush the curriculum year from cache
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return mutationResult;
        }
      ));
  }

  importSourceDirectly$(entityType: PipelineEntityType, sourceSystem: SourceSystem) {
    return this.mutateRecords$(entityType,
        newFetchAndImportSourceFileAction({
          sourceSystem: sourceSystem
        })
      );
  }

  // TODO: Jay: if we decide to Implement this observable version of uploadSourceFile, the call to
  //  this._applicationState.cacheImportParseErrorsAndSourceDocumentState() needs to be implemented here as well.
  // private uploadSourceFile$(sourceSystem: SourceDocumentType, fileContents: string): Observable<ApiEither<ParseErrorApiObject, Nothing>> {
  //   return this.mutateRecords$(curriculumEntityTypeFromSourceDocumentType(sourceSystem),
  //       newImportSourceFileAction({
  //         dataSource: dataSourceFromSourceDocumentType(sourceSystem),
  //         fileContents: fileContents
  //     })
  //   );
  // }

  uploadSourceFile$(entityType: PipelineEntityType, sourceSystem: SourceSystem, fileContents: string): Observable<ApiEither<ParseErrorApiObject, Nothing>> {
    return this.mutateRecords$(entityType,
        newImportSourceFileAction({
          sourceSystem: sourceSystem,
          fileContents: fileContents
        }));
  }

  directELumenProgramImport$(endMonth: String, endDay: Number, endYear : Number): Observable<Boolean> {
    return this._entityManager.getDirectELumenImportAction$("programs", this._apiObject.collegeSlug, this.curriculumYear, endMonth, endDay, endYear).pipe(
        flatMap(it => {
          if (it == false) {
            return of(false);
          }
          // flush the curriculum year from cache
          return this._applicationState.refreshCurriculumYear$();
        }),
        map(curriculumYearUpdated => {
              return curriculumYearUpdated;
            }
        ));
  }

  directELumenCourseImport$(endMonth : String, endDay : Number, endYear : Number): Observable<Boolean> {
    return this._entityManager.getDirectELumenImportAction$("courses", this._apiObject.collegeSlug, this.curriculumYear, endMonth, endDay, endYear).pipe(
      flatMap(it => {
        if (it == false) {
          return of(false);
        }
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return curriculumYearUpdated;
        }
      ));
  }

  public directCurriqunetProgramImport$(): Observable<Boolean> {
    return this._entityManager.getDirectCurriqunetImportAction$("programs", this._apiObject.collegeSlug, this.curriculumYear).pipe(
      flatMap(it => {
        if (it == false) {
          return of(false);
        }
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return curriculumYearUpdated;
        }
      ));
  }

  public directCurriqunetCourseImport$(): Observable<Boolean> {
    return this._entityManager.getDirectCurriqunetImportAction$("courses", this._apiObject.collegeSlug, this.curriculumYear).pipe(
      flatMap(it => {
        if (it == false) {
          return of(false);
        }
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return curriculumYearUpdated;
        }
      ));
  }

  directImportType$(): Observable<String> {
    return this._entityManager.getDirectImportTypeAction$(this._apiObject.collegeSlug);
  }

  getDirectImportType(): Promise<String>{
      return new Promise((resolve, reject) => {
        const subscription = this._entityManager.getDirectImportTypeAction$(this._apiObject.collegeSlug).subscribe({
          next: importType => {
            resolve(importType);
            subscription.unsubscribe();
          },
          error: e => {
            console.log(e);
            reject(e);
          }
        });
      });
  }

  importBadges$(fileContents: string): Observable<ApiEither<ParseErrorApiObject, Nothing>> {
    return this.mutateRecords$(PipelineEntityType.COURSE, // TODO: its not really courses...
      newImportBadgeFileAction({
        fileContents: fileContents
      }));
  }

  async deactivateSource(entityType: PipelineEntityType, sourceSystem: SourceSystem) {
    return this.mutateRecords(entityType,
      newDeactivateSourceSystemAction({ sourceSystem: sourceSystem}));
  }

  courseLists$(searchParameters: Pick<FindCourseListsAction, 'itemsPerPage' | 'page' | 'criteria'>) {
    return this._entityManager.performApiAction$(
      newFindCourseListsAction({
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        ... searchParameters })
    );
  }

  badges$(
    itemsPerPage: number,
    searchTerm: string | undefined,
    page: number
  ): Observable<FindImportedBadgesResponse> {

    return this._entityManager.performApiAction$(newFindImportedBadgesAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      itemsPerPage: itemsPerPage,
      page: page,
      searchTerm : searchTerm,
    }));
  }

  genEdPatterns$() {
    return this._entityManager.performApiAction$(
      newFindGenEdPatternsAction({
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear})
    );
  }

  deleteCourseList$(courseListUuid: string){
    //do not define response type here, so that an error may be thrown later if wrong type is returned
    let response;
    return this._entityManager.performApiAction$(newDeleteCourseListAction(
      {
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        courseListUuid: courseListUuid}
    )).pipe(
      flatMap(it => {
        response = it;
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
        return response;
      })
    );
  }

  addCourseList$(courseList: Pick<CourseListAddRequest, 'longDescription' | 'pickedSlugs' | 'shortDescription' | 'title'>) : Observable<AddCourseListApiResponse> {
    let response : AddCourseListApiResponse;
    return this._entityManager.performApiAction$(newCourseListAddRequest({
        ...courseList,
        collegeSlug: this.collegeSlug,
        curriculumYear : this.curriculumYear
      })
    ).pipe(
      flatMap(it =>{
        response = it;
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
        return response;
      })
    );
  }

  updateCourseList$(payload: Pick<UpdateCourseListAction, 'id' | 'isActive' | 'lockVersion' | 'longDescription' | 'pickedCourseSlugs' | 'shortDescription' | 'title'>) : Observable<CourseListId>{
    let response : CourseListId;

    return this._entityManager.performApiAction$(newUpdateCourseListAction(
      {
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        ... payload
      }
    ))
  .pipe(
      flatMap(it => {
        response = it;
        // flush the curriculum year from cache
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return response;
        }
      ));
  }

  updateGenEdPattern$(id: string, isPartOfCurriculum: boolean , courses: { [index: string]: string[] }) {
    let response: GenEdPatternApiObject;

    return this._entityManager.performApiAction$(newUpdateGenEdAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      genEdPatternId: id,
      courseSlugsByNode: courses
    })).pipe(
      flatMap(it => {
        response = it;
        // flush the curriculum year from cache
        return this._applicationState.refreshCurriculumYear$();
      }),
      map(curriculumYearUpdated => {
          return response;
        }
      ));
  }

  genEdPatternsDetail$(id: string) : Observable<GenEdPatternApiObject>{
    return this._entityManager.performApiAction$(
      newGetGenEdPatternAction({ genEdId: id, collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,})
    );
  }

  pickableCourses$(
    itemsPerPage: number,
    searchTerm: string | undefined,
    showNonPublishable: boolean,
    showInactive: boolean,
    page: number
  ): Observable<FindPickableCoursesResponse> {

    return this._entityManager.performApiAction$(newFindPickableCoursesAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      itemsPerPage: itemsPerPage,
      page: page,
      searchTerm : searchTerm,
      showInactive : showInactive,
      showInvalid : showNonPublishable,
    }));
  }

  topCodes$(
    itemsPerPage: number,
    searchTerm: string | undefined,
    page: number
  ): Observable<FindTopCodesResponse> {

    return this._entityManager.performApiAction$(newFindTopCodesAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      itemsPerPage: itemsPerPage,
      page: page,
      searchTerm : searchTerm,
    }));
  }

  cipCodes$(
    itemsPerPage: number,
    searchTerm: string | undefined,
    page: number
  ): Observable<FindCipCodesResponse> {

    return this._entityManager.performApiAction$(newFindCipCodesAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      itemsPerPage: itemsPerPage,
      page: page,
      searchTerm : searchTerm,
    }));
  }

  courses$(criteria: CurriculumEntitySearchCriteria, page: number, itemsPerPage: number):
    Observable<PaginatedFilteredCourseSearchResults> {
    return this._entityManager.performApiAction$(newFindCoursesAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      criteria: criteria,
      page: page,
      itemsPerPage: itemsPerPage
    }));
  }

  programs$(criteria: CurriculumEntitySearchCriteria, page: number, itemsPerPage: number):
    Observable<PaginatedFilteredProgramSearchResults> {
    return this._entityManager.performApiAction$(newFindProgramsAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      criteria: criteria,
      page: page,
      itemsPerPage: itemsPerPage
    }));
  }

  highSchools$(){
    return this._entityManager.performApiAction$(newSearchHighSchoolsByCollegeAction({
      collegeSlug: this.college.slug,
      curriculumYear: this.curriculumYear,
      collegeId: this.college.uuid
    }))
  }

  updateNotes$(notes: string) {
    return this._entityManager.performApiAction$(newEditCurriculumNotesAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      notes : notes
    }));
  }

  getVersionHistory$() : Observable<CurriculumYearHistoryResponse> {
    return this._entityManager.performApiAction$(newGetCurriculumYearHistoryAction(
      {
        collegeSlug : this.collegeSlug,
        curriculumYear : this.curriculumYear
      }
    ));
  }

  courseListById$(courseListId: string) : Observable<CourseListApiObject>{
    return this._entityManager.performApiAction$(newCourseListByIdAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      id: newCourseListId({uuid: courseListId})}))
      .pipe(
        map(courseListOrNotFound => {
          if(isSuccess(courseListOrNotFound)){
            return courseListOrNotFound.value;
          }
          else {
            throw new Error("unexpected response from server");
          }
        })
      );
  }

  courseById$(courseId: string): Observable<CourseApiObject | CurriculumEntityDetailMissing> {
    return this._entityManager
      .performApiAction$(newCurriculumEntityDetailAction({
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        'entityId': courseId}))
      .pipe(
        map(response => {

          if (isFailure(response)) {
            return response.value;
          } else if (isSuccess(response)  && isCourseApiObject(response.value)) {
            return response.value;
          } else {
            throw new Error('unhandled response: ' + response['@type']);
          }
        })
      );
  }

  programById$(courseId: string): Observable<ProgramApiObject> {
    return this._entityManager
      .performApiAction$(newCurriculumEntityDetailAction({
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        'entityId': courseId}))
      .pipe(
        map(response => {
          if (isFailure(response)) {
            return response.value;
          } else if (isSuccess(response)  && isProgramApiObject(response.value)) {
            return response.value;
          } else {
            throw new Error('unhandled response: ' + response['@type']);
          }
        })
      );
  }

  courseSourceRecord$(curriculumEntityId:string, sourceRecordId: string): Observable<SourceRecordDetailApiObject<RichCourseRecord>> {
    return this._entityManager
      .performApiAction$(newSourceRecordDetailAction({
        collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        'curriculumEntityId': curriculumEntityId,
        'id' : sourceRecordId})) as Observable<SourceRecordDetailApiObject<RichCourseRecord>>;
  }

  programSourceRecord$(curriculumEntityId:string, sourceRecordId: string): Observable<SourceRecordDetailApiObject<RichProgramRecord>> {
    return this._entityManager
      .performApiAction$(newSourceRecordDetailAction({collegeSlug: this.collegeSlug,
        curriculumYear: this.curriculumYear,
        'curriculumEntityId': curriculumEntityId, 'id' : sourceRecordId})) as Observable<SourceRecordDetailApiObject<RichProgramRecord>>;
  }

  aggregateNoticeCounts(entityType: CurriculumEntityType, customGroups: { [p: string]: CourseSchema[] } | { [p: string]: ProgramSchema[] }) {
    return this._entityManager.performAction(newAggregateNoticeCounts({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      entityType: entityType,
      customGroups: customGroups
    }));
  }

  aggregateCleanCounts(entityType: PipelineEntityType, customGroups: { [p: string]: CourseSchema[] } | { [p: string]: ProgramSchema[] }) {
    return this._entityManager.performAction(newAggregateCleanCounts({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      entityType: entityType,
      customGroups: customGroups
    }));
  }

  aggregateAlignmentCounts(entityType: PipelineEntityType) {
    return this._entityManager.performAction(newAggregateAlignmentCounts({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      entityType: entityType
    }));
  }

  async aggregateSourceCounts(entityType: PipelineEntityType) {
    return (await this._entityManager.performAction(newAggregateSourceCounts({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      entityType: entityType
    }))).summaries;
  }

  // Observable versions
  aggregateSourceCounts$(entityType: PipelineEntityType): Observable<SourceCountSummaryBySourceSystem> {
    return this._entityManager.performApiAction$(newAggregateSourceCounts({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      entityType: entityType
      })
    )
    .pipe(map( r => r.summaries ));
  }

  labelForSourceSystems(entityType: PipelineEntityType, systems: SourceSystem[]): string {
    if(!systems){
      throw new Error("no systems");
    }
    return systems.map(system => this.labelForSourceSystem(entityType, system)).join(",");
  }

  labelForSourceSystem(entityType: PipelineEntityType, system: SourceSystem) {
    return this.college.sourceLabels[entityType] && this.college.sourceLabels[entityType][system];
  }

  archive() {
    return this._entityManager.performApiAction$(newArchiveCurriculumYearAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
    }));
  }

  forcePublish(){
    return this._entityManager.performApiAction$(newMarkPublishedAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear
    }))
  }

  addHighSchool$(highSchoolForCreate : CreateHighSchoolApiObject): Observable<CreateHighSchoolResponse> {
    let mutationResult: CreateHighSchoolResponse;
    return this._entityManager.performApiAction$(newCreateHighSchoolAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      highSchool: highSchoolForCreate
    })).pipe(
        flatMap(it => {
          mutationResult = it;
          // flush the curriculum year from cache
          return this._applicationState.refreshCurriculumYear$();
        }),
        map(curriculumYearUpdated => {
              return mutationResult;
            }
        ));
  }

  editHighSchool$(highSchoolForEdit : EditHighSchoolApiObject): Observable<EditHighSchoolResponse> {
    let mutationResult: EditHighSchoolResponse;
    return this._entityManager.performApiAction$(newEditHighSchoolAction({
      collegeSlug: this.collegeSlug,
      curriculumYear: this.curriculumYear,
      highSchool: highSchoolForEdit
    })).pipe(
        flatMap(it => {
          mutationResult = it;
          // flush the curriculum year from cache
          return this._applicationState.refreshCurriculumYear$();
        }),
        map(curriculumYearUpdated => {
              return mutationResult;
            }
        ));
  }

  get collegeCourseImportInitialSelectorRoute(){
    return [...this.coursesDashboardRoute, 'import', 'initial-selector']
  }

  get collegeCourseImportDirectRoute(){
    return[...this.coursesDashboardRoute, 'import', 'direct']
  }

  get collegeProgramsImportTypeSelectRoute() {
    return[...this.programsDashboardRoute, 'import', 'type-selector']
  }

  get collegeProgramsImportDirectDashboardRoute() {
    return[...this.programsDashboardRoute, 'import', 'direct']
  }

  get stateCoursesImportRootRoute() {
    return [...this.coursesDashboardRoute, 'import', 'state']
  }

  get collegeCoursesImportRootRoute() {
    return [...this.coursesDashboardRoute, 'import', 'college']
  }

  get collegeCoursesImportDashboardRoute(){
    return [...this.coursesDashboardRoute, 'import', 'college', 'dashboard']
  }

  get stateProgramsImportRootRoute() {
    return [...this.programsDashboardRoute, 'import', 'state']
  }

  get collegeProgramsImportRootRoute() {
    return [...this.programsDashboardRoute, 'import', 'college']
  }

  stateCoursesDuplicateRoute() {
    return [...this.stateCoursesImportRootRoute, 'duplicates']
  }

  collegeCoursesDuplicateRoute() {
    return [...this.collegeCoursesImportRootRoute, 'duplicates']
  }

  stateProgramsDuplicateRoute() {
    return [...this.stateProgramsImportRootRoute, 'duplicates']
  }

  collegeProgramsDuplicateRoute() {
    return [...this.collegeProgramsImportRootRoute, 'duplicates']
  }

  stateCoursesMissingSubjectNumberRoute() {
    return [...this.stateCoursesImportRootRoute, 'subject-number', 'missing']
  }

  collegeCoursesMissingSubjectNumberRoute() {
    return [...this.collegeCoursesImportRootRoute, 'subject-number', 'missing']
  }

  stateProgramMissingTitleAwardRoute() {
    return [...this.stateProgramsImportRootRoute, 'title-award', 'missing'];
  }

  collegeProgramMissingTitleAwardRoute() {
    return [...this.collegeProgramsImportRootRoute, 'title-award', 'missing']
  }

  stateCoursesImportRoute() {
    if (isCOCISource(this.stateSource)) {
      return [...this.stateCoursesImportRootRoute, 'COCI', 'direct']
    } else if (isCSUSupplementalSource(this.stateSource)) {
      return [...this.stateCoursesImportRootRoute, 'CSU', 'csv']
    }
  }

  stateProgramsImportRoute() {
    if (isCOCISource(this.stateSource)) {
      return [...this.stateProgramsImportRootRoute, 'COCI', 'direct']
    } else if (isCSUSupplementalSource(this.stateSource)) {
      return [...this.stateProgramsImportRootRoute, 'CSU', 'csv']
    }
  }

  // TODO: Consider using a single component for program review, with one route for state and one for college
  stateProgramsReviewRoute() {
    if (isCOCISource(this.stateSource)) {
      return [...this.stateProgramsImportRootRoute, 'COCI', 'review']
    } else if (isCSUSupplementalSource(this.stateSource)) {
      return [...this.stateProgramsImportRootRoute, 'CSU', 'review']
    }
  }

  // TODO: Consider using a single component for course review, with one route for state and one for college
  stateCoursesReviewRoute() {
    if (isCOCISource(this.stateSource)) {
      return [...this.stateCoursesImportRootRoute, 'COCI', 'review']
    } else if (isCSUSupplementalSource(this.stateSource)) {
      return [...this.stateCoursesImportRootRoute, 'CSU', 'review']
    }
  }

  // TODO: Consider routing to one component that includes a source-specific subcomponent
  collegeCoursesImportRoute() {
    if (isGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'GENERIC', 'csv']
    } else if (isELumenSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'ELUMEN', 'csv']
    } else if (isPeopleSoftSource(this.collegeSource)) {
     return [...this.collegeCoursesImportRootRoute, 'PEOPLESOFT', 'csv']
    } else if (isUCGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'GENERIC', 'csv']
    }
  }

  // TODO: Consider using a single component for course review, with one route for state and one for college
  collegeCoursesReviewRoute() {
    if (isGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'GENERIC', 'review']
    } else if (isELumenSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'ELUMEN', 'review']
    } else if (isPeopleSoftSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'PEOPLESOFT', 'review']
    } else if (isUCGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeCoursesImportRootRoute, 'GENERIC', 'review']
    }
  }

  // TODO: Consider using a single component for program review, with one route for state and one for college
  collegeProgramsReviewRoute() {
    if (isGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'GENERIC', 'review']
    } else if (isELumenSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'ELUMEN', 'review']
    } else if (isPeopleSoftSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'PEOPLESOFT', 'review']
    } else if (isUCGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'GENERIC', 'review']
    }
  }

  collegeProgramsImportRoute() {
    if (isGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'GENERIC', 'csv']
    } else if (isELumenSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'ELUMEN', 'csv']
    } else if (isPeopleSoftSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'PEOPLESOFT', 'csv']
    } else if (isUCGenericCollegeSource(this.collegeSource)) {
      return [...this.collegeProgramsImportRootRoute, 'GENERIC', 'csv']
    }
  }

}

export function createProgramCorrection(value:any, field: ProgramSchema) {
  return {
    '@type': 'SetValue',
    field: field,
    value: value
  } as SetValue<RichProgramRecord, any>;
}

export function createCourseCorrection(value, field: CourseSchema) : SetValue<RichCourseRecord, any> {
  return {
    '@type': 'SetValue',
    field: field,
    value: value
  } as SetValue<RichCourseRecord, any>;
}

export class ParseError {
  constructor(
    private parseError: ParseErrorApiObject
  ) {
  }

  get isCociUnreachable(): boolean {
    return isCociNotReachableError(this.parseError);
  }

  get isNoSourceConfigured(): boolean {
    return isNoSourceConfiguredError(this.parseError);
  }

  get isNotParsableError(): boolean {
    return isNotParsableError(this.parseError);
  }

  get isSourceNotFetchable(): boolean {
    return isSourceNotFetchableError(this.parseError);
  }

  get isParseValidationError(): boolean {
    return isParseValidationError(this.parseError);
  }

  get cociUrl(): string {
    if (isCociNotReachableError(this.parseError)) {
      return this.parseError.urlCalled;
    }
  }


  get minimumRequirementErrors(): RowWithNumber[] {
    if (isParseValidationError(this.parseError)) {
      return this.parseError.minimumRequirementErrors;
    }
  }
}

export enum SourceDocumentImportType {
  INITIAL_IMPORT = 'INITIAL_IMPORT',
  IMPORT_FAILED = 'IMPORT_FAILED',
  IMPORT_REVIEWED = 'IMPORT_REVIEWED',
  NOT_IMPORTED_THIS_SESSION = 'NOT_IMPORTED_THIS_SESSION'
}
/**
 * Maintains import state for a source document.
 * ApplicationStateService.SourceDocumentStateMap is initialized at startup with all source document types set to
 * state NONE. THey are updated after attempting to upload a datasource incurriculum-year.ts.
 * ApplicationStateService.cacheImportParseErrorsAndSourceDocumentState() is called with the result. Further updates
 * to the state can happen in a components through a property on base-routable
 *
 * Access to the current source document state object is exposed by base-routable.sourceDocumentState. Which in turn
 * uses applicationState.currentSourceDocumentStateObject to give you the correct state object for the
 * uses route. E.g:
 *
 * accessing base-routable.sourceDocumentState at route 'programs/import/state' will return the state object for
 * SourceDocumentType.COCI_PROGRAMS
 *
 * accessing base-routable.sourceDocumentState at route 'programs/import/college' will return the state object for
 * the colleges currently configured DataSource: SourceDocumentType.ELUMEN_PROGRAMS or SourceDocumentType.GENERIC_PROGRAMS
 **/
export class SourceDocumentState {
  constructor(
    private sourceState: SourceDocumentImportType) {
  }

  get currentState(): SourceDocumentImportType {
    return this.sourceState;
  }

  set currentState(state: SourceDocumentImportType) {
    this.sourceState = state;
  }

  get isIntialImport(): boolean {
    return this.sourceState === SourceDocumentImportType.INITIAL_IMPORT;
  }

  get didImportFail(): boolean {
    return this.sourceState === SourceDocumentImportType.IMPORT_FAILED;
  }

  get HaveResultsBeenReviewed(): boolean {
    return this.sourceState === SourceDocumentImportType.IMPORT_REVIEWED;
  }

  get wasImportedThisSession(): boolean {
    return this.sourceState !== SourceDocumentImportType.NOT_IMPORTED_THIS_SESSION;
  }
}





