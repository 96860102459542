import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BaseRoutableComponent} from "../base-routable.component";
import {OnDestroy} from "@angular/core/src/metadata/lifecycle_hooks";
import {ServiceManager} from "../../services/service-manager";
import {ActivatedRoute, Router} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {takeUntil} from "rxjs/operators";
import {Observable} from "rxjs";
import {
  FindImportedBadgesResponse, IImportedBadge,
  IPickedBadge
} from "../../../api/datacleanuptool-api.model";
import {PaginationObject} from "../pagination/pagination.component";

@Component({
  selector: 'app-badge-picker',
  templateUrl: './badge-picker.component.html',
})
export class BadgePickerComponent extends BaseRoutableComponent implements OnInit, OnDestroy {

  modalRef: BsModalRef;
  @ViewChild('template') template;

  readonly itemsPerPage = 10;
  searchResults$: Observable<FindImportedBadgesResponse>;

  private _searchTerm: string;
  private _page: number = 1;
  private _pickedBadge: IPickedBadge;

  constructor(
    protected readonly serviceManager: ServiceManager,
    protected activatedRoute: ActivatedRoute,
    private modalServiceA: BsModalService,
    private router: Router,
  ) {
    super(serviceManager, activatedRoute);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalServiceA.show(template, {ignoreBackdropClick: true, backdrop: false, keyboard: false, class: 'modal-lg'});
  }

  closeModal() : void {
    this.modalRef.hide();

    if(this._pickedBadge){
      // TODO: enable picking
      this.applicationState.badgePicked(this._pickedBadge);
    }
    else {
      // TODO: enable initial pick state
      this.applicationState.badgePicked(undefined);
    }

    this.router.navigate(
      [ './', { outlets: { badgePickerModal: null }} ],{ relativeTo: this.route.parent }
    );
  }

  ngOnInit(): void  {
    // TODO: initial state.. remove this here once its triggered by initial top code selection below..
    // this._searchTerm = undefined;
    // this.updatePaginatedResults();

    this.applicationState.initiallyPickedBadge$
      .pipe(takeUntil(this.onNgDestroy))
      .subscribe(
        pickedBadge => {
          // TODO: initial state
          if(pickedBadge) {
            this._pickedBadge = pickedBadge;
          }
          this._searchTerm = undefined;
          this.updatePaginatedResults();
        }
      );

    setTimeout(() => {
      this.openModal(this.template);
    });

  }

  searchChanged(searchParams: string) {
    this._searchTerm = searchParams;
    this.resetToPageOne();
    this.updatePaginatedResults();
  }

  private resetToPageOne() {
    this._page = 1;
  }

  get page(){
    return this._page;
  }

  setPage(page: number) {
    // console.log("set page.." + page);
    this._page = page;
    this.updatePaginatedResults();
  }

  onPaginationChange(paginationObject: PaginationObject) {
    this.setPage(paginationObject.page);
  }

  private updatePaginatedResults() {
    console.log("filter and search: items per page:" + this.itemsPerPage + ",search term: " + this._searchTerm + ", page " + this._page);
    this.searchResults$ = this.curriculumYear.badges$(
      this.itemsPerPage,
      this._searchTerm,
      this._page);
  }

  isSelected(badge: IImportedBadge) {
    // TODO: initial selection
    return this._pickedBadge && this._pickedBadge.badgeClassUrl === badge.badgeClassUrl;
  }

  pickImportedBadge(importedBadge: IImportedBadge) {
    this._pickedBadge = this.convertImportedBadgeToPickedBadge(importedBadge);
    console.log("THIS IS THE PICKED BADGE", this._pickedBadge);
  }

  private convertImportedBadgeToPickedBadge(importedBadge: IImportedBadge) {
    return {
      badgeClassUrl: importedBadge.badgeClassUrl,
      badgeTitle: importedBadge.badgeTitle,
      badgeClassImageUrl: importedBadge.badgeClassImageUrl,
      missing: false
    };
  }
}
