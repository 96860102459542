import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {fromEvent} from "rxjs/internal/observable/fromEvent";
import {debounceTime, distinctUntilChanged} from "rxjs/operators";


@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html'
})
export class InputSearchComponent implements AfterViewInit, OnDestroy {

  @Input() placeholder: string;

  @Input() label: string;

  @Input() id: string;

  @Input() currentSearchString: string = '';

  @Output() searchChangeEmitter: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputControl') input;

  private keyUp$: Observable<any>;

  private keyUpSubscription: Subscription;

  ngAfterViewInit() {
    this.keyUp$ = fromEvent(this.input.nativeElement, 'keyup')
      .pipe (
        distinctUntilChanged(),
        debounceTime(300), //only search after 3000 ms

        // tap(() => this.loading.emit(true)), // Enable loading
        // // search, call the search service
        //
        // map((query:string) => this.youtube.search(query)) ,
        // // discard old events if new input comes in
        //
        // // act on the return of the search
      );
    // this.keyUp$ = Observable
    //   .fromEvent<HTMLElement>(this.input.nativeElement, 'keyup')
    //   .distinctUntilChanged()
    //   .debounceTime(300);

    this.keyUpSubscription = this.keyUp$.subscribe(() => this.searchChangeEmitter.emit( this.input.nativeElement.value ) );
  }

  ngOnDestroy() {
    if (this.keyUpSubscription ) { this.keyUpSubscription.unsubscribe(); }
  }

}
