import { Component, OnInit } from '@angular/core';
import { ApplicationStateService } from '../shared/services/application-state.service';
import {ClientEntityManager} from '../shared/entity-system/client-entity-manager.service';
import { Router } from '@angular/router';
import {
  newFindPickableCollegesAction,
} from "../api/datacleanuptool-api.model";
import {Observable} from "rxjs";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {createToastObject, ToastService, ToastType} from "../shared/services/toast.service";
import {createMsgFromErrorType} from "../shared/util/error-util";
import {map} from "rxjs/operators";
import {SelectFormControlOption} from "../shared/components/formcontrols/select/select.component";
import {sortBy} from "../shared/util/array-utils";

@Component({
  selector: 'app-colleges',
  templateUrl: './colleges.component.html'
})
export class CollegesComponent implements OnInit {

  get collegesOptions$(): Observable<SelectFormControlOption[]> {
    return this.entityManager.performApiAction$(newFindPickableCollegesAction())
      .pipe(map(it => {
        return it.colleges.map(college => {
          return {name: college.name, value: college.slug}
        })
      }),
        map(it => sortBy(it, it => it.name)))
  }

  form: FormGroup;

  constructor(
    private applicationStateService: ApplicationStateService,
    private router: Router,
    private entityManager: ClientEntityManager,
    private fb: FormBuilder,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.applicationStateService.clearSelectedCollege();

    const formControls = {
      college: new FormControl(Validators.required),
    };

    this.form = this.fb.group(formControls);
  }

  //webpack:///src.

  submit() {
    const sub = this.applicationStateService.selectCollege$(this.form.controls.college.value).subscribe(
      collegeSelected => {
        if (collegeSelected){
          // route to the curriculum year dashboard
          this.router.navigate(['colleges', this.applicationStateService.college.slug, 'curriculum-years']);
        }
        else {
          this.toastService.add(
            createToastObject( ToastType.TOAST_IS_ERROR, createMsgFromErrorType("Unable to select " + this.form.controls.college.value, "College"))
          )
        }
      }
    );
  }

}
