import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from as fromPromise ,  Observable ,  of } from 'rxjs';
import { MessageService } from './message.service';
import { tap } from 'rxjs/operators';
import { EnvironmentConfigService } from './environment-config.service';



import { LoggingService } from './logging-service';
import { Router } from '@angular/router';
// import { HttpParamsOptions } from '@angular/shared/http/src/params';
import { ApiRequestMessage, } from '../../api/datacleanuptool-api.model';
import {switchMap} from "rxjs/internal/operators/switchMap";

@Injectable()
export class ApiUrlService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private envSpecificSvc: EnvironmentConfigService,
    private loggingService: LoggingService,
    private router: Router
  ) {}

  // sendMessageWithFile<ResponseType>(
  //   file: File,
  //   message: ApiRequestUploadMessage<ResponseType>
  // ): Observable<ResponseType> {
  //   const data = new FormData();
  //   data.set('file', file);
  //   data.set('message', JSON.stringify(message));
  //
  //   return this.postRest('api/v1/dispatch-upload', data);
  // }

  sendMessage<ResponseType>(
    message: ApiRequestMessage<ResponseType>
  ): Observable<ResponseType> {
    return this.postRest('api/v1/dispatch', message);
  }

  getELumenDirectImport<ResponseType>(recordType: String, collegeSlug: String, year: Number, endMonth: String, endDay: Number, endYear: Number) : Observable<ResponseType> {

      let apiPath = "elumen-direct-import/" + recordType.toLowerCase() + "/" + collegeSlug.toLowerCase() + "/" + year.toString() + "/" + endMonth.toUpperCase() + "/" + endDay.toString() + "/" + endYear.toString();

      return this.getRestData(apiPath);
  }

  public getCurriqunetDirectImport<ResponseType>(recordType: String, collegeSlug: String, year: Number) : Observable<ResponseType> {

    let apiPath = "curriqunet-direct-import/" + recordType.toLowerCase() + "/" + collegeSlug.toLowerCase() + "/" + year.toString();

    return this.getRestData(apiPath);
  }

  getDirectImportType(collegeSlug: String): Observable<String> {
      let apiPath = "direct-import-type/" + collegeSlug.toLowerCase();

      return this.getRestData(apiPath);
  }

  private getEnvironmentConfig() {
    return this.envSpecificSvc.environmentConfig;
  }

  getRestData<T>(apiPath: string): Observable<T> {
    return this.getBaseUrl()
      .pipe(
        switchMap(baseUrl => {
          const apiUrl = baseUrl + '/' + apiPath;
          this.loggingService.log('GET apiUrl ::' + apiUrl);
          return this.http.get<T>(apiUrl)
            .pipe(
              tap(data => this.logMessage(apiPath + ' : ' + JSON.stringify(data))
              )
            );
        })
    );
  }

  postAuth<T, B>(payload: B, apiPath: string): Observable<T>{
    return this.getBaseAuthUrl()
      .pipe(
        switchMap( baseUrl => {
          const apiUrl = baseUrl + '/' + apiPath;
          this.loggingService.log('POST for apiUrl :: ' + apiUrl);

          return this.http
            .post<T>(apiUrl, payload);
        })
      );
  }


  postRest<T>(apiPath: string, body: any): Observable<T> {
    return this.getBaseUrl()
      .pipe(
        switchMap( baseUrl => {
          const apiUrl = baseUrl + '/' + apiPath;
          this.loggingService.log('POST for apiUrl :: ' + apiUrl);

          return this.http
            .post<T>(apiUrl, body)
            .pipe(
              tap(data => this.logMessage(apiPath + ' : ' + JSON.stringify(data))
                // , _ =>  this.router.navigate(['/500'])
              )
            );
        })
      );
  }

  /** Log a CollegeService message with the MessageService */
  private logMessage(message: string) {
    this.messageService.add('College Service: ' + message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.logMessage(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getBaseUrl(): Observable<string> {
    return fromPromise(this.getEnvironmentConfig().then(data => data.apiBaseUrl));
  }

  getBaseAuthUrl(): Observable<string> {
    return fromPromise(this.getEnvironmentConfig().then(data => data.apiHostAuth));
  }
}
