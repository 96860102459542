import { Injectable } from '@angular/core';
import { ClientEntityManager } from '../entity-system/client-entity-manager.service';
import { LoggingService } from './logging-service';
import { ApplicationStateService } from './application-state.service';
import { HeaderTitleService } from './header-title.service';
import { ToastService } from './toast.service';

@Injectable()
export class ServiceManager {

  constructor(
    private _toastService : ToastService,
    private clientEntityManager: ClientEntityManager,
    private loggingService: LoggingService,
    private headerTitleService: HeaderTitleService,
    private applicationStateService: ApplicationStateService,
    ) {}

  get entityManager() {
    return this.clientEntityManager;
  }

  get logger() {
    return this.loggingService;
  }

  get toastService (): ToastService {
    return this._toastService;
  }

  get applicationState (): ApplicationStateService {
    return this.applicationStateService;
  }

  get headerTitle(): HeaderTitleService {
    return this.headerTitleService;
  }

}
