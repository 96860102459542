import {
  CollegeReport, CollegeReportCourseSummary, CollegeReportProgramSummary
} from "../../../api/datacleanuptool-api.model";

export class CurriculumInfo2  {
  // get uuid() {
  //   return this.id.curriculumUuid;
  // }
  //
  // get collegeUuid() {
  //   return this.data && this.data.collegeUuid;
  // }
  //
  // get notes() {
  //   return this.data && this.data.notes;
  // }
  //
  // set notes(notes: string) {
  //   this.mutateData(data => data.notes = notes);
  // }
  //
  // get version() {
  //   return this.data && this.data.version;
  // }
  //
  // // get templateDownloadUrls() {
  // //   return this.data && this.data.templateDownloadUrls;
  // // }
  //
  // get versionAndStatus() {
  //   return `${this.versionLabel}`;
  // }
  //
  // get isPublished(): boolean {
  //   return !!this.publishedDate;
  // }
  //
  // get isOriginalVersion() {
  //   return this.minorVersion === 0;
  // }
  //
  // get title() {
  //   if (this.data && this.versionLabel) {
  //     return `${this.versionAndStatus}`;
  //   }
  // }

  // @CachedProperty()
  // get sortedCourses() {
  //   return this.courses.projectToArray(courses => sortBy(courses.toArray(), course => course.courseCode));
  // }

  // @CachedProperty()
  // get coursesBySlugMap() {
  //   return this.project(() => this.sortedCourses)
  //   .flatProject(courses =>
  //     courses.toArray().reduce(
  //       (map, course) => map.set(course.slug, course),
  //       new Map()));
  // }d

  // @DataComputedProperty()
  // get label() {
  //   return this.versionLabel && 'v' + this.versionLabel;
  // }

  // @DataComputedProperty()
  // get majorVersion() {
  //   return this.data && parseInt(this.version.split('.')[0]) || 0;
  // }
  //
  // @DataComputedProperty()
  // get minorVersion() {
  //   return this.data && parseInt(this.version.split('.')[1]) || 0;
  // }

  // @DataComputedProperty()
  // get isLatestInMajorVersionPublished() {
  //   return this.college.latestPublishedCurriculaByMajorVersionMap
  //     && this === this.college.latestPublishedCurriculaByMajorVersionMap[this.majorVersion.toString()];
  // }
  //
  // @DataComputedProperty()
  // get isLatestInMajorVersion() {
  //   return this.college &&
  //     this.college.latestCurriculaByMajorVersionMap &&
  //     this === this.college.latestCurriculaByMajorVersionMap[this.majorVersion.toString()];
  // }

  // @DataComputedProperty()
  // get versionLabel() {
  //   return this.version && this.version.replace('_', '.');
  // }
  //
  // @DataComputedProperty()
  // get publishedDate() {
  //   return this.data && this.data.publishedDate && new Date(this.data.publishedDate);
  // }

  // @DataComputedProperty()
  // get publishedLabel() {
  //   return this.isPublished ? 'Published' : 'Draft';
  // }

  // @DataComputedProperty()
  // get college() {
  //   return this.data && this.entityFor(College.byId(this.data.collegeUuid));
  // }

  // @DataComputedProperty()
  // get programs() {
  //   return this.entityFor(ProgramClientEntity.byCurriculum(this.uuid));
  // }
  //
  // @DataComputedProperty()
  // get sortedPrograms() {
  //   return this.programs.projectToArray(programs => sortBy(programs.toArray(), program => program.title));
  // }

  // @DataComputedProperty()
  // get activeProgramCount() {
  //   return this.programs.project(programs => programs.toArray().filter(p => p.isActive).length);
  // }
  //
  // getCoursesWithSlugs(slugs: string[]) {
  //   return this.sortedCourses.projectToArray(courses => courses.toArray().filter(c => slugs.includes(c.slug)));
  // }

  // activeEntityCount(type: PipelineEntityType) {
  //   switch (type) {
  //     case PipelineEntityType.PROGRAM:
  //       return this.activeProgramCount;
  //
  //     case PipelineEntityType.COURSE:
  //       return this.activeCourseCount;
  //   }
  // }

  //validationResult: CurriculumPublishabilityResult;

  // protected onDataChanged(): void {
  //   super.onDataChanged();
  // }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// routes
//   prependDocumentTypeTo(source: string[], docType: SourceDocumentType): string[] {
//     return [...source, docType];
//   }

  // get baseMergePreviewRoute() {
  //   return this.data && [...this.detailRoute, 'mergepreview'];
  // }
  //

  //
  // get importRoute() {
  //   return this.data && [...this.detailRoute, 'import'];
  // }

  //
  // get importCoursesErrorRoute() {
  //   return this.data && [...this.importRoute, 'courses', 'errors'];
  // }
  //
  // get bulkAmendCoursesRoute() {
  //   return this.data && [...this.detailRoute, 'amend', 'courses'];
  // }
  //
  // get bulkAmendCoursesErrorRoute() {
  //   return this.data && [...this.bulkAmendCoursesRoute, 'errors'];
  // }
  //

  //
  // get importProgramsErrorRoute() {
  //   return this.data && [...this.importRoute, 'programs', 'errors'];
  // }
  //
  //

  //
  // // COURSE GROUPS aka course lists
  // get courseGroupsRoute() {
  //   return this.data && [...this.detailRoute, 'course-lists'];
  // }
  //
  // // GEN ED AREAS
  // get genEdAreasRoute() {
  //   return this.data && [...this.detailRoute, 'gen-ed-areas'];
  // }
  //
  // // Missing / Sourceless records
  // get baseMissingRecordsRoute() {
  //   return this.data && [...this.detailRoute, 'missing-records'];
  // }

  // // TODO: Should be removed once Master records are being used over course / program entities

  //
  // preMergeRoute(sourceDocumentType: SourceDocumentType) {
  //   return this.data && [...this.basePremegeRoute, sourceDocumentType];
  // }
  //

  //
  //
  // entityBulkAmendRoute(type: PipelineEntityType): string[] {
  //   switch (type) {
  //     case PipelineEntityType.PROGRAM:
  //       return this.bulkAmendProgramsRoute;
  //
  //     case PipelineEntityType.COURSE:
  //       return this.bulkAmendCoursesRoute;
  //   }
  // }
  //


  // // modal routes
  // get editModalRoute() {
  //   return this.data && ['edit-curriculum-version', 'curriculum-years', this.version];
  // }

  // get createCourseForCurriculumModalRoute() {
  //   return this.data && ['create-course'];
  // }

  // get createProgramForCurriculumModalRoute() {
  //   return this.data && ['create-program'];
  // }



  // noticeReportRoute(type: PipelineEntityType): string[] {
  //   return this.data && [...this.detailRoute, 'report', type];
  // }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Entity actions
  //
  // createProgramForCurriculum(program: ProgramApiEntity): Promise<SaveEntityApiResponse> {
  //   return this.entityManager.performAction(createCreateProgramApiEntityAction(
  //     {
  //       curriculumUuid: this.uuid,
  //       program: program
  //     }));
  // }
  //
  // createCourseForCurriculum(course: CourseApiEntity): Promise<SaveEntityApiResponse> {
  //   throw new Error("Course creation is no longer supported");
  //   // return this.entityManager.performAction(createCreateCourseApiEntityAction(
  //   //   {
  //   //     curriculumUuid: this.uuid,
  //   //     course: course
  //   //   }));
  // }


  //
  // save() {
  //   return this.performActionReturningThis(createEditCurriculumApiEntityAction({
  //     curriculumUuid: this.uuid,
  //     notes: this.requiredData.notes
  //   }));
  // }

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Static Methods

  // static byId(curriculumUuid: string) {
  //   return new ClientEntityRequest(
  //     CurriculumInfoClientEntity,
  //     createCurriculumInfoApiEntityId({ curriculumUuid })
  //   );
  // }
  //
  // private static byCollegeUuidFunc = entitySetRequestFunc(
  //   CurriculumInfoClientEntity,
  //   'CurriculumsForCollegeSetApiEntityId'
  // );
  //
  // static byCollegeUuid(collegeUuid: string) {
  //   return this.byCollegeUuidFunc(createCurriculumsForCollegeSetApiEntityId({ collegeUuid }));
  // }
  //
  // private static byCollegeAndVersionId = entityAliasRequestFunc(
  //   CurriculumInfoClientEntity,
  //   'CurriculumByCollegeAndVersionAliasApiEntityId'
  // );
  //
  // static byCollegeAndVersion(collegeUuid: string, curriculumVersion: string) {
  //   return this.byCollegeAndVersionId(createCurriculumByCollegeAndVersionAliasApiEntityId({
  //     collegeUuid,
  //     curriculumVersion
  //   }));
  // }
  //
  // private static latestCurriculumByYearId = entityAliasRequestFunc(
  //   CurriculumInfoClientEntity,
  //   'LatestCurriculumByYearApiEntityId'
  // );
  //
  // static latestByCollegeAndYear(collegeUuid: string, year: number) {
  //   return this.latestCurriculumByYearId(createLatestCurriculumByYearApiEntityId({
  //     collegeUuid,
  //     year
  //   }));
  // }

}

export class CollegeCurriculumReportResult {
  constructor(
    private apiResult: CollegeReport
  ) {
  }

  get programReports(): CollegeReportProgramSummary[]{
    return this.apiResult.programSummaries;
  }

  get courseReports(): CollegeReportCourseSummary[]{
    return this.apiResult.courseSummaries;
  }
}

// export class CurriculumValidationResultClientEntity {
//
//   @CachedProperty()
//   get publishabilityResult(): CurriculumPublishabilityResult {
//     return this.apiResult;
//   }
//
//   constructor(
//     private apiResult: CurriculumPublishabilityResult,
//     private curriculumYear: CurriculumYear,
//     private entityManager: ClientEntityManager
//   ) {
//   }
//
//   // private resolveMasterRecords(recordId: string[]): MasterRecordSummaryClientEntity[] {
//   //   return recordId.map(courseId => this.entityManager.entityFor(MasterRecordSummaryClientEntity.byId(courseId)));
//   // }
//
//   // private resolveGenEdPatterns(patternIds: string[]): GenEdCurriculumClientEntity[] {
//   //   return patternIds.map(patternId => this.entityManager.entityFor(GenEdCurriculumClientEntity.byId(this.curriculumYear.uuid, patternId)));
//   // }
// }

// export interface MissingCourseRequisites {
//   course: CourseClientEntity;
//   requisites: string[];
// }
//
// export enum CourseRequisiteType {
//   CO, PRE
// }
