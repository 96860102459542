import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationStateService } from '../services/application-state.service';

@Injectable()
export class HasImportedCoursesDataGuard implements CanActivate {

  constructor(
    private appState: ApplicationStateService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean {
    console.log("has imported courses guard invoked");
    if (
      this.appState.curriculumYear &&
      this.appState.curriculumYear.hasImportedCourses()
    ) {
      return true;
    }
    else {
      if(this.appState.college.sources["STATE"] && this.appState.college.sources["STATE"]["@type"] == "COCISource") {
        // COCI import comes first, if there is one
        return this.router.createUrlTree(this.appState.curriculumYear.stateCoursesImportRoute());
      }
      else {
        // college local data comes first, if this isn't a COCI school
        return this.router.createUrlTree(this.appState.curriculumYear.collegeCoursesImportRoute());
      }
    }
  }
}
