import { HttpErrorResponse } from '@angular/common/http';

export function traceIdFromError(error: any): string | undefined {
  return error instanceof HttpErrorResponse && error.headers.get('traceid') || undefined;
}

export function createMsgFromErrorType(error: any, errorSource?: string) {
  const type =  error['@type'] || ''
  return `${errorSource} authoring has failed. ${type ? 'Error type: ' + error['@type'] : error}`;
}
