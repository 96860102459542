import {Injectable} from '@angular/core';
import {
  ActiveSourceRecordExcluded,
  CourseSchema,
  CourseTitleSameAsSubjectAndNumber,
  CurriculumEntityType,
  CyclicCourseRequisite,
  DataAddedManually,
  DataCorrectedManually,
  ErrorNotice,
  InactiveSourceRecordIncluded,
  InconsistentDataFromSource,
  InconsistentFields,
  InfoNotice,
  InvalidOptionalFields,
  InvalidRequiredData,
  InvalidRequiredFields, isCipCodeWithoutSOCs,
  isCourseDescriptionSameAsSubjectAndNumber,
  isCourseTitleSameAsSubjectAndNumber,
  isCyclicCourseRequisite,
  isDeprecatedPattern,
  isDescriptionSameAsTitle,
  isHasNoPublishableCourses,
  isInvalidOptionalData,
  isInvalidOptionalFields,
  isInvalidRequiredData,
  isInvalidRequiredFields,
  isMissingOptionalData,
  isMissingOptionalFields,
  isMissingRequiredData,
  isMissingRequiredFields,
  isMissingSystems,
  isNonPublishableAwardType,
  isNonPublishableRequisites,
  isSomeInactiveCourses,
  isSomeMissingCourses,
  isSomeNonPublishableCourses,
  isSpellingWarning,
  isStartsWithNumber,
  isSuspiciousDataFields,
  isTopCodeWithoutSOCs,
  isUnmergeableRecord,
  isUnresolvedDuplicates,
  isUnusuallyShort,
  ManuallyAddedFields,
  ManuallyCorrectedFields,
  MissingDataFromSource,
  MissingOptionalData,
  MissingOptionalFields,
  MissingRequiredData,
  MissingRequiredFields,
  MissingSystems,
  NonPublishableAwardType,
  NonPublishableRequisites,
  Notice,
  PipelineEntityType,
  ProgramSchema,
  SomeInactiveCourses,
  SomeMissingCourses,
  SomeNonPublishableCourses,
  SourceDuplicates,
  SourcesProvider,
  SourceSystem,
  SpellingWarning,
  UnresolvedDuplicates,
  UnusuallyShort,
  WarningNotice,
} from '../../api/datacleanuptool-api.model';
import {ApplicationStateService} from "./application-state.service";

export enum HumanReadableNoticeSeverity {
  CRITICAL = 'Critical',
  WARNING = 'Warning',
  INFO = 'Info'
}

export interface HumanReadableNotice {
  collegeAction: string;
  ourAction: string;
  message: string;
  severity: HumanReadableNoticeSeverity;
}

@Injectable()
export class MasterRecordNoticeService {
  private applicationState: ApplicationStateService;

  private errorNoticeMetaData: NoticeMetaData[] = [];
  private allNoticeMetaDataByType: Map<string, NoticeMetaData> = new Map<string, NoticeMetaData>();
  private allNonFieldNoticeMetaDataByType: Map<string, NoticeMetaData> = new Map<string, NoticeMetaData>();
  private warningNoticeMetaData: NoticeMetaData[] = [];
  private schemaFields: Map<string, FieldDisplay>;
  private infoNoticeMetaData: NoticeMetaData[] = [];

  constructor(applicationState: ApplicationStateService){
    this.applicationState = applicationState;
    this.initNoticeMetaData();
  }

  getNoticeMetaDataForType(type: string) : NoticeMetaData{
    if( this.allNoticeMetaDataByType.get(type) === undefined){
      throw new Error ("type " + type + " is undefined ");
    }
    return this.allNoticeMetaDataByType.get(type);
  }

  nonFieldErrorTypes() : NoticeMetaData[]{
    return this.errorNoticeMetaData.filter(notice => !notice.fieldNotice);
  }

  // infoTypes() : NoticeMetaData[] {
  //   return this.infoNoticeMetaData;
  // }

  nonFieldWarningTypes() : NoticeMetaData[]{
    return this.warningNoticeMetaData.filter(notice => !notice.fieldNotice);
  }

  initNoticeMetaData() {
    this.errorNoticeMetaData = [];
    if(cleanupErrorMetadata) {
      for (const cleanupNoticeKey in cleanupErrorMetadata) {
        if(cleanupErrorMetadata.hasOwnProperty(cleanupNoticeKey)){
          const cleanupNoticeMetaData: NoticeMetaData = {
            id: cleanupNoticeKey,
            label: cleanupErrorMetadata[cleanupNoticeKey].label,
            fieldNotice: cleanupErrorMetadata[cleanupNoticeKey].fieldNotice
          };
          this.errorNoticeMetaData.push(cleanupNoticeMetaData);
        }
      }
    }

    this.warningNoticeMetaData = [];

    for (const cleanupNoticeKey in cleanupWarningMetadata) {
      if(cleanupWarningMetadata.hasOwnProperty(cleanupNoticeKey)) {
        const cleanupNoticeMetaData: NoticeMetaData = {
          id: cleanupNoticeKey, label: cleanupWarningMetadata[cleanupNoticeKey].label,
          fieldNotice: cleanupWarningMetadata[cleanupNoticeKey].fieldNotice
        };
        this.warningNoticeMetaData.push(cleanupNoticeMetaData);
      }
    }

    this.infoNoticeMetaData = [];

    for (const cleanupNoticeKey in cleanupInfoMetadata) {
      if(cleanupInfoMetadata.hasOwnProperty(cleanupNoticeKey)) {
        const cleanupNoticeMetaData: NoticeMetaData = {
          id: cleanupNoticeKey, label: cleanupInfoMetadata[cleanupNoticeKey].label,
          fieldNotice: cleanupInfoMetadata[cleanupNoticeKey].fieldNotice
        };
        this.infoNoticeMetaData.push(cleanupNoticeMetaData);
      }
    }

    this.schemaFields = new Map();

    for (const field in programSchemaFields) {
      if (programSchemaFields.hasOwnProperty(field)) {
        this.schemaFields.set(field, programSchemaFields[field]);
        // it can be either ProgramSchema. or ProgramSchema- (when used as a map key)
        // TODO: fix this on server so its always '-', but need to handle and migrate legacy source data that has '.' in it first
        this.schemaFields.set(field.replace(".", "-"), programSchemaFields[field]);
      }
    }
    for (const field in courseSchemaFields) {
      if (courseSchemaFields.hasOwnProperty(field)) {
        this.schemaFields.set(field, courseSchemaFields[field]);
        // it can be either CourseSchema. or CourseSchema- (when used as a map key)
        // TODO: fix this on server so its always '-', but need to handle and migrate legacy source data that has '.' in it first
        this.schemaFields.set(field.replace(".", "-"), courseSchemaFields[field]);
      }
    }
    this.errorNoticeMetaData.forEach(
      notice => {
        this.allNoticeMetaDataByType.set(notice.id, notice);
        if(!notice.fieldNotice){
          this.allNonFieldNoticeMetaDataByType.set(notice.id, notice);
        }
      }
    );
    this.warningNoticeMetaData.forEach(
      notice => {
        this.allNoticeMetaDataByType.set(notice.id, notice);
        if(!notice.fieldNotice){
          this.allNonFieldNoticeMetaDataByType.set(notice.id, notice);
        }
      }
    );
    this.infoNoticeMetaData.forEach(
      notice => this.allNoticeMetaDataByType.set(notice.id, notice)
    );

  }

  getHumanReadableNotice(curriculumEntityType: CurriculumEntityType, cleanupNotice: Notice, field: string) : HumanReadableNotice {
    let entityTypeLabel;
    if(curriculumEntityType === CurriculumEntityType.PROGRAM){
      entityTypeLabel = "program";
    }
    else{
      entityTypeLabel = "course";
    }

    //let detail :string[] = [];
    const severity = this.isErrorNotice(cleanupNotice) ? HumanReadableNoticeSeverity.CRITICAL : this.isWarningNotice(cleanupNotice) ? HumanReadableNoticeSeverity.WARNING : HumanReadableNoticeSeverity.INFO;

    let sourceSystemLabel = cleanupNotice["sourceSystem"] && this.sourceSystemLabel(curriculumEntityType, cleanupNotice["sourceSystem"]);

    let message: string;
    let ourAction: string = 'TODO: our action';
    let collegeAction: string = 'TODO: college action';

    const cleanupNoticeType = cleanupNotice["@type"];
    switch(cleanupNoticeType){

      case "SomeMissingCourses": {
        const notice : SomeMissingCourses = cleanupNotice as SomeMissingCourses;
        message = notice.missingCourseSlugs.length + " course(s) can't be found at all ("+ notice.missingCourseSlugs.join(", ") +")";
        ourAction = '';
        collegeAction = "Add the courses to your curriculum or remove them from the General Education Pattern";
        break;
      }
      case "SomeNonPublishableCourses": {
        const notice : SomeNonPublishableCourses = cleanupNotice as SomeNonPublishableCourses;
        message = notice.nonPublishableCourseSlugs.length + " course(s) have errors ("+ notice.nonPublishableCourseSlugs.join(", ") +")";
        ourAction = '';
        collegeAction = "Fix the errors for these courses to your curriculum or remove them from the General Education Pattern";
        break;
      }
      case "SomeInactiveCourses": {
        const notice : SomeInactiveCourses = cleanupNotice as SomeInactiveCourses;
        message = notice.inactiveCourseSlugs.length + " course(s) are inactive ("+ notice.inactiveCourseSlugs.join(", ") +")";
        ourAction = '';
        collegeAction = "Activate the courses to your curriculum or remove them from the General Education Pattern";
        break;
      }
      // errors:
      case "InvalidRequiredData": {
        const notice : InvalidRequiredData =  cleanupNotice as InvalidRequiredData;
        message = notice.message;
        ourAction = '';
        if(field){
          collegeAction = 'Correct the ' + this.getFieldNameDisplay(field) + " in the " + entityTypeLabel + " template";  // TODO: template or eLumen..
        }
        break;
      }
      case "InvalidRequiredFields": {
        const notice : InvalidRequiredFields =  cleanupNotice as InvalidRequiredFields;
        message = "Unusable " + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ");
        ourAction = '';
        collegeAction='Correct the invalid data in the '+entityTypeLabel+' template (see details below)'; // TODO: template or eLumen.
        break;
      }
      case "MissingRequiredData": {
        const notice : MissingRequiredData =  cleanupNotice as MissingRequiredData;
        message = notice.message;
        ourAction = '';
        collegeAction = 'Add the ' + this.getFieldNameDisplay(field) + " to the " + entityTypeLabel + " template"; // TODO: template or eLumen.
        break;
      }
      case "MissingRequiredFields": {
        const notice : MissingRequiredFields =  cleanupNotice as MissingRequiredFields;
        message = "Missing " + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ");
        ourAction = '';
        collegeAction='Provide the missing data in the '+entityTypeLabel+' template (see details below)'; // TODO: template or eLumen.
        break;
      }
      case "NonPublishableAwardType": {
        const notice : NonPublishableAwardType =  cleanupNotice as NonPublishableAwardType;
        message = "Award type not currently supported in Program Pathways Mapper ";
        ourAction = 'Program will not be published';
        collegeAction='';
        break;
      }
      // TODO: UnmergeableRecord (when is it generated?)

      case "UnresolvedDuplicates" : {
        message = "Unresolved duplicate "+entityTypeLabel+"s in " + sourceSystemLabel;
        ourAction = "";
        collegeAction = "Remove the duplicate "+entityTypeLabel+"s from " + sourceSystemLabel;
        break;
      }

/////////// warnings:

      case "ActiveSourceRecordExcluded": {
        const notice : ActiveSourceRecordExcluded =  cleanupNotice as ActiveSourceRecordExcluded;
        message = "An active source record from " + sourceSystemLabel + " was excluded";
        ourAction = '';
        collegeAction = 'De-activate ' + entityTypeLabel + " in " + sourceSystemLabel;
        break;
      }
      case "CourseTitleSameAsSubjectAndNumber": {
        message = "Course Title is the same as Course Subject and Number";
        ourAction = '';
        collegeAction = "Add a proper course title to the courses template"; // TODO: or eLumen if they are eLumen
        break;
      }
      case "CourseDescriptionSameAsSubjectAndNumber": {
        message = "Course Description is the same as Course Subject and Number";
        ourAction = '';
        collegeAction = "Add a proper course description to the courses template"; // TODO: or eLumen if they are eLumen
        break;
      }
      case "DescriptionSameAsTitle": {
        message = "Description is the same as Title";
        ourAction = '';
        collegeAction = "Add a proper "+entityTypeLabel+" description to the "+entityTypeLabel+" template"; // TODO: or eLumen if they are eLumen
        break;
      }
      case "UnusuallyShort": {
        const notice : UnusuallyShort =  cleanupNotice as UnusuallyShort;
        message = notice.message;
        ourAction = '';
        collegeAction = ''; // TODO: or eLumen if they are eLumen
        break;
      }
      case "StartsWithNumber": {
        message = 'Learning Outcomes should not start with a number';
        ourAction = '';
        collegeAction = ''; // TODO: or eLumen if they are eLumen
        break;
      }
      case "DataAddedManually":{
        const notice: DataAddedManually<any, any> = cleanupNotice as DataAddedManually<any, any>;

        message = this.getFieldNameDisplay(field) + " was manually added";

        ourAction = "";
        collegeAction = "";

        break;
      }
      case "DataCorrectedManually":{
        const correctionApplied: DataCorrectedManually<any, any> = cleanupNotice as DataCorrectedManually<any, any>;
        message = this.getFieldNameDisplay(field) + " was manually corrected";

        ourAction = "";
        collegeAction = "";
        break;
      }
      case "DuplicatesAutoResolved" : {
        message = "Duplicate "+entityTypeLabel+"s in " + sourceSystemLabel;
        ourAction = "DCT chose the " + entityTypeLabel + " with the highest CCC Control Number";
        collegeAction = "Remove the duplicate "+entityTypeLabel+"s from " +  sourceSystemLabel;
        break;
      }
      case "InactiveSourceRecordIncluded": {
        const notice : InactiveSourceRecordIncluded =  cleanupNotice as InactiveSourceRecordIncluded;
        message = "An inactive source record from "+sourceSystemLabel+" was included";
        ourAction = '';
        collegeAction = 'Activate ' + entityTypeLabel + " in " + sourceSystemLabel;
        break;
      }
      case "InconsistentDataFromSource" : {
        const notice : InconsistentDataFromSource<any, any> =  cleanupNotice as InconsistentDataFromSource<any, any>;
        message = sourceSystemLabel + " has an inconsistent value";
        if(!notice.valueIsTruncated){
          message = message + " ('"+ notice.sourceValue.value+"')";
        }

        ourAction = "The " +notice.goldenValueSource.map(sourceSystem => this.sourceSystemLabel(curriculumEntityType, sourceSystem)).join(", ")+ " value";
        if(!notice.valueIsTruncated) {
          ourAction = ourAction + " ('"+notice.goldenValue.value+"')";
        }
        ourAction = ourAction + " for "+this.getFieldNameDisplay(field)+" was chosen instead of the "+sourceSystemLabel+" value";
        if(!notice.valueIsTruncated){
          ourAction = ourAction + " ('"+notice.sourceValue.value+"')";
        }
        collegeAction = "Correct the "+ this.getFieldNameDisplay(field);
        if(!notice.valueIsTruncated){
          collegeAction = collegeAction + " from '" + notice.sourceValue.value + "' to '" + notice.goldenValue.value + "'";
        }
        collegeAction = collegeAction + " in " + sourceSystemLabel;
        break;
      }
      case "InconsistentFields" : {
        message = "Some data for this program (" + (cleanupNotice as InconsistentFields)
          .fields
          .map(f=>this.getFieldNameDisplay(f)).join(", ") + ") is inconsistent or missing in the sources ";
        ourAction = "DCT has attempted to reconcile the data inconsistencies by helping you choose which data to use.  Please see the details of choices made in the 'Cleaned Data' section below.";
        collegeAction = "Review the Actions taken in DCT for this "+entityTypeLabel+" in the 'Cleaned Data' section below. If you approve, consider taking the 'Next Steps' to correct the inconsistent data at its source(s), and then re-import your data. No other action is required. If you have any concerns, first please make sure that your "+entityTypeLabel+" template data is correct and then contact us.";
        break;
      }
      case "InvalidOptionalData": {
        const notice : MissingOptionalData =  cleanupNotice as MissingOptionalData;
        message = notice.message;
        ourAction = this.getFieldNameDisplay(field) + " was excluded from the published " + entityTypeLabel;
        collegeAction = 'Optionally correct the ' + this.getFieldNameDisplay(field) + " in the " + entityTypeLabel + " template";
        break;
      }
      case "InvalidOptionalFields": {
        const notice : InvalidOptionalFields =  cleanupNotice as InvalidOptionalFields;
        message = "Unusable " + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ");
        ourAction = '';
        collegeAction = 'Optionally correct data in the '+entityTypeLabel+' template (see details below)';
        break;
      }
      case "ManuallyAddedFields":{
        // rollup notice
        const notice: ManuallyAddedFields = cleanupNotice as ManuallyAddedFields;
        message = "Manually added " + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ");
        ourAction = 'Data was added manually in DCT (usually taken from the print catalog)';
        collegeAction = "Review the Actions taken in DCT for this "+entityTypeLabel+" in the 'Cleaned Data' section below. If you approve, consider taking the 'Next Steps' to add or correct the missing/incorrect data at its source(s), and then re-import your data. No other action is required. If you have any concerns, first please make sure that your Programs Template data is correct and then contact us.";
        break;
      }
      case "ManuallyCorrectedFields":{
        // rollup notice
        const notice: ManuallyCorrectedFields = cleanupNotice as ManuallyCorrectedFields;
        message = "Some data for this "+entityTypeLabel+" was manually corrected (" + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ") + ")";
        ourAction = 'Data was corrected in DCT (usually taken from the print catalog)';
        collegeAction = "Review the Actions taken in DCT for this "+entityTypeLabel+" in the 'Cleaned Data' section below. If you approve, consider taking the 'Next Steps' to add or correct the missing/incorrect data at its source(s), and then re-import your data. No other action is required. If you have any concerns, first please make sure that your Programs Template data is correct and then contact us.";
        break;
      }
      case "MissingDataFromSource" : {
        const notice : MissingDataFromSource<any, any> =  cleanupNotice as MissingDataFromSource<any, any>;
        message = this.getFieldNameDisplay(field) + " is missing from " + sourceSystemLabel;
        if(notice.valueIsTruncated){
          // TODO: different verbiage for truncated
        }
        ourAction ="";
        collegeAction = "Add the "+ this.getFieldNameDisplay(field) + " to " + sourceSystemLabel;
        break;
      }
      case "MissingOptionalData": {
        const notice : MissingOptionalData =  cleanupNotice as MissingOptionalData;
        message = notice.message;
        ourAction = '';
        collegeAction = 'Optionally add the ' + this.getFieldNameDisplay(field) + " to the " + entityTypeLabel + " template";
        break;
      }
      case "MissingOptionalFields": {
        const notice : MissingOptionalFields =  cleanupNotice as MissingOptionalFields;
        message = "Missing " + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ");
        ourAction = '';
        collegeAction = 'Optionally provide the missing data in the '+entityTypeLabel+' template (see details below)';
        break;
      }
      // TODO: 'MissingOrInvalidCourses' | 'MissingOrInvalidPrograms' |

      case "SourceDuplicates" : {
        //SourceDuplicates: your source has duplicates. if neither of the other DuplicatesAutoResolved or UnresolvedDuplicates exist, we manually corrected
        // TODO: change this notice to be DuplicatesManuallyResolved. only put one duplicate notice: DuplicatesManuallyResolved, DuplicatesAutoResolved or UnresolvedDuplicates
        message = "Duplicate "+entityTypeLabel+"s in " + sourceSystemLabel;
        ourAction = "";
        collegeAction = "Remove the duplicate "+entityTypeLabel+"s from " + sourceSystemLabel;
        break;
      }
      case "SpellingWarning":{
        const spellingWarning: SpellingWarning<any> = cleanupNotice as SpellingWarning<any>;
        const fieldLabel  = this.getFieldNameDisplay(field);
        message = this.getDisplayLabel(cleanupNotice) + ": " + fieldLabel;

        break;
      }
      case "SuspiciousDataFields": {
        const notice : MissingOptionalFields =  cleanupNotice as MissingOptionalFields;
        message = "Possibly incorrect " + notice.fields.map(f => this.getFieldNameDisplay(f)).join(", ");
        ourAction = '';
        collegeAction = 'Review the suspicious data in the '+entityTypeLabel+' template (see details below)';
        break;
      }
      case "MissingSystems" : {
        const notice = cleanupNotice as MissingSystems;
        const entityTypeLabelCapitalized = entityTypeLabel.charAt(0).toUpperCase() + entityTypeLabel.slice(1);

        if(notice.missingSystems.indexOf(SourceSystem.COLLEGE) !== -1 && notice.missingSystems.indexOf(SourceSystem.STATE) !== -1){
          message =  `${entityTypeLabelCapitalized} that was manually added does not align with any ${entityTypeLabel} in ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.STATE)} or in ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.COLLEGE)}`;
          ourAction = "";
          collegeAction = `Add or activate this "+curriculumEntityType+" in ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.STATE)} or in ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.COLLEGE)}`;
        }
        else if(notice.missingSystems.indexOf(SourceSystem.COLLEGE) !== -1){
          message = `Not aligned with a ${entityTypeLabel} from ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.COLLEGE)}`;
          ourAction = "";
          collegeAction = `Add or activate this ${curriculumEntityType} in ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.COLLEGE)}`;
        }
        else if(notice.missingSystems.indexOf(SourceSystem.STATE) !== -1){
          message = `Not aligned with a ${entityTypeLabel} from ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.STATE)}`;
          ourAction = "";
          collegeAction = `Add or activate this ${curriculumEntityType} in ${this.sourceSystemLabel(curriculumEntityType, SourceSystem.STATE)}`;
        }

        break;
      }
      case "CyclicCourseRequisite" : {
        const notice = cleanupNotice as CyclicCourseRequisite;
        message = 'Prerequisites have a cycle: ';
        notice.requisiteCycle.forEach(requisiteCycle => {
          message +=  requisiteCycle.requisites.map(requisite => requisite.subjectAndNumber).join("=> ");
        });
        break;
      }
      case "NonPublishableRequisites" : {
        const notice = cleanupNotice as NonPublishableRequisites;
        message = 'Some course requisites cannot be published: ';
        if(notice.nonExistent.length > 0){
          notice.nonExistent.forEach(course => {
            message += course + " (missing), ";
          });
        }
        if(notice.inactive.length > 0){
          notice.inactive.forEach(course => {
            message += course + " (not active this year), ";
          });
        }
        if(notice.errors.length > 0){
          notice.errors.forEach(course => {
            message += course + " (has errors), ";
          });
        }
        ourAction = "Some " + this.getFieldNameDisplay(field) + " were invalid and excluded from the published " + entityTypeLabel;
        collegeAction = 'Optionally correct the ' + this.getFieldNameDisplay(field) + " in the " + entityTypeLabel + " template";

        break;
      }
      default: {
        message = this.getDisplayLabel(cleanupNotice);
      }
    }
    return {
      severity: severity,
      message: message,
      ourAction: ourAction,
      collegeAction: collegeAction,
    };
  }

  getHumanReadableFieldNoticesOfType(entityType: CurriculumEntityType,
                                     fieldNotices: { [p: string]: Notice[] },
                                     fieldName,
                                     noticeType : Notice['@type']): HumanReadableNotice[]{
    let matchingNotices : HumanReadableNotice[] = [];
    if(fieldNotices.hasOwnProperty(fieldName)) {
      matchingNotices = fieldNotices[fieldName]
        .filter(nonFieldNotice =>  nonFieldNotice["@type"] === noticeType)
        .map(notice =>
          this.getHumanReadableNotice(entityType, notice, fieldName)
        )
    }
    return matchingNotices;
  }

  getHumanReadableFieldNotices(entityType: CurriculumEntityType, fieldNotices: { [p: string]: Notice[] }, severity:HumanReadableNoticeSeverity) : Map<string, HumanReadableNotice[]>{
    const humanReableNoticesByField : Map<string, HumanReadableNotice[]> = new Map();

    for (const fieldName in fieldNotices){
      if(fieldNotices.hasOwnProperty(fieldName)) {
        const noticesOfSeverityForField = fieldNotices[fieldName].map(notice =>
          this.getHumanReadableNotice(entityType, notice, fieldName)
        ).filter(nonFieldNotice => nonFieldNotice.severity === severity);
        if (noticesOfSeverityForField.length > 0) {
          humanReableNoticesByField.set(
            this.getFieldNameDisplay(fieldName),
            noticesOfSeverityForField
          );
        }
      }
    }
    return humanReableNoticesByField;
  }

  private getDisplayLabel(notice: Notice) {
    return this.getNoticeMetaDataForType(notice["@type"]).label;
  }

  public getFieldSortOrder(fieldName: string) {
    if(!this.schemaFields.has(fieldName)) {
      throw new Error(fieldName + " is not a valid field name");
    }
    return this.schemaFields.get(fieldName).order;
  }

  public getFieldNameDisplay(fieldName: string) {
    if(!this.schemaFields.has(fieldName)) {
      throw new Error(fieldName + " is not a valid field name");
    }
    return this.schemaFields.get(fieldName).display;
  }

  get allTypes() : Map<string, NoticeMetaData>{
    return this.allNoticeMetaDataByType;
  }

  get allNonFieldTypes() : Map<string, NoticeMetaData>{
    return this.allNonFieldNoticeMetaDataByType;
  }

  // TODO: generate this
  isErrorNotice(notice: Notice) : boolean{
    return isMissingRequiredFields(notice)  ||
      isInvalidRequiredFields(notice) ||
      isUnmergeableRecord(notice) ||
      isUnresolvedDuplicates(notice) ||
      isInvalidRequiredData(notice) ||
      isMissingRequiredData(notice) ||
      isNonPublishableAwardType(notice) ||
      isHasNoPublishableCourses(notice);
  }

  isWarningNotice(notice: Notice){
    return isMissingOptionalFields(notice) ||
      isInvalidOptionalFields(notice) ||
      isMissingOptionalData(notice) ||
      isInvalidOptionalData(notice) ||
      isCourseTitleSameAsSubjectAndNumber(notice) ||
      isCourseDescriptionSameAsSubjectAndNumber(notice) ||
      isDescriptionSameAsTitle(notice) ||
      isUnusuallyShort(notice) ||
      isStartsWithNumber(notice) ||
      isTopCodeWithoutSOCs(notice) ||
      isCipCodeWithoutSOCs(notice) ||
      isSuspiciousDataFields(notice) ||
      isMissingSystems(notice) ||
      isSpellingWarning(notice) ||
      isNonPublishableRequisites(notice) ||
      isSomeInactiveCourses(notice) ||
      isSomeMissingCourses(notice) ||
      isSomeNonPublishableCourses(notice) ||
      isCyclicCourseRequisite(notice) ||
      isDeprecatedPattern(notice);
  }

  sourceSystemLabel(curriculumEntityType: CurriculumEntityType, sourceSystem: SourceSystem) {
    // Use a custom label
    if (curriculumEntityType in PipelineEntityType) {
      let entityType = PipelineEntityType[curriculumEntityType];
      let source = this.applicationState.college.sources[sourceSystem];
      return source && sourceSystemLabels[entityType][source["@type"]];
    } else if (sourceSystem == SourceSystem.INTERNAL) {
      return "Manually Entered/Corrected";
    }
  }
}

export const sourceSystemLabels: {[a in PipelineEntityType]: {[a in SourcesProvider["@type"]]: string}} = {
  COURSE: {
    COCISource: "COCI",
    CSUSupplementalSource: "State Gen Ed Database",
    ELumenSource: "eLumen Import",
    GenericCollegeSource: "Local Curriculum System",
    InternalSource: "Manually Entered/Corrected",
    PeopleSoftSource: "PeopleSoft",
    UCGenericCollegeSource: "Local Curriculum System"
  },
  PROGRAM: {
    COCISource: "COCI",
    CSUSupplementalSource: "State Degrees Database",
    ELumenSource: "eLumen Import",
    GenericCollegeSource: "Local Curriculum System",
    InternalSource: "Manually Entered/Corrected",
    PeopleSoftSource: "PeopleSoft",
    UCGenericCollegeSource: "Local Curriculum System"
  },
};

export interface NoticeMetaData {
  'id': string;
  'label' : string;
  'fieldNotice' : boolean;
}

const cleanupInfoMetadata: {[a in InfoNotice['@type']]: {label: string; fieldNotice: boolean}} = {
  'ActiveSourceRecordExcluded': { label: "Active source record excluded", fieldNotice: false},
  'InactiveSourceRecordIncluded': { label: "Inactive source record included", fieldNotice: false},
  'DataAddedManually': { label: "Data Added Manually", fieldNotice: true},
  'DataCorrectedManually': { label: "Data Corrected Manually", fieldNotice: true},
  'SourceDuplicates': { label: "Source Duplicates", fieldNotice: false},
  'DuplicatesAutoResolved': { label: "Auto Resolved Duplicates", fieldNotice: false},
  'InconsistentFields': { label: "Inconsistent Data in Import", fieldNotice: false},
  'ManuallyAddedFields': { label: "Manually Added Data", fieldNotice: false},
  'ManuallyCorrectedFields': { label: "Manually Corrected Data", fieldNotice: false},
  'InconsistentDataFromSource': { label: "Inconsistent Data", fieldNotice: true},
  'MissingDataFromSource': { label: "Missing Data in Import", fieldNotice: true},
  SomePublishableCourses : {label : "Some Publishable Courses", fieldNotice: false}
};

const cleanupWarningMetadata: {[a in WarningNotice['@type']]: {label: string; fieldNotice: boolean}} = {
  'MissingOptionalFields': { label: "Missing Optional Fields", fieldNotice: false},
  'InvalidOptionalFields': { label: "Invalid Optional Fields", fieldNotice: false},
  'MissingOptionalData': { label: "Missing Optional Data", fieldNotice: true},
  'InvalidOptionalData': { label: "Invalid Optional Data", fieldNotice: true},
  'TopCodeWithoutSOCs': { label: "Top Code has no Careers", fieldNotice: true},
  'CipCodeWithoutSOCs': { label: "Cip Code has no Careers", fieldNotice: true},
  'CourseTitleSameAsSubjectAndNumber': { label: "Title Same As Subject/Number", fieldNotice: true},
  'CourseDescriptionSameAsSubjectAndNumber': { label: "Description Same As Subject/Number", fieldNotice: true},
  'DescriptionSameAsTitle': { label: "Description Same As Title", fieldNotice: true},
  'UnusuallyShort': { label: "Unusually Short", fieldNotice: true},
  'StartsWithNumber': { label: "Starts with Number", fieldNotice: true},
  'SuspiciousDataFields': { label: "Suspicious Data Fields", fieldNotice: false},
  'MissingSystems': { label: "Unmatched", fieldNotice: false},
  'SpellingWarning': {label: 'Spellcheck Failed', fieldNotice: true},
  'NonPublishableRequisites' : {label: 'Redacted Requisites', fieldNotice: true},
  'DeprecatedPattern' : {label : 'Deprecated Pattern', fieldNotice: true},
  'CyclicCourseRequisite' : {label: "Course Requisite has Cycle", fieldNotice: false},
  SomeNonPublishableCourses : {label : "Some Courses with Errors", fieldNotice: false},
  SomeInactiveCourses : {label : "Some Inactive Courses", fieldNotice: false},
  SomeMissingCourses : {label : "Some Missing Courses", fieldNotice: false}
};

const cleanupErrorMetadata: {[a in ErrorNotice['@type']]: { label: string; fieldNotice: boolean}} = {
  'MissingRequiredFields': { label: "Missing Required Fields", fieldNotice: false},
  'InvalidRequiredFields': { label: "Invalid Required Fields", fieldNotice: false},
  'UnmergeableRecord': { label: "Missing Critical Identifying Information", fieldNotice: false},
  'UnresolvedDuplicates': { label: "Has Unresolved Duplicates", fieldNotice: false},
  'InvalidRequiredData': { label: "Invalid Required Data", fieldNotice: true},
  'MissingRequiredData': { label: "Missing Required Data", fieldNotice: true},
  'NonPublishableAwardType' : {label : "Unpublishable Award Type", fieldNotice: false},
  HasNoPublishableCourses : {label : "No Publishable Courses", fieldNotice: false}
};


interface FieldDisplay {
  display: string; order: number;
}

const programSchemaFields: {[a in ProgramSchema]: FieldDisplay} = {
  [ProgramSchema.title]: {display : "Title", order: 1},
  [ProgramSchema.awardType]: {display : "Award Type", order: 2},
  [ProgramSchema.cccControlNumber]: {display : "CCC Control Number", order: 3},
  [ProgramSchema.minUnits]: {display : "Min Units", order: 4},
  [ProgramSchema.maxUnits]: {display : "Max Units", order: 5},
  [ProgramSchema.description]: {display : "Description", order: 6},
  [ProgramSchema.topCode]: {display : "TOP6 Code", order: 7},
  [ProgramSchema.cipCode]: {display : "CIP Code", order: 8},
  [ProgramSchema.learningOutcomes]: {display : "Learning Outcomes", order: 9},
  [ProgramSchema.youtubeVideoId]: {display : "Youtube Video ID", order: 10},
  [ProgramSchema.tty]: {display : "Youtube Video Transcript", order: 11},
  [ProgramSchema.transferability]: {display : "Transferability", order: 12},
  [ProgramSchema.instructionMethod]: {display : "Instruction Method", order: 13},
  [ProgramSchema.additionalRequirements]: {display : "Additional Requirements", order: 14},
  [ProgramSchema.include]: { display: "Active", order: 15},
  [ProgramSchema.all]: {display : "Not Used", order: 16}, // TODO: eliminate this one from the generated TS
  [ProgramSchema.valueClassByField]: {display : "Not Used", order: 17}, // TODO: eliminate this one from the generated TS
  [ProgramSchema.metadata]: {display : "Not Used", order: 18}, // TODO: eliminate this one from the generated TS
  [ProgramSchema.cipCode]: {display : "CIP Code", order: 19}, // TODO: does this belong here?
  [ProgramSchema.badgeClassUrl]: {display : "Badge Class Url", order: 20},
};

const courseSchemaFields: {[a in CourseSchema]: FieldDisplay} = {

  [CourseSchema.subjectAndNumber]: {display : "Course Subject and Number", order: 1},
  [CourseSchema.title]: {display : "Title", order: 3},
  [CourseSchema.cccControlNumber]: {display : "CCC Control Number", order: 4},
  [CourseSchema.minUnits]: {display : "Min Units", order: 5},
  [CourseSchema.maxUnits]: {display : "Max Units", order: 6},
  [CourseSchema.description]: {display : "Description", order: 7},
  [CourseSchema.topCode]: {display : "TOP6 Code", order: 8},
  [CourseSchema.prerequisites]: {display : "Prerequisites", order: 9},
  [CourseSchema.prerequisiteText]: {display : "Advisory", order: 10},
  [CourseSchema.corequisites]: {display : "Corequisites", order: 11},
  [CourseSchema.labHours]: {display : "Lab Hours", order: 12},
  [CourseSchema.lectureHours]: {display : "Lecture Hours", order: 13},
  [CourseSchema.materialsFee]: {display : "Materials Fee", order: 14},
  [CourseSchema.transferability]: {display : "Transferability", order: 15},
  [CourseSchema.instructionMethod]: {display : "Instruction Method", order: 16},
  [CourseSchema.include]: { display: "Active", order: 17},
  [CourseSchema.all]: {display : "Not Used", order: 18}, // TODO: eliminate this one from the generated TS
  [CourseSchema.valueClassByField]: {display : "Not Used", order: 19}, // TODO: eliminate this one from the generated TS
  [CourseSchema.metadata]: {display : "Not Used", order: 20}, // TODO: eliminate this one from the generated TS
  [CourseSchema.courseNumber]: {display : "Not Used", order: 21}, // TODO: eliminate this one from the generated TS
  [CourseSchema.courseSubject]: {display : "Not Used", order: 22}, // TODO: eliminate this one from the generated TS
  [CourseSchema.errorThrowingWither]: {display : "Not Used", order: 23}, // TODO: eliminate this one from the generated TS
  [CourseSchema.errorThrowingGetter]: {display : "Not Used", order: 24}, // TODO: eliminate this one from the generated TS
  [CourseSchema.badgeClassUrls]: {display : "Badge Class URLs", order: 25},
};
