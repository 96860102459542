import { Component, EventEmitter, Input, Output } from '@angular/core';
import {Subject} from "rxjs";
import {PendingChangesModalComponent} from "../pending-changes-modal/pending-changes-modal.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {Router} from "@angular/router";

export interface OldPaginationObject extends PaginationObject{
  begin: number;
  end: number;
}

export interface PaginationObject {
  page: number;
}

export function createPaginationObject(
  page: number = PaginationComponent.firstPage
): PaginationObject {
  return {page: page};
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {

  modalRef: BsModalRef;

  static firstPage: number = 1;

  @Input() hasChanges: boolean;

  @Input() totalItems: number;

  @Input()
  set optionalStartPage(value: number) { this._currentPage = value; }

  @Input() set itemsPerPage(val: number) { this._itemsPerPage = val; }
  private _itemsPerPage: number;

  @Output() paginationObjectEmitter: EventEmitter<PaginationObject> = new EventEmitter<PaginationObject>();

  constructor(private modalService: BsModalService,
              private router: Router) {
  }

  get totalPages(): number {
    const page = Math.ceil(this.totalItems / this._itemsPerPage) ;
    return isNaN(page) ? 1 : page;
  }

  get firstPage(): number {
    return PaginationComponent.firstPage;
  }

  get lastPage(): number {
    const page = this.totalItems < this._itemsPerPage ? 1 : Math.ceil( this.totalItems / this._itemsPerPage );
    return isNaN(page) ? 1 : page;
  }

  set currentPage(value: number) {
    if (this.hasChanges) {
      this.openPendingChangesModal(value);
    } else {
      this.changePage(value);
    }
  }

  private openPendingChangesModal(value: number) {
    const confirm = new Subject<boolean>();
    this.modalRef = this.modalService.show(PendingChangesModalComponent, {ignoreBackdropClick: true, backdrop: false, keyboard: false});
    this.modalRef.content.confirm = confirm;
    const sub = confirm.subscribe(result => {
      if (result) {
        this.changePage(value);
        sub.unsubscribe();
      }
    });
  }

  private changePage(value: number) {
    this._currentPage = value;

    if ( this.currentPage > this.lastPage) {
      this.currentPage = this.lastPage;
    }

    if ( this.currentPage <  1) {
      this.currentPage = this.firstPage;
    }

    //this.navigateToPage();
    this.emitPaginationObject();
  }

  // private navigateToPage() {
  //   if (this.currentPage === 1) {
  //     // If routing back to page 1, clear the query param.
  //     this.router.navigate([]);
  //   } else {
  //     this.router.navigate([],{ queryParams: { page: this.currentPage } });
  //   }
  // }

  get currentPage(): number { return this._currentPage; }
  private _currentPage: number = this.firstPage;

  next() {
    this.currentPage++;
  }

  previous() {
    this.currentPage--;
  }

  first () {
    this.currentPage = this.firstPage;
  }

  last () {
    this.currentPage = this.lastPage;
  }

  /** example:

   22 records
   5 per page

   <<   1   ,   2   ,   3   ,   4   ,   5     >>
   [5]     [5]     [5]      [5]     [2]

   */

//   private beginning() {
//     return (this.currentPage - 1 ) * this._itemsPerPage;
// }
//
//   private end () {
//     const end =  ( this._itemsPerPage - 1 ) + this.beginning();
//
//     return end < this.totalItems
//               ? end
//               : this.beginning() + this.modulo();
//   }

  private modulo() {
    return Math.floor(this.totalItems % this._itemsPerPage);
  }

  private emitPaginationObject () {
    this.paginationObjectEmitter.emit(
      createPaginationObject(this.currentPage)
    );
  }

}
