import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {Observable} from "rxjs";
import {PaginationObject} from "../pagination/pagination.component";
import {
  FindPickableCoursesResponse,
  PickableCourse,
} from "../../../api/datacleanuptool-api.model";
import {BaseRoutableComponent} from "../base-routable.component";
import {ServiceManager} from "../../services/service-manager";
import {ActivatedRoute, Router} from "@angular/router";
import {OnDestroy} from "@angular/core/src/metadata/lifecycle_hooks";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-course-picker',
  templateUrl: './course-picker.component.html'
})
export class CoursePickerComponent extends BaseRoutableComponent implements OnInit, OnDestroy{

  modalRef: BsModalRef;
  @ViewChild('template') template;

  readonly itemsPerPage = 10;
  searchResults$: Observable<FindPickableCoursesResponse>;

  private _searchTerm: string;
  private _showNotOffered = false;
  private _showNonPublishable = true;
  private _pickedCourses:PickableCourse[];
  private _page: number = 1;

  constructor(
    protected readonly serviceManager: ServiceManager,
    protected activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,
  ) {
    super(serviceManager, activatedRoute);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {ignoreBackdropClick: true, backdrop: false, keyboard: false, class: 'modal-lg'});
  }

  closeModal() : void {
    this.modalRef.hide();

    this.applicationState.coursesPicked(this._pickedCourses);

    this.router.navigate(
      [
        './',
        // NOTE: No relative-path navigation is required because we are accessing
        // the parent's "activatedRoute" instance. As such, this will be executed
        // as if we were doing this in the parent view component.
        {
          outlets: {
            coursePickerModal: null
          }
        }
      ],
      {
        relativeTo: this.route.parent // <--- PARENT activated route.
      }
    );
  }

  ngOnInit(): void {
    this.applicationState.initiallyPickedCourses$
      .pipe(takeUntil(this.onNgDestroy))
      .subscribe(
      pickedCourses => {
        if (pickedCourses) {
            this._pickedCourses = pickedCourses;
            this._searchTerm = undefined;
            this._showNotOffered = false;
            this._showNonPublishable = true;
            this.updatePaginatedResults();
          }
        }
      );

    setTimeout(() => {
      this.openModal(this.template);
    });
  }

  get pickedCourses() : PickableCourse[] | undefined{
    return this._pickedCourses;
  }

  get showNotOffered(): boolean {
    return this._showNotOffered; }

  set showNotOffered(value: boolean) {
    this._showNotOffered = value;
    this.resetToPageOne();
    this.updatePaginatedResults();
  }

  get showNonPublishable(): boolean {
    return this._showNonPublishable; }

  set showNonPublishable(value: boolean) {
    this._showNonPublishable = value;
    this.resetToPageOne();
    this.updatePaginatedResults();
  }

  searchChanged(searchParams: string) {
    this._searchTerm = searchParams;
    this.resetToPageOne();
    this.updatePaginatedResults();
  }

  private resetToPageOne() {
    this._page = 1;
  }

  get page(){
    return this._page;
  }

  setPage(page: number) {
    this._page = page;
    this.updatePaginatedResults();
  }

  onPaginationChange(paginationObject: PaginationObject) {
    this.setPage(paginationObject.page);
  }

  private updatePaginatedResults() {
    this.searchResults$ = this.curriculumYear.pickableCourses$(
      this.itemsPerPage,
      this._searchTerm,
      this._showNonPublishable,
      this._showNotOffered,
      this._page);
  }

  isSelected(course: PickableCourse) {
    return Array.from(this._pickedCourses)
      .some(v => v.slug === course.slug);
  }

  addCourse(course: PickableCourse) {
    this._pickedCourses.push(course);
  }

  removeCourse(course: PickableCourse) {
    this._pickedCourses.filter(it => it.slug !== course.slug);
  }

}
