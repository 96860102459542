import { ActivatedRoute, Router } from '@angular/router';
import { BaseRoutableComponent } from '../base-routable.component';
import {
  BulkAmendType,
  PipelineEntityType
} from '../../../api/datacleanuptool-api.model';
import { pluralLabel } from '../../../api/extensions';
import { ServiceManager } from '../../services/service-manager';

export abstract class BulkEditBaseComponent extends BaseRoutableComponent {

  public static PROGRAM_BULK_EDIT_TEMPLATE_MAPPING = {
    "learning-outcomes": BulkAmendType.PROGRAM_LEARNING_OUTCOMES,
    "active": BulkAmendType.PROGRAM_ACTIVE,
    "descriptions": BulkAmendType.PROGRAM_DESCRIPTIONS,
    "youtube": BulkAmendType.PROGRAM_YOUTUBE_VIDEOS,
    "badgeClassUrl" : BulkAmendType.PROGRAM_BADGE_CLASS
  };

  public static COURSE_BULK_EDIT_TEMPLATE_MAPPING = {
    "units": BulkAmendType.COURSE_UNITS,
    "active": BulkAmendType.COURSE_ACTIVE,
    "descriptions": BulkAmendType.COURSE_DESCRIPTIONS,
    "requisites": BulkAmendType.COURSE_REQUISITES
  };

  amendTarget: string;

  // selectedType: BulkAmendType = null;
  importWasSuccessful?: boolean = null;

  entityType: PipelineEntityType;
  PipelineEntityType = PipelineEntityType;
  field: string;

  constructor(
    protected router: Router,
    serviceManager: ServiceManager,
    activatedRoute: ActivatedRoute,
  ) {
    super(serviceManager, activatedRoute);
  }

  init(field: string) {
    this.entityType = this.routeData.entityType;
    this.amendTarget = pluralLabel(this.entityType);
    this.field = field;
  }

  onImportCompleted(success: boolean) {
    if (success) {
      this.importWasSuccessful = true;
      this.router.navigate(
        this.curriculumYear.entityBulkAmendSuccessRoute(this.entityType, this.field)
      );
    } else {
      this.importWasSuccessful = false;
      this.router.navigate(
        this.curriculumYear.entityBulkAmendErrorRoute(this.entityType, this.field)
      );
    }
  }

}

