import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ApplicationStateService } from '../services/application-state.service';

@Injectable()
export class HasImportedCollegeProgramsDataGuard implements CanActivate {

  constructor(
    private appState: ApplicationStateService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean {
    const params = next.params;
    if (
      this.appState.curriculumYear &&
      this.appState.curriculumYear.hasImportedPrograms() &&
      this.appState.curriculumYear.hasImportedCollegePrograms()
    ) {
      return true;
    }
    else {
      return this.router.createUrlTree(this.appState.curriculumYear.collegeProgramsImportRoute());
    }
  }
}
