import { Component, OnInit, Input } from '@angular/core';
import { OverviewCard, OverviewCardData } from '../../../model/overview-card';

@Component({
  selector: 'app-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent implements OnInit {

  primary: OverviewCardData;
  secondaryRight: OverviewCardData;
  secondaryLeft: OverviewCardData;


  @Input() cardData: OverviewCard;

  constructor() { }

  ngOnInit() {
    this.primary = this.cardData.primary;
    this.secondaryRight = this.cardData.secondaryRight;
    this.secondaryLeft = this.cardData.secondaryLeft;
  }

}
