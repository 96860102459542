import { Component } from '@angular/core';
import { OverviewCard } from '../shared/model/overview-card';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html'
})
export class AdminDashboardComponent {

  collegeOverviewCard: OverviewCard;
  userOverviewCard: OverviewCard;

  constructor () {
    this.collegeOverviewCard = {
      primary: { label: 'Colleges', value: 1 },
      secondaryRight: { label: 'Datasets', value: 40 },
      secondaryLeft: { label: 'Drafts', value: 16 }
    }

    this.userOverviewCard = {
      primary: { label: 'Users', value: 1 },
      secondaryRight: { label: 'System Admins', value: 1 },
      secondaryLeft: { label: 'College Admins', value: 0 }
    };

  }

}
