import {Component, Input} from '@angular/core';
import {ServiceManager} from '../../services/service-manager';
import {
  DuplicateRow,
  ImportNotice,
  InvalidCollege, PipelineEntityType,
  SourceSystem,
  ValueNotImportable
} from '../../../api/datacleanuptool-api.model';
import {BaseComponent} from "../base.component";
import {ParseError} from "../../entity-system/entities/curriculum-year";

@Component({
  selector: 'app-import-error',
  templateUrl: './import-error.component.html'
})
export class ImportErrorComponent extends BaseComponent{

  @Input() errors: ParseError;

  constructor(
    protected readonly serviceManager: ServiceManager,
  ) {
    super(serviceManager);
  }

  humanReadableImportNotice(importNotice: ImportNotice): string {
    let message: string;
    switch(importNotice["@type"]){
      case "DuplicateRow":{
        let notice: DuplicateRow = importNotice as DuplicateRow;
        message = "Duplicate rows: " + notice.duplicateRowNumbers;
        break;
      }
      case "InvalidCollege": {
        let notice: InvalidCollege = importNotice as InvalidCollege;
        message = "Imported data doesn't appear to belong to this college " + notice.message;
        break;
      }
      case "ValueNotImportable":{
        let notice: ValueNotImportable = importNotice as ValueNotImportable;
        message = "Field  '" + notice.field + "' has a problem that is preventing import: " + notice.message;
        break;
      }
      default :{
        message = importNotice["@type"];
        break;
      }
    }
    return message;
  }
}
