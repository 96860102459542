import { Component} from '@angular/core';
import {Subject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-pending-changes-modal',
  templateUrl: './pending-changes-modal.component.html',
  styleUrls: ['./pending-changes-modal.component.scss']
})
export class PendingChangesModalComponent {

  confirm: Subject<boolean>;

  constructor(private _bsModalRef: BsModalRef) {}

  closeModal() {
    this._bsModalRef.hide();
  }

  public confirmAction(value: boolean): void {
    this.confirm.next(value);
    this._bsModalRef.hide();
    this.confirm.complete();
  }
}
