import { Component, OnInit } from '@angular/core';
import { CurriculumYearHistoryResponse } from '../../../api/datacleanuptool-api.model';
import { ServiceManager } from '../../../shared/services/service-manager';
import { Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-version-history',
  templateUrl: './version-history.component.html',
  styleUrls: ['./version-history.component.scss']
})
export class VersionHistoryComponent implements OnInit {
  curriculumYearHistory$: Observable<CurriculumYearHistoryResponse>;

  constructor(
    private serviceManager: ServiceManager,
    private bsModalRef: BsModalRef,
  ) {}

  ngOnInit() {
    this.curriculumYearHistory$ = this.serviceManager.applicationState.curriculumYear.getVersionHistory$();
  }

  closeModal() : void {
    this.bsModalRef.hide();
  }

  dateForFormat(publishedDateStringValue: string) {
    const date = new Date(publishedDateStringValue);
    return date;
  }
}
