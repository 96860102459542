import {
  CollegeApiEntity, isCOCISource, newFindCurriculumYearsForCollegeAction, COCISource,
} from '../../../api/datacleanuptool-api.model';
import {
  ClientEntityManager,
} from '../client-entity-manager.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class College{

  constructor(
    private _apiEntity: CollegeApiEntity,
    private _entityManager: ClientEntityManager
  ) {

  }

  get cociCollegeInfo(): COCISource | undefined {
    if (isCOCISource(this.sources.STATE)) {
      return this.sources.STATE;
    }
    return null;
  }

  get uuid() { return this._apiEntity.id; }

  get slug() { return this._apiEntity.slug; }

  get name() { return this._apiEntity.name; }

  get sources() { return this._apiEntity.sources; }

  get sourceLabels() { return this._apiEntity.sourceSystemLabels; }

  // set name(name: string) { this.mutateData(data => data.name = name); }

  // routes
  get detailRoute() {return['/colleges', this.slug]; }

//
//   // Original
//   // @DataComputedProperty()
//   // get sortedCurriculaByVersionDesc() {
//   //   return this.curricula.projectToArray(curricula => sortBy(curricula.toArray(), curriculum => curriculum.version, 'descending'));
//   // }
//
//   @DataComputedProperty()
//   get sortedCurriculaByVersionDesc() {
//     return this.curricula.projectToArray(curricula => {
//       const uniqueCurricula = {};
//       const sorted = sortBy(curricula.toArray(), curriculum => curriculum.version, 'descending');
//         sorted.forEach(s => {
//           if (isNullOrUndefined(uniqueCurricula[s.majorVersion])) {
//             uniqueCurricula[s.majorVersion] = {};
//             uniqueCurricula[s.majorVersion].version = 0;
//             uniqueCurricula[s.majorVersion].entity = s;
//           }
//
//           if ( s.minorVersion > uniqueCurricula[s.majorVersion].version) {
//             uniqueCurricula[s.majorVersion].version = s.minorVersion;
//             uniqueCurricula[s.majorVersion].entity = s;
//           }
//
//         });
//
//       return Object.values(uniqueCurricula).map(c => c['entity']);
//     });
//   }

  curriculumYears$(): Observable<number[]> {
    return this._entityManager.performApiAction$(newFindCurriculumYearsForCollegeAction({collegeSlug: this.slug}))
      .pipe(map (it => it.years));
  }

  mostRecentCurriculumYearPriorTo$(yearPriorTo: number): Observable<number | undefined> {
    return this.curriculumYears$().pipe(
      map(curriculumYears => curriculumYears.filter(
        year => year < yearPriorTo
      )),
      map (curriculumYears => {
        if (curriculumYears.length) {
          return Math.max.apply(Math, curriculumYears);
        }
        else {
          return undefined;
        }
      })
    );
  }


  //
//   @CachedProperty()
//   get latestPublishedCurriculaByMajorVersionMap() {
//     return this.curricula.project(curricula => {
//       const latestPublishedByMajor: {[majorVersion: string]: CurriculumInfoClientEntity} = {};
//
//       curricula.toArray().forEach(c => {
//         if (!latestPublishedByMajor[ c.majorVersion.toString() ] || latestPublishedByMajor[ c.majorVersion.toString() ].minorVersion < c.minorVersion) {
//           if (c.isPublished ) {
//             latestPublishedByMajor[ c.majorVersion.toString() ] = c;
//           }
//         }
//       });
//
//       return latestPublishedByMajor;
//     });
//   }
//
//   @CachedProperty()
//   get latestCurriculaByMajorVersionMap() {
//     return this.curricula.project(curricula => {
//       const latestByMajor: {[majorVersion: string]: CurriculumInfoClientEntity} = {};
//
//       curricula.toArray().forEach(c => {
//         if (! latestByMajor[c.majorVersion.toString()] || latestByMajor[c.majorVersion.toString()].minorVersion < c.minorVersion) {
//           latestByMajor[c.majorVersion.toString()] = c;
//         }
//       });
//
//       return latestByMajor;
//     });
//   }
//
// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//   // Entity actions
//
//   async addRevision(year: number, notes?: string) {
//     const response = await this.entityManager.performAction(createAddCurriculumRevisionApiEntityAction({
//       collegeUuid: this.uuid,
//       year, notes
//     }));
//
//     // TODO:
//     if (isCurriculumYearMissing(response)) {
//
//     // TODO: handle / display errors for reals
//       console.log('[[ ERROR ]] CollegeInfoClient :: addRevision => "isCurriculumYearMissing" ', response);
//     }
//     else if (isLastRevisionNotPublished(response)) {
//       // TODO: handle / display errors for reals
//       console.log('[[ ERROR ]] CollegeInfoClient :: addRevision => "LastRevisionNotPublished" ', response);
//     }
//     else if (isRevisionAdded(response)) {
//       // TODO: handle / display errors for reals
//       console.log('** SUCCESS ** CollegeInfoClient :: addRevision => "RevisionAdded" ', response);
//     }
//   }

  // addNewYear(
  //   year: number,
  //   copyCurriculumUuid?: string,
  //   notes?: string
  // ): Promise<AddCurriculumYearApiResponse> {
  //   return this.entityManager.performAction(createAddCurriculumYearApiEntityAction({
  //       collegeUuid: this.uuid,
  //       year: year,
  //       copyCurriculumUuid: copyCurriculumUuid,
  //       notes: notes
  //   }));
  // }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Static Methods

  // static byId(collegeUuid: string) {
  //   return new DetachedClientEntityRequest(
  //     College,
  //     createCollegeId({ uuid : collegeUuid })
  //   );
  // }

}
