export type SortOrder =  'ascending' | 'descending';

export function sortBy<T>(entities: T[], value: (T) => any, sortOrder: SortOrder = 'ascending'  ) {
  return entities.sort((aObject, bObject) => {
    const a = value(aObject);
    const b = value(bObject);

    switch (sortOrder) {

      case 'ascending':
        return a === b ? 0 : a < b ? - 1 : 1;

      case 'descending':
        return a === b ? 0 : a > b ? - 1 : 1;

      default:
        return a === b ? 0 : a < b ? - 1 : 1;
    }
  });
}
