import { Directive, ViewContainerRef, TemplateRef, Input, ComponentFactoryResolver } from '@angular/core';
// import { LoadingDotsComponent } from '../components/loading-dots.component';
// import { LoadingErrorComponent } from '../components/loading-error.component';
import { LoadingMessageComponent } from '../components/loading-message/loading-message.component';

/**
 *
 *
 * ### Example
 *
 * ```
 * <div *awaitPromises='[promise1, promise2]'>
 *     <!-- content to be displayed when promise1 and promise2 are complete -->
 *     <!-- otherwise LoadingDotComponent appears -->
 * </div>
 *
 */


@Directive({
  selector: '[awaitPromises]'
})
export class AwaitPromises {
  currentPromise: Promise<any>;
  indicatorSubModule: string;

  constructor(
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>,
    private componentResolver: ComponentFactoryResolver
  ) {
  }

  // I believe the last loaded promise type should be an object with a loadedPromise property that contains an array of promise.
  @Input() set awaitPromises(newValue: Array<Promise<any> | { loadedPromise: Promise<any> }> | Promise<any> | { loadedPromise: Promise<any> }) {
    let newPromises: Array<Promise<any>> = [];

    if (Array.isArray(newValue)) {
      newPromises = newValue
        .filter(p => !!p)
        .map((value: any) => ('loadedPromise' in value) ? value[ 'loadedPromise' ] : value);

    } else if (newValue && 'loadedPromise' in newValue) {
      newPromises = [ (newValue as any)[ 'loadedPromise' ] ];
    } else if (newValue) {
      newPromises = [ newValue as any ];
    }

    if (newPromises.length > 0) {
      // promises to wait for, display loading dots
      this.showLoadingAnimation();
      this.currentPromise = Promise.all(newPromises).then(
        () => this.showTemplate(),
        //error => this.showError(error)
      );
    } else {
      // no promises given, display template
      this.showTemplate();
    }
  }

  @Input() set subModule(newSubModule: string) {
    this.indicatorSubModule = newSubModule;
  }

  // private showError(error: any) {
  //
  //   const factory = this.componentResolver.resolveComponentFactory(LoadingErrorComponent);
  //

  private showTemplate(): void {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.template);

  }

  //   this.viewContainer.clearSelectedRecord();
  //
  //   const componentRef = this.viewContainer.createComponent(factory);
  //   componentRef.instance.className = this.indicatorSubModule;
  //   componentRef.instance.errorMessage = error[ 'message' ] || error;
  // }

  private showLoadingAnimation(): void {

    const factory = this.componentResolver.resolveComponentFactory(LoadingMessageComponent);

    this.viewContainer.clear();
    const componentRef = this.viewContainer.createComponent(factory);
    if ( this.indicatorSubModule ) {
      componentRef.instance.subModule = this.indicatorSubModule;
    }
  }
}
