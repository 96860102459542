import { Injectable } from '@angular/core';
import {ReplaySubject} from 'rxjs';

export enum Breakpoint {
  MOBILE = 'MOBILE', // > 767px
  TABLET = 'TABLET', //   768px - 1023px
  LAPTOP = 'LAPTOP', //   1024px - 1439px
  HD = 'HD'          // < 1440px
}

@Injectable()
export class BreakpointService {

  private activeBreakpointSource = new ReplaySubject<Breakpoint>();
  activeBreakpoint$ = this.activeBreakpointSource.asObservable();

  constructor() { }

  announceActiveBreakpointChange(breakpoint: Breakpoint) {
    this.activeBreakpointSource.next(breakpoint);
  }

}
