import {Component} from '@angular/core';
import {BaseCurriculumEntityTableComponent} from "../../base-curriculum-entity-table.component";
import {ApplicationStateService} from "../../../../shared/services/application-state.service";
import {ExportSearchResultsService} from "../../../../shared/services/export-search-results.service";
import {
  HumanReadableNoticeSeverity,
  MasterRecordNoticeService
} from "../../../../shared/services/master-record-notice.service";
import {
  Notice,
  PaginatedFilteredCourseSearchResults, CurriculumEntityType
} from '../../../../api/datacleanuptool-api.model';

@Component({
  selector: 'app-course-table',
  templateUrl: './course-table.component.html'
})

export class CourseTableComponent extends BaseCurriculumEntityTableComponent<PaginatedFilteredCourseSearchResults>{

  constructor(
    protected applicationStateService: ApplicationStateService,
    protected recordFilterService: ExportSearchResultsService,
    protected masterRecordNoticeService: MasterRecordNoticeService,
  ) {
    super(applicationStateService, recordFilterService, masterRecordNoticeService);
  }

  getHumanReadableNotices(nonFieldNotices: Notice[], noticeSeverity: HumanReadableNoticeSeverity) {
    return super.getHumanReadableNoticesForEntityType(CurriculumEntityType.COURSE, nonFieldNotices, noticeSeverity);
  }
}
