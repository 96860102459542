import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[appAwaitIntersection]'
})
export class AwaitIntersectionDirective implements AfterViewInit {

  @Input() intersectWith?: Element;

  @Input() visibilityThreshold?: number | number[];

  @Input() intersectWithMargin?: string;

  @Input() singleUse?: Boolean = false;

  @Output() intersected: EventEmitter<IntersectionObserverEntry> = new EventEmitter<IntersectionObserverEntry>();

  private _intersectionObserver?: IntersectionObserver;

  constructor( private _element: ElementRef ) { }

  public ngAfterViewInit () {
    const options: IntersectionObserverInit = {
      ... (this.intersectWith && {root: this.intersectWith} ),
      ... (this.intersectWithMargin && {rootMargin: this.intersectWithMargin} ),
      ... (this.visibilityThreshold && {threshold: this.visibilityThreshold} )
    };

    const intersectionObserverCallback = (entries: IntersectionObserverEntry[] ) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (this.isIntersecting(entry)) {
          this.intersected.emit(entry);
          if ( this.singleUse ){
            this.stopObserving();
          }
        }
      });
    };

    this._intersectionObserver = new IntersectionObserver(entries => {
      intersectionObserverCallback( entries ); }, options );

    this._intersectionObserver.observe(this._element.nativeElement);
  }


  private stopObserving() {
    this._intersectionObserver.unobserve(this._element.nativeElement);
    this._intersectionObserver.disconnect();
  }

  // typescript interface doesn't seem to included 'IntersectionObserverEntry'...
  // casting entry to <any> for the intersection check
  private isIntersecting (entry: IntersectionObserverEntry) {
    return (<any>entry).isIntersecting && entry.target === this._element.nativeElement;
  }

}
