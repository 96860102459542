
import {ServiceManager} from "../services/service-manager";
import {Subject} from "rxjs";
import {OnDestroy} from "@angular/core";

/**
 * Base class for routable components (views) in the application. Uses the [ServiceManager] to provide easy access to various
 * managers, and has some shortcut delegates for common operations.
 */
export abstract class BaseComponent implements OnDestroy{

  // emits to do cleanup all subscriptions - as long as they were piped with .pipe( takeUntil(this.ngUnsubscribeOnDestroy)) as the *final* command
  protected onNgDestroy = new Subject();

  protected constructor(
    protected readonly serviceManager: ServiceManager,
  ) {
    // // Always call our own private destroy() method to clean up subscriptions, etc, after the subclass ngOnDestroy's method (if any).
    // // This is a work around for the lack of a @CallSuper equivalent (ie requiring child method to make a super invocation)
    const originalOnDestroy = this.ngOnDestroy;
    this.ngOnDestroy = () => {
      originalOnDestroy.apply(this);
      this.destroy();
    };
  }

  ngOnDestroy(): void {
    // Do nothing.  this can be overridden with the child's ngOnDestroy.
    // If the child calls super.ngOnDestroy(), nothing happens here.
    //
    // This class's private destroy() method to clean up subscriptions, etc, is always called
    // immediately after the child's ngOnDestroy (see constructor).
  }

  // this destroy() is always called after after the sub component's own ngOnDestroy method invocation (see constructor)
  private destroy() {
    // cleanup all subscriptions created by this component
    // as long as those subscriptions were piped with .pipe( takeUntil(this.onNgDestroy)) as the *final* command
    this.onNgDestroy.next();
    this.onNgDestroy.complete();
  }

  get college(){
    return this.applicationState.college;
  }

  get curriculumYear(){
    return this.applicationState.curriculumYear;
  }

  get entityManager() {
    return this.serviceManager.entityManager;
  }

  get logger() {
    return this.serviceManager.logger;
  }

  get applicationState () {
    return this.serviceManager.applicationState;
  }

  get headerTitle() {
    return this.serviceManager.headerTitle;
  }

  get toastService(){
    return this.serviceManager.toastService;
  }

}
