import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html'
})
export class TabComponent implements OnInit {

  get label(): string { return this._label; }

  @Input()
  set label(value: string) { this._label = value; }
    private _label: string;

  get selected(): boolean { return this._selected; }

  set selected(value: boolean) { this._selected = value; }
    private _selected: boolean;

  constructor() { }

  ngOnInit() {}

}
