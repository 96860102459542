import { Component } from '@angular/core';
import { ApplicationStateService } from '../../services/application-state.service';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {

  constructor(
    private appState: ApplicationStateService,
    public authenticationService: AuthenticationService
  ) {}

  get collegeName () {
    return (this.appState.college && this.appState.college.name) || '';
  }



  logUserOut() {
    this.authenticationService.logOutAndRedirect();
  }

}
