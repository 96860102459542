import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ClientEntityManager } from '../entity-system/client-entity-manager.service';
import { College } from '../entity-system/entities/college';
import {
  CurriculumYear,
} from '../entity-system/entities/curriculum-year';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { HeaderTitleService } from './header-title.service';
import { map } from 'rxjs/operators';
import { ToastService } from './toast.service';
import {
  BulkAmendErrors,
  PipelineEntityType,
  isSuccess,
  newFindCollegeBySlugAction,
  SourceSystem,
  newFindCurriculumYearAction,
  PickableCourse,
  ITopCode, ICipCode, IImportedBadge, IPickedBadge,
} from '../../api/datacleanuptool-api.model';

@Injectable()
export class ApplicationStateService implements OnDestroy {
  pendingCsvAsText?: string;
  currentBulkAmendCsvAsText: string;

  constructor(
    private entityManager: ClientEntityManager,
    private router: Router,
    private headerTitleService: HeaderTitleService,
    private _toastService: ToastService
  ) {
    this.headerTitleSubscription =
      this.headerTitleService
        .headerTitle$
        .subscribe(title => this.headerTitle = title);

    //this.InitializeSourceDocumentStateMap();
  }

  college?: College;

  // TODO: Remove this - doesn't belong here
  // ProgramPipelineEntityTypeDataGuard or CoursePipelineEntityTypeDataGuard set this value
  // in the authenticated-routing module
  currentPipelineEntityType: PipelineEntityType | null =  null;

  // CollegeSourceSystemDataGuard or StateSourceSystemDataGuard set this value
  // in the programs or course import/college or state route
  currentSourceSystem: SourceSystem | null =  null;

  curriculumYear?: CurriculumYear;
  currentBulkAmendErrors?: BulkAmendErrors;

  headerTitle: string = '';

  //importErrorMap: { [K in SourceDocumentType]?: ParseError } = {};
  private headerTitleSubscription: Subscription;

  private _courseListAddedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
  onCourseListAdded$: Observable<boolean> = this._courseListAddedSubject.asObservable();

  private _courseListUpdatedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
  onCourseListUpdated$: Observable<boolean> = this._courseListUpdatedSubject.asObservable();

  private _courseUpdatedSubject: ReplaySubject<string> = new ReplaySubject(1);
  onCourseUpdated$: Observable<string> = this._courseUpdatedSubject.asObservable();

  private _courseAddedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
  onCourseAdded$: Observable<boolean> = this._courseAddedSubject.asObservable();

  private _programUpdatedSubject: ReplaySubject<string> = new ReplaySubject(1);
  onProgramUpdated$: Observable<string> = this._programUpdatedSubject.asObservable();

  private _programAddedSubject: ReplaySubject<boolean> = new ReplaySubject(1);
  onProgramAdded$: Observable<boolean> = this._programAddedSubject.asObservable();

  private _initiallyPickedCoursesSubject: ReplaySubject<PickableCourse[]> = new ReplaySubject(1);
  initiallyPickedCourses$: Observable<PickableCourse[]> = this._initiallyPickedCoursesSubject.asObservable();

  private _initiallyPickedTopCodeSubject: ReplaySubject<ITopCode> = new ReplaySubject(1);
  initiallyPickedTopCode$: Observable<ITopCode> = this._initiallyPickedTopCodeSubject.asObservable();

  private _initiallyPickedBadgeSubject: ReplaySubject<IPickedBadge> = new ReplaySubject(1);
  initiallyPickedBadge$: Observable<IPickedBadge> = this._initiallyPickedBadgeSubject.asObservable();

  private _initiallyPickedCipCodeSubject: ReplaySubject<ICipCode> = new ReplaySubject(1);
  initiallyPickedCipCode$: Observable<ICipCode> = this._initiallyPickedCipCodeSubject.asObservable();

  private _topCodesPickedSubject: ReplaySubject<ITopCode> = new ReplaySubject(1);
  onTopCodePicked$: Observable<ITopCode> = this._topCodesPickedSubject.asObservable();

  private _badgePickedSubject: ReplaySubject<IPickedBadge> = new ReplaySubject(1);
  onBadgePicked$: Observable<IPickedBadge> = this._badgePickedSubject.asObservable();

  private _cipCodesPickedSubject: ReplaySubject<ICipCode> = new ReplaySubject(1);
  onCipCodePicked$: Observable<ICipCode> = this._cipCodesPickedSubject.asObservable();

  private _coursesPickedSubject: ReplaySubject<PickableCourse[]> = new ReplaySubject<PickableCourse[]>(1);
  onCoursesPicked$: Observable<PickableCourse[]> = this._coursesPickedSubject.asObservable();

  // private SourceDocumentStateMap: { [K in SourceDocumentType]?: SourceDocumentState } = {};
  //
  // get currentSourceDocumentStateObject(): SourceDocumentState {
  //   return this.SourceDocumentStateMap[this.currentSourceDocumentType] ;
  // }
  //
  // get currentSourceDocumentType(): SourceDocumentType {
  //   const dataSource = this.currentSourceSystem === SourceSystem.STATE
  //                      ? DataSource.COCI
  //                      : this.curriculumYear.collegeSource;
  //
  //   return sourceDocumentTypeFromDataSourceAndPipelineEntityType(
  //     dataSource,
  //     this.currentPipelineEntityType
  //   );
  // }
  //
  // InitializeSourceDocumentStateMap() {
  //  Object.keys(SourceDocumentType).forEach(
  //    k => this.SourceDocumentStateMap[k] = new SourceDocumentState(SourceDocumentImportType.NOT_IMPORTED_THIS_SESSION)
  //  );
  // }


  selectCollege$(collegeSlug: string): Observable<boolean> {
      if (collegeSlug === undefined) {
        throw new Error('selectCollege$ - collegeSlug is undefined');
      }
      if (this.college && this.college.slug === collegeSlug) {
      // this college is already selected.  colleges don't need to be refreshed
      return of(true);
    } else {

      return this.entityManager
        .performApiAction$(newFindCollegeBySlugAction({collegeSlug: collegeSlug}))
        .pipe(
          map(apiResult => {
              if (isSuccess(apiResult)) {
                this.college = new College(apiResult.value, this.entityManager);
                return true;
              } else {
                return false;

              }
            }
          ));
    }
  }

  refreshCurriculumYear$() {
    if (!this.curriculumYear.curriculumYear) {
      throw new Error('no curriculum year is selected, so cannot refresh');
    }
    return this.selectCurriculumYear$(this.curriculumYear.curriculumYear);
  }

  selectCurriculumYear$(curriculumYear: number): Observable<boolean> {
    if (!this.college) {
      throw new Error('select college first!');
    }
    return this.entityManager
       .performApiAction$(newFindCurriculumYearAction({collegeSlug: this.college.slug, curriculumYear: curriculumYear}))
      .pipe(
        map(apiResult => {
            if (isSuccess(apiResult)) {
              this.curriculumYear = new CurriculumYear(apiResult.value, this.college, this.entityManager, this, this._toastService);
              return true;
            } else {
              return false;
            }
          }
        ));
  }

  clearSelectedCollege() {
    this.clearSelectedCurriculumYear();
    this.college = undefined;
  }

  clearSelectedCurriculumYear() {
    this.curriculumYear = undefined;
  }

  onCourseListAdded() {
    this._courseListAddedSubject.next(true);
  }

  programUpdated(programId: string) {
    this._programUpdatedSubject.next(programId);
  }

  onCourseUpdated(courseId: string) {
    this._courseUpdatedSubject.next(courseId);
  }

  coursesPicked(pickedCourses: PickableCourse[]) {
    this._coursesPickedSubject.next(pickedCourses);
  }

  topCodePicked(pickedTopCode: ITopCode) {
    this._topCodesPickedSubject.next(pickedTopCode);
  }

  badgePicked(pickedBadge: IPickedBadge) {
    this._badgePickedSubject.next(pickedBadge);
  }

  cipCodePicked(pickedCipCode: ICipCode) {
    this._cipCodesPickedSubject.next(pickedCipCode);
  }

  set initiallyPickedCourses(initiallyPickedCourses: PickableCourse[]) {
    this._coursesPickedSubject.next(undefined);
    this._initiallyPickedCoursesSubject.next(initiallyPickedCourses);
  }

  set initiallyPickedTopCode(initiallyPickedTopCode: ITopCode | undefined) {
    this._topCodesPickedSubject.next(undefined);
    this._initiallyPickedTopCodeSubject.next(initiallyPickedTopCode);
  }

  set initiallyPickedBadge(initiallyPickedBadge: IPickedBadge | undefined) {
    this._badgePickedSubject.next(undefined);
    this._initiallyPickedBadgeSubject.next(initiallyPickedBadge);
  }

  set initiallyPickedCipCode(initiallyPickedCipCode: ICipCode | undefined) {
    this._cipCodesPickedSubject.next(undefined);
    this._initiallyPickedCipCodeSubject.next(initiallyPickedCipCode);
  }

  onCourseListUpdated() {
    this._courseListUpdatedSubject.next(true);
  }

  // cacheImportParseErrorsAndSourceDocumentState(
  //   sourceSystem: SourceDocumentType,
  //   response: ApiEither<ParseErrorApiObject, Nothing>
  // ): Promise<ParseErrorApiObject | null> {
  //   if (isFailure(response)) {
  //     this.setImportErrorMap(sourceSystem, response.value);
  //     this.setSourceDocumentState(sourceSystem, SourceDocumentImportType.IMPORT_FAILED);
  //     return response.value;
  //   }
  //   else {
  //     this.setImportErrorMap(sourceSystem, undefined);
  //     this.setSourceDocumentState(sourceSystem, SourceDocumentImportType.INITIAL_IMPORT);
  //   }
  // }

  // private setSourceDocumentState (
  //   sourceSystem: SourceDocumentType,
  //   state: SourceDocumentImportType
  // ) {
  //   this.SourceDocumentStateMap[sourceSystem] = new SourceDocumentState(state);
  // }
  //
  // private setImportErrorMap(
  //   sourceSystem: SourceDocumentType,
  //   parseErrorApiObject: ParseErrorApiObject
  // ) {
  //   this.importErrorMap[sourceSystem] = new ParseError(parseErrorApiObject);
  // }

  programAdded() {
    this._programAddedSubject.next(true);
  }

  courseAdded() {
    this._courseAddedSubject.next(true);
  }

  // this really doesn't seem like the place for this
  ngOnDestroy() {
    this.headerTitleSubscription.unsubscribe();
  }
}
