import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html'
})
export class LoadingBarComponent {
  @Input() height: string;
  @Input() isRound: boolean;
  @Input() displayText: string;
}
